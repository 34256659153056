import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/service/toast.service';
import { Chart, registerables } from 'chart.js';
import { UtilService } from 'src/app/service/util.service';
import { ExecutionService } from 'src/app/service/execution.service';
import { TExecBatchTaskVariable, getVariableType } from 'src/app/model/texecbatchtaskvariable.model';

@Component({
  selector: 'app-taskoutput',
  templateUrl: './taskoutput.component.html',
  styleUrls: ['./taskoutput.component.css']
})
export class TaskOutputComponent implements OnInit {

  siteid;
  controllerid;
  projectid;
  activityid;
  executiontid;
  batchid;
  taskid;

  listavariable: TExecBatchTaskVariable[];
  errormsg;

  constructor(private route: ActivatedRoute, private utilService: UtilService,
    private executionService: ExecutionService, public toastService: ToastService,
    private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
      this.projectid=params['projectid'];
      this.activityid=params['activityid'];
      this.executiontid=params['executionid'];
      this.batchid=params['batchid'];
      this.taskid=params['taskid'];
    })
    this.getBatchTaskVarsObservable(this.siteid, this.controllerid, this.projectid , this.activityid, this.executiontid, this.batchid, this.taskid);
    Chart.register(...registerables);
  }



  doShowVariables(siteid: string, controllerid: string, activityid: string, batchid: string, taskid: string)
  {
    this.router.navigate(['/site/' + siteid +'/controller/' + controllerid +'/activities/' + activityid +'/batch/' + batchid +'/task/' + taskid +'/variables']);
  }

  getVarType(item: TExecBatchTaskVariable):string
  {
    return getVariableType(item);
  }

  refreshBatchTasks()
  {
    this.getBatchTaskVarsObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.batchid, this.taskid);
  }

  getBatchTaskVarsObservable(siteid: string, controllerid: string , projectid: string, activityid: string, executiontid: string, batchid: string, taskid: string){
    this.errormsg ='';
    this.executionService.getBatchTaskVariablesObservable(siteid, controllerid, projectid, activityid, executiontid, batchid, taskid)
      .subscribe(
        {
          next: resp => {
            this.listavariable = resp;
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Task output');
          }
        });
  }



  doDeleteBatchTaskVar(varid: string)
  {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected output [" + varid + "]?", true).subscribe(
        {
        next: (res) => {
          if (res) {
            this.executionService.deleteBatchTaskVariableObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.batchid, this.taskid, varid )
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Execution Task output deleted correctly.", 'Task output');
                    this.refreshBatchTasks();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Task');
                  }
                });
            }
          }
        }
      );
  }

}
