<nav *ngIf="currentPage !== undefined">

  <div class="d-flex justify-content-between">
    <div class="mr-auto p-2">
      <ul class="pagination justify-content-between">
        <li class="page-item">
          <button class="page-link">{{totalCount}} elements</button>
        </li>
      </ul>
    </div>
    <div class="p-2">
      <div class="d-flex justify-content-end">
        <ul class="pagination justify-content-between">
          <li class="page-item" [ngClass]="{'disabled': hasPrevious === false}">
            <button class="page-link" (click)="changePage(currentPage - 1)">Previous</button>
          </li>

          <li class="page-item" *ngIf="minPage > 1">
            <button class="page-link" (click)="changePage(1)">1</button>
          </li>
          <li class="page-item disabled other" *ngIf="minPage > 2">
            <div class="page-link">
              ...
            </div>
          </li>

          <li class="page-item" [ngClass]="{'active': page === currentPage}" *ngFor="let page of pages">
            <button class="page-link selected" (click)="changePage(page)">{{page}}</button>
          </li>

          <li class="page-item disabled other" *ngIf="maxPage < totalPages - 1">
            <div class="page-link">
              ...
            </div>
          </li>
          <li class="page-item" *ngIf="maxPage < totalPages">
            <button class="page-link" (click)="changePage(totalPages)">{{totalPages}}</button>
          </li>

          <li class="page-item" [ngClass]="{'disabled': hasNext === false}">
            <button class="page-link" (click)="changePage(currentPage + 1)">Next</button>
          </li>
        </ul>
      </div>
    </div>
  </div>

</nav>
