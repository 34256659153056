import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/service/toast.service';
import { BatchFilter, TExecBatch, TExecBatchStatus, doParsePropertiesExecBatch } from 'src/app/model/texecbatch.model';
import { Chart, registerables } from 'chart.js';
import { UtilService } from 'src/app/service/util.service';
import { ExecutionService } from 'src/app/service/execution.service';
import { ActionColumnSort, ActionEvent, TableConfiguration } from 'src/app/lib/table/table.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.css']
})
export class BatchesComponent implements OnInit {

  siteid;
  controllerid;
  projectid;
  activityid;
  executiontid;

  myChart: Chart;
  result: any;

  searchForm: FormGroup;
  searchFilter: BatchFilter;

  statuses = [
    { id: TExecBatchStatus.UNKNOWN, value: 'UNKNOWN' },
    { id: TExecBatchStatus.NEW, value: 'NEW' },
    { id: TExecBatchStatus.EMPTY, value: 'EMPTY' },
    { id: TExecBatchStatus.RUNNING, value: 'RUNNING' },
    { id: TExecBatchStatus.ERROR, value: 'ERROR' },
    { id: TExecBatchStatus.SUCCESS, value: 'SUCCESS' },
    { id: TExecBatchStatus.INTERRUPTED, value: 'INTERRUPTED' }
  ];

  errormsg;

  tableConfiguration: TableConfiguration = {
    name: '',
    enableAdd: false,
    enableReload: true,
    columns: [
      {
        name: 'Id',
        fieldName: 'number',
        columnHead: true,
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Status',
        fieldName: 'batchStatus',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Errors',
        fieldName: 'numberoferrors',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Running',
        fieldName: 'numberofrunning',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Success',
        fieldName: 'numberofsuccess',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Start',
        fieldName: 'startdatetime',
        type: 'datetime',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Elapsed',
        fieldName: '_elapsed',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      }
    ],
    actions: [
      {
        name: 'Tasks',
        actionName: 'tasks',
        iconClass: 'bi bi-robot'
      },
      {
        name: 'Delete',
        actionName: 'delete',
        buttonClass: 'btn-danger',
        iconClass: 'bi bi-trash'
      }
    ]
  };

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private utilService: UtilService,
    private executionService: ExecutionService, public toastService: ToastService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.projectid = params['projectid'];
      this.activityid = params['activityid'];
      this.executiontid = params['executionid'];
    })
    this.doInitSearchForm();
    this.refreshBatches();
    Chart.register(...registerables);
  }

  doInitSearchFilter() {
    this.searchFilter = new BatchFilter();
  }

  doInitSearchForm() {
    this.doInitSearchFilter();
    this.searchForm = this.formBuilder.group({
      status: null,
      number: null
    });
  }

  doSearch() {
    this.doInitSearchFilter();
    if (this.searchForm.controls.status.valid && this.searchForm.value.status) {
      this.searchFilter.batchstatus = this.searchForm.value.status;
    }
    if (this.searchForm.controls.number.valid && this.searchForm.value.number) {
      this.searchFilter.number = this.searchForm.value.number;
    }

    this.getBatchesObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.searchFilter);
  }

  // --------------------------------------- Tabella -------------------------------------------

  action(event: ActionEvent) {
    switch (event.actionName) {
      case 'tasks':
        this.doShowTasks(this.siteid, this.controllerid, this.projectid, this.activityid, event.value.execid, event.value.batchid);
        break;

      case 'delete':
        this.doDeleteBatch(event.value?.batchid);
        break;
    }
  }

  sort(event: ActionColumnSort) {
    this.searchFilter.OrderBySQLClause = event.fieldName + ' ' + event.order;
    this.refreshBatches();
  }

  pageChanged(event: ActionEvent) {
    this.searchFilter.pageNumber = Number(event);
    this.refreshBatches();
  }

  doReload() {
    //this.result = [];
    this.refreshBatches();
  }

  hideFunction = (actionName: any, value: TExecBatch): boolean => {
    switch (actionName) {
    }
    return false;
  }

  // -------------------------------------------------------------------------------------------


  getBatchesObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executiontid: string, filter: BatchFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.executionService.getExecBatchesObservable(siteid, controllerid, projectid, activityid, executiontid, filter)
      .subscribe(
        {
          next: resp => {
            this.result = resp;
            this.result.dataList = doParsePropertiesExecBatch(resp.dataList);
            //if (this.result.dataList?.length > 0) {
              this.doUpdateChart();
            //}
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Batches');
          }
        });
  }

  doShowTasks(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string) {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid + '/activities/' + activityid + '/executions/' + executionid + '/batches/' + batchid + "/tasks"]);
  }

  refreshBatches() {
    this.getBatchesObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.searchFilter);
  }

  doUpdateChart() {
    var vlabels: string[] = [];

    var vDataErr: number[] = [];
    var vDataSucc: number[] = [];
    var vDataRun: number[] = [];

    this.result.dataList.slice(0, 20).forEach(x => {
      if (x.startdatetime) {
        vlabels.push(x.startdatetime.toString());
        vDataErr.push(x.numberoferrors);
        vDataRun.push(x.numberofrunning);
        vDataSucc.push(x.numberofsuccess);
      }
    });

    this.myChart?.destroy();
    this.myChart = new Chart("myChart", {
      type: 'bar',
      data: {
        labels: vlabels,
        datasets: [{
          label: 'Errors',
          data: vDataErr,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1
        },
        {
          label: 'Running',
          data: vDataRun,
          backgroundColor: [
            'rgba(102, 220, 223, 0.2)'
          ],
          borderColor: [
            'rgba(102, 220, 223, 1)'
          ],
          borderWidth: 1
        },
        {
          label: 'Success',
          data: vDataSucc,
          backgroundColor: [
            'rgba(128, 243, 108, 0.2)'
          ],
          borderColor: [
            'rgba(128, 243, 108, 1)'
          ],
          borderWidth: 1
        }
      ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }



  doDeleteBatch(batchid: string) {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected batch [" + batchid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.executionService.deleteExecBatchObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, batchid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Execution Batch deleted correctly.", 'Batch');
                    this.refreshBatches();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Batch');
                  }
                });
          }
        }
      });
  }

}
