import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TActivity } from 'src/app/model/tactivity.model';
import { TController, TControllerStats, doCalculateIndex} from 'src/app/model/tcontroller.model';
import { ActivitiesService } from 'src/app/service/activities.service';
import { ControllerService } from 'src/app/service/controller.service';
import { ToastService } from 'src/app/service/toast.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.css']
})
export class ControllerComponent implements OnInit {

  siteid: string;
  controllerid: string;

  oController: TController;
  controllerStats: TControllerStats;
  listaactivity: TActivity[];
  errormsg;
  isStatsLoaded= false;
  statsIndex= 0;

  createActivityParameters:any;
  updateControllerParameters:any;

  constructor(private route: ActivatedRoute, private router: Router,
    private controllerService: ControllerService,
    private activityService: ActivitiesService,
    private utilService: UtilService,
    public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.getControllerDetailsObservable(this.siteid, this.controllerid);
      this.getControllerStatsObservable(this.siteid, this.controllerid);
      this.getActivitiesObservable(this.siteid, this.controllerid);
    })
  }

  updateController(){
    this.updateControllerParameters = {siteid: this.siteid, controllerid: this.controllerid};
  }

  onDetailsClosed()
  {
    this.updateControllerParameters = null;
    this.getControllerStatsObservable(this.siteid, this.controllerid);
    this.getActivitiesObservable(this.siteid, this.controllerid);
  }

  getControllerDetailsObservable(siteid, controllerid: string) {
    this.errormsg = '';
    this.controllerService.getControllerObservable(siteid, controllerid)
      .subscribe(
        {
          next: risp => {
            this.oController = risp;
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controllers');
          }
        });
  }

  getControllerStatsObservable(siteid, controllerid: string){
    this.controllerService.getControllerStatsObservable(siteid, controllerid)
      .subscribe(
        {
          next: risp => {
            this.controllerStats = risp;
            this.isStatsLoaded = true;
            this.doCalculateIndex();
          },
          error:error => {}
        });
  }

  getActivitiesObservable(siteid, controllerid: string){
    this.activityService.getActivitiesObservable(siteid, controllerid, null)
      .subscribe(
        {
          next: risp => {
            this.listaactivity = risp;
          },
          error:error => {
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  doCalculateIndex() {
    this.statsIndex = doCalculateIndex(this.controllerStats, this.oController?.status);
  }


  doNewActivity()
  {
    this.createActivityParameters = {siteid: this.siteid, controllerid: this.controllerid};
  }


  gotoProjects() {
    this.router.navigate(['/site/' + this.siteid + '/controller/' + this.controllerid + '/controller-projects']);
  }

  gotoActivities() {
    this.router.navigate(['/site/' + this.siteid + '/controller/' + this.controllerid + '/activities']);
  }

  gotoLogs() {
    this.router.navigate(['/site/' + this.siteid + '/controller/' + this.controllerid + '/logs']);
  }

  deleteController(){

    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected controller [" + this.oController.controllerid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.deleteControllerObservable(this.oController.siteid, this.oController.controllerid);
          }
        }
      });
  }


  deleteControllerObservable(siteId:string, controllerId:string){
    this.errormsg ='';
    this.controllerService.deleteControllerObservable(siteId, controllerId)
      .subscribe(
        {
          next: risp =>
          {
            if (risp === true)
            {
              this.toastService.showSuccessToast("Controller ["+controllerId+"] deleted correctly.", 'Controller');
              this.gotoSite();
            }
            else
            {
              this.toastService.showErrorToast("Cannot be deleted", 'Controller');
            }

          },
          error:error => {
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

    
  gotoSite(){
    this.router.navigate(['/site/' + this.siteid]);
  }

}
