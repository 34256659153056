<div class="mx-2">
  <h1><i class="bi bi-archive text-warning mx-3"></i>Variable list</h1>
  <h5>This page shows all variables for the specified project.</h5>
  <app-parents [projectid]="projectid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>

  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Type</th>
      <th scope="col">Value</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let variable of listavariable">
      <th scope="row">{{ variable.varid }}</th>
      <td>{{ variable.name }}</td>
      <td>{{ getVarType(variable) }}</td>
      <td>{{ variable.value }}</td>
    </tr>
    </tbody>
  </table>
</div>
</div>

