import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControllerListComponent } from './controller-list/controller-list.component';
import { SiteService } from 'src/app/service/site.service';
import { ToastService } from 'src/app/service/toast.service';
import { UtilService } from 'src/app/service/util.service';
import { TSite } from 'src/app/model/tsite.model';
import { TController } from 'src/app/model/tcontroller.model';
import { ControllerService } from 'src/app/service/controller.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit {

  siteid:string;
  site:TSite;
  listacontroller: TController[];

  updateSiteParameters:any;
  createParameters:any;
  modifyParameters:any;

  @ViewChild(ControllerListComponent) controllerListComponent: ControllerListComponent;

  constructor(private route: ActivatedRoute, private siteService: SiteService,
    private controllerService: ControllerService,
    private utilService: UtilService,
    private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.getSiteObservable(this.siteid);
      this.getControllerObservable(this.siteid);
    });
  }  

  getSiteObservable(siteid: string){
    this.siteService.getSiteDetailObservable(siteid)
      .subscribe(
        {
          next: resp => {
            this.site = resp;
          },
          error:error => {
            this.toastService.showErrorToast(error, 'Site');
          }
        });
  }

  
  getControllerObservable(siteid){
    this.controllerService.getControllersObservable(siteid)
      .subscribe(
        {
          next: risp => {
            this.listacontroller = risp;
          },
          error:error => {
            this.toastService.showErrorToast(error, 'Site');
          }
        });
  }

  addNewController()
  {
    this.createParameters = {siteid: this.siteid};
  }

  deleteSite() {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected site [" + this.siteid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.siteService.deleteSiteObservable(this.siteid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Site deleted correctly.", 'Site');
                    this.router.navigate(['/']);
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Site');
                  }
                });
          }
        }
      });
  }

  gotoLogs(){
    this.router.navigate(['/site/' + this.siteid + "/logs"]);
  }

  updateSite(){
    this.updateSiteParameters = {siteid: this.siteid};
  }

  onDetailsClosed()
  {
    this.modifyParameters = null;
    this.createParameters = null;
    this.updateSiteParameters = null;
    this.getSiteObservable(this.siteid);
    this.getControllerObservable(this.siteid);
    this.controllerListComponent.refreshControllers();
  }

}
