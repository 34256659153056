
  <div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="controllerProjectDetailsModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Project {{oCurrentProject?.projectid}} - details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="modalForm" (ngSubmit)="onFormSubmit()">

            <label for="recipient-name" class="col-form-label">Controller Id</label>
            <input type="text" class="form-control" id="recipient-name" formControlName="controllerid" readonly>

            <label for="recipient-name" class="col-form-label">Project Id</label>
            <select class="form-control form-select" aria-label="Project" id="recipient-name" formControlName="projectid">
              <option *ngFor="let project of projectList"
                      [value]="project?.projectid"
                      [selected]="project?.projectid === modalForm.value.projectid?.projectid">
                  {{project?.projectid}} {{project?.summary}}
              </option>
            </select>

          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
          <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
        </div>
      </div>
    </div>
  </div>

