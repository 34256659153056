import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.hasValidIdToken) {
      return true;
    }
    else if (!this.router.url.startsWith("/auth/logout")) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    // not logged in so redirect to main page with the return url
    //this.router.navigate(['auth/login']);
    //this.authService.doLogin();
    //return false;
  }
}
