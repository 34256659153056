
export class TSite{
  constructor(
     public siteid: string, // Identificativo del sito
     public name: string, // Nome
     public description: string, // Descrizione
     public icon: string, // Icona
     public note: string, // Note
  ){}
}

export class TSiteStats {
  siteid: string
  countControllers: number
  countControllersActive: number
  countActivities: number
  countActivitiesActive: number
  countActivitiesRunning: number
  countExecutions: number
  countExecutionsRunning: number
  countBatches: number
  countBatchSuccess: number
  countBatchError: number
}


export function doCalculateIndex(stats: TSiteStats) : number {

  if(stats?.countActivitiesRunning === 0) {
    return 4;
  }
  if(stats?.countActivitiesRunning > 0 &&
    stats?.countBatchSuccess === stats?.countBatches) {
      return 1;
  }
  else if (stats?.countActivitiesRunning > 0
    && stats?.countBatchError < stats?.countBatchSuccess)
  {
    return 2
  }
  else{
    return 3
  }
  
  return 0;
}



export class RobSrvSiteRequest{
  constructor(
    public siteid: string, // Identificativo del sito
    public name: string, // Nome
    public description: string, // Descrizione
    public note: string, // Note
  ){}
}