import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TActivity, TActivityStats, doCalculateIndex, getActivityStatus } from 'src/app/model/tactivity.model';
import { ActivitiesService } from 'src/app/service/activities.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.css']
})
export class ActivityCardComponent implements OnInit, OnChanges  {

  @Input() siteid:string;
  @Input() activity:TActivity;
  activityStats: TActivityStats;
  isLoaded = false;
  index= 0;

  constructor(public activityService: ActivitiesService, public toastService: ToastService,  private router:Router) { }

  ngOnInit(): void {
    // if(this.site){
    //   this.getSitesStatsObservable(this.site.siteid);
    // }
  }

  ngOnChanges(){
    if(this.activity){
      this.getActivityStatsObservable(this.siteid, this.activity.controllerid, this.activity.activityid);
    }
  }

  getActivityStatsObservable(siteid: string, controllerid: string, activityid: string){
    this.activityService.getActivityStatsObservable(siteid, controllerid, activityid)
      .subscribe(
        {
          next: risp => {
            this.activityStats = risp;
            this.isLoaded= true;
            this.doCalculateIndex();
          },
          error:error => {}
        });
  }

  getActivityStatus(oActivity: TActivity){
    return getActivityStatus(oActivity);
  }

  doCalculateIndex(){
    this.index = doCalculateIndex(this.activityStats, this.activity.status);
  }


  gotoActivity(activity: TActivity){
    this.router.navigate(['/site/' + this.siteid + '/controller/' + activity.controllerid + '/activities'], { queryParams: {activityid: activity.activityid}});
  }

}
