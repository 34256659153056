<div>
  <div *ngIf="message" class="modal alert-modal" tabindex="-1" role="dialog" id="modal" aria-labelledby="confirm" style="display:block!important">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <i class="bi bi-info-circle fs-3 "></i>
          <h5 class="modal-title" id="confirm">{{message.title}}</h5>
        </div>
        <div class="modal-body" style="white-space: pre-line;" *ngIf="message.text !== undefined">
          <p>{{message.text}}</p>
        </div>
        <div class="modal-body" style="white-space: pre-line;" *ngIf="message.textHTML !== undefined" >
          <p [innerHTML]="message.textHTML"></p>
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="!message.useYesButton" class="btn btn-outline-primary mx-3" (click)="message.noFn()">OK</button>
          <button type="submit" *ngIf="message.useYesButton" class="btn btn-primary" (click)="message.yesFn()">Yes</button>
          <button type="button" *ngIf="message.useYesButton" class="btn btn-outline-primary mx-3" (click)="message.noFn()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
