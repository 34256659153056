import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RobSrvSiteRequest, TSite, TSiteStats } from '../model/tsite.model';
import { getFilterParams, handleErrorObs } from '../lib/Utils';
import { SearchFilter } from '../lib/table/search.model';
import { PagedData } from '../lib/table/pagedData';
import { TLog } from '../model/tlog.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getSitesObservable(): Observable<TSite[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/site-details")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getSiteDetailObservable(siteId: string): Observable<TSite> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/site-details/" + siteId )
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getSiteStatsObservable(siteId: string): Observable<TSiteStats> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/site-stats/" + siteId )
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getSiteLogsObservable(siteId: string, filter: SearchFilter|null): Observable<PagedData<TLog[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteId + "/logs", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }


  postSiteObservable(oRequest: RobSrvSiteRequest): Observable<TSite> {
    return this.http.post(this.utilService.getBasePath() + "/api/v1/site-details", oRequest)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  putSiteObservable(oRequest: RobSrvSiteRequest): Observable<TSite> {
    if (oRequest && oRequest.siteid) {
      return this.http.put(this.utilService.getBasePath() + "/api/v1/site-details/" + oRequest.siteid, oRequest)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  deleteSiteObservable(siteid: string): Observable<boolean> {
    if (siteid) {
      return this.http.delete(this.utilService.getBasePath() + "/api/v1/site-details/" + siteid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

}



