<div class="my-2">
  Select a site

  <select class="selectpicker" [ngModel]="this.selectedSiteId" (ngModelChange)="onChange($event)" name="selSite">
    <option [ngValue]="null" disabled>Select a site</option>
    <option *ngFor="let site of listasite" [ngValue]="site.siteid">Site {{site.siteid}}</option>
  </select>
  <p>
    {{this.selectedSiteId}}
  </p>
</div>
