import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TControllerProject } from 'src/app/model/tcontroller-project.model';
import { TProject } from 'src/app/model/tproject.model';
import { ProjectService } from 'src/app/service/project.service';
import { ToastService } from 'src/app/service/toast.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-controller-project',
  templateUrl: './dialog-controller-project.component.html',
  styleUrls: ['./dialog-controller-project.component.scss']
})
export class DialogControllerProjectComponent implements OnInit {

  projectList: TProject[];
  oCurrentProject: TControllerProject;

  siteid: string;
  controllerid: string;

  modalForm = new FormGroup({
    controllerid: new FormControl(),
    projectid: new FormControl()
  });

  // --- aggiungere per modal ----
  formModal:any;
  errormsg;

  @Output() onClose = new EventEmitter<string>();

  constructor(private projectservice: ProjectService,
              private toastService: ToastService)
              { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("controllerProjectDetailsModal")
    );

    this.getProjectsObservable();
  }

  getProjectsObservable(){
    this.errormsg ='';
    this.projectservice.getProjectsObservable()
      .subscribe(
        {
          next: risp => this.projectList = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }



  doShow(siteid: string, controllerid:string) {
    this.siteid = siteid;
    this.controllerid = controllerid;

    this.modalForm.setValue({controllerid: this.controllerid, projectid: null})

    this.formModal.show();
  }

  onFormSubmit(){}

  closeModal(){
    this.onClose.emit("");
    this.formModal.hide();
  }

  patchLinkProjectsObservable(siteid: string, controllerid:string, projectid: string){
    this.errormsg ='';
    this.projectservice.patchAssignControllerProjectsObservable(siteid, controllerid, projectid)
      .subscribe(
        {
          next: risp => {
            if(risp === true)
            {
            this.toastService.showSuccessToast("Project assigned correctly to controller.", 'Controller');
            this.onClose.emit("");
            this.formModal.hide();
            }
            else
            {
              this.toastService.showErrorToast("Project cannot be assigned to controller.", 'Controller');
            }
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  doSave(){
    this.oCurrentProject = new TControllerProject(null, null);
    this.oCurrentProject.controllerid = this.modalForm.get('controllerid').value;
    this.oCurrentProject.projectid = this.modalForm.get('projectid').value;

    this.patchLinkProjectsObservable(this.siteid, this.oCurrentProject.controllerid, this.oCurrentProject.projectid);


  }

}
