<div class="d-flex justify-content-between mx-3">
  <div>
    <h2 *ngIf="_config.name">
      {{_config.name}}
    </h2>
  </div>
  <div>
    <div class="text-nowrap">
      <button *ngIf="_config.enableAdd" type="button" class="btn btn-primary" title="Add new element"
        (click)="doAdd()">
        <i class="bi bi-plus"></i>
      </button>
      <button *ngIf="_config.enableReload" type="button" class="btn btn-primary" title="Reoad" (click)="doReload()">
        <i class="bi bi-arrow-repeat"></i>
      </button>
      <button *ngIf="_config.enableXLSX" type="button" class="btn btn-primary" title="Export Excel"
        (click)="doExcel()">
        <i class="bi bi-filetype-xls"></i>
      </button>
      <button *ngIf="_config.enableCSV" type="button" class="btn btn-primary" title="Export CSV" (click)="doCSV()">
        <i class="bi bi-filetype-csv"></i>
      </button>
    </div>
  </div>
</div>

<div class="table-responsive mx-3">
  <table class="table table-striped">
    <thead>
      <tr>
        <th *ngFor="let column of _config.columns; let i = index" scope="col" [class]="column.classHead"
        class="taaaext-naaaowrap">
        <ng-container *ngIf="!column.sortable?.enabled">
          {{column.name}}
        </ng-container>
        <ng-container *ngIf="column.sortable?.enabled">
            <a class="sort" (click)="sortConfigure(column.fieldName)">
              {{column.name}}
              <i *ngIf="sortStatus[i].active && sortStatus[i].order === 'asc'" class="bi bi-sort-alpha-down"></i>
              <i *ngIf="sortStatus[i].active && sortStatus[i].order === 'desc'" class="bi bi-sort-alpha-down-alt"></i>
            </a>
          </ng-container>
        </th>
        <th *ngIf="_config.actions &&  _config.actions.length > 0" class="text-end">
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataList; let rowIndex = index">
        <ng-container *ngFor="let column of _config.columns">
          <td [class.head]="column.columnHead" [class]="column.class">
            <ng-container
              *ngIf="(column?.HTMLContent === undefined || column?.HTMLContent === false) && isDate(column.type)">
              {{item[column.fieldName] | date}}
            </ng-container>
            <ng-container
              *ngIf="(column?.HTMLContent === undefined || column?.HTMLContent === false) && isDateTime(column.type)">
              {{item[column.fieldName] | date:'dd/MM/yyyy HH:mm:ss:SSS'}}
            </ng-container>
            <ng-container
              *ngIf="(column?.HTMLContent === undefined || column?.HTMLContent === false) && isCurrency(column.type)">
              {{item[column.fieldName] | currency : 'EUR'}}
            </ng-container>
            <ng-container
              *ngIf="(column?.HTMLContent === undefined || column?.HTMLContent === false) && isString(column.type)">
              {{item[column.fieldName]}}
            </ng-container>
            <ng-container *ngIf="column?.HTMLContent === true">
              <div [innerHTML]="item[column.fieldName]"></div>
            </ng-container>
            <ng-container *ngIf="(item[column.fieldName] === undefined || item[column.fieldName] === null) && column.defaultValue !== undefined">
              {{column.defaultValue}}
            </ng-container>
          </td>
        </ng-container>
        <td *ngIf="_config.actions" class="text-end" [ngClass]="collapsibleButtons.length > 0 == true ? 'flex' : ''">
          <ng-container *ngFor="let action of notCollapsibleButtons; let i = index;">
            <button type="button" class="btn btn-xs my-1 mx-1" [ngClass]="action.buttonClass ? action.buttonClass : 'btn-primary'"
              [hidden]="hide(action.actionName, item, rowIndex)" (click)="pressButton(action.actionName, item)">
              <i [class]="action.iconClass"></i>
              {{action.name}}
            </button>
          </ng-container>
          <ng-container *ngIf="collapsibleButtons.length > 0 && hideble(item)">
            <span class="sr-only">other</span>
            <button class="btn btn-s btn-primary my-1 mx-1 btn-icon btn-action more-buttons" type="button"
              id="dropdownMenuButton6-med" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              title="Action">
              <svg class="icon icon-white" aria-hidden="true">
                <use href="./assets/svg/sprite.svg#it-more-actions"></use>
              </svg>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton6-med">
              <div class="link-list-wrapper">
                <ul class="link-list">
                  <li *ngFor="let action of collapsibleButtons">
                    <button type="button" class="btn btn-link" (click)="pressButton(action.actionName, item)"
                      *ngIf="!hide(action.actionName, item, rowIndex)">
                      {{action.name}}</button>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<lib-paginator *ngIf="dataList != null && dataList.length > 0" [pagingMetadata]="pagingMetadata"
  (pageChanged)="changePage($event)"></lib-paginator>
<div *ngIf="dataList != null && dataList.length == 0" class="d-flex justify-content-center">No results</div>
<div *ngIf="dataList == null" class="d-flex justify-content-center">
  <div class="spinner-grow text-primary" role="status">
    <!-- <span class="sr-only">Loading...</span> -->
  </div>
</div>
