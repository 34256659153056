import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router, private utilService: UtilService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.accessToken;
    if (token && (req && !req.url.startsWith(String(this.utilService.getAuthenticationIssuer())))) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + token)
        });
      if(this.authService.isTokenExpired()){
        this.authService.doLogout();
      }
    }
    return next.handle(req).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          const token = this.authService.accessToken;
          if (token && (req && !req.url.startsWith(String(this.utilService.getAuthenticationIssuer())))) {
            switch (err.status) {
              case 401:
              case 403:
              //case 404:
                this.router.navigate(['/auth/login']);
                //this.authService.doLogin();
                break;
              default:
                return;
            }
          }
          /*else if (!token) {
            //this.router.navigate(['/auth/login']);
            this.authService.doLogin();
          }*/
        }
      }));
  }
}
