import { Component, OnInit, VERSION } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { RobserverService } from 'src/app/service/robserver.service';
import { TVersion } from 'src/app/model/tversion.model';
import { isValuedString } from 'src/app/lib/Utils';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  oVersion:TVersion;
  errormsg;
  sAngularVersion: string
  sAppVersion : string = require('../../../../package.json').version;

constructor(private robservice: RobserverService, private authService: AuthService) {
    this.errormsg = '';
    this.sAngularVersion = VERSION.full;
}


  ngOnInit(): void {
    this.authService.userChanged.subscribe(x=> {
      if(isValuedString(this.authService.fullName)){
        this.getVersionObservable();
      }
    });
    this.getVersionObservable();
  }

  getVersionObservable() {
    this.robservice.getVersionObservable()
      .subscribe(
        {
          next: risp => {
            this.oVersion = risp,
              this.errormsg = null
          },
          error: error => this.errormsg = error
        });
  }

}

