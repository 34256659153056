import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RobSrvSiteRequest, TSite } from 'src/app/model/tsite.model';
import { SiteService } from 'src/app/service/site.service';
import { ToastService } from 'src/app/service/toast.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-site',
  templateUrl: './dialog-site.component.html',
  styleUrls: ['./dialog-site.component.css']
})
export class DialogSiteComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  siteid:string;

  bCreate: boolean;

  @Input() set openModal(request: any){
    if (request && request.siteid)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.oCurrentSite = request;
      this.getSiteDetailsObservable(this.siteid);
    }
  }


  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.siteid = null;
      this.bCreate = true;

      this.oCurrentSite = new TSite('0', null, null, null, null);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentSite:TSite;
  errormsg;

  form = new FormGroup({
    siteid: new FormControl(),
    icon: new FormControl(),
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(),
    note: new FormControl()
  });


  icons = [
    { id: 'bi-alert', value: 'warning' },
    { id: 'bi-arrow-down', value: 'down arrow' },
    { id: 'bi-arrow-up', value: 'up arrow' },
    { id: 'bi-calendar', value: 'calendar' },
    { id: 'bi-check', value: 'checkmark' },
    { id: 'bi-chevron-down', value: 'down-left chevron' },
    { id: 'bi-chevron-left', value: 'left chevron' },
    { id: 'bi-chevron-right', value: 'right chevron' },
    { id: 'bi-chevron-up', value: 'up-left chevron' },
    { id: 'bi-clock', value: 'clock' },
    { id: 'bi-cloud-download', value: 'cloud download' },
    { id: 'bi-cloud-upload', value: 'cloud upload' },
    { id: 'bi-envelope', value: 'email envelope' },
    { id: 'bi-exclamation-triangle', value: 'exclamation triangle' },
    { id: 'bi-eye', value: 'open eye' },
    { id: 'bi-file-pdf', value: 'pdf file' },
    { id: 'bi-file-word', value: 'word file' },
    { id: 'bi-filter', value: 'filter for data search and selection' },
    { id: 'bi-flag', value: 'flag for status or language indication' },
    { id: 'bi-play', value: 'play' },
    { id: 'bi-bounding-box', value: 'bounding box' },
    { id: 'bi-currency-exchange', value: 'currency exchange' },
    { id: 'bi-person-lines-fill', value: 'person-lines' },
    { id: 'bi-bank', value: 'bank' },
    { id: 'bi-bezier', value: 'bezier' }
  ];

  constructor(private location: Location,
              private siteService:SiteService,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("siteDetailsModal")
    );
  }

  updateForm()
  {
      this.form.setValue({siteid: this.oCurrentSite.siteid,
        description: this.oCurrentSite?.description ?? null,
        icon: this.oCurrentSite?.icon ?? null,
        name: this.oCurrentSite?.name ?? null,
        note: this.oCurrentSite?.note ?? null} );
  }

  doSave(){
    this.oCurrentSite.siteid = this.form.get('siteid').value;
    this.oCurrentSite.description = this.form.get('description').value;
    this.oCurrentSite.icon = this.form.get('icon').value;
    this.oCurrentSite.name = this.form.get('name').value;
    this.oCurrentSite.note = this.form.get('note').value;


    if (this.bCreate)
    {
      this.doSaveSiteDetailsObservable(this.oCurrentSite);
    }
    else
    {
      this.doModifySiteDetailsObservable(this.oCurrentSite);
    }
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getSiteDetailsObservable(siteid: string){
    this.errormsg ='';
    this.siteService.getSiteDetailObservable(siteid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentSite = risp;
            this.updateForm();

          },

          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Site');
          }
        });
    this.formModal.show();
  }

  doSaveSiteDetailsObservable(oRequest: RobSrvSiteRequest){
    this.errormsg ='';
    this.siteService.postSiteObservable(oRequest)
      .subscribe(
        {
          next: risp => {
            this.oCurrentSite = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Site saved correctly.", 'Site');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Site');
          }
        });
  }

  doModifySiteDetailsObservable(oRequest: RobSrvSiteRequest){
    this.errormsg ='';
    this.siteService.putSiteObservable(oRequest)
      .subscribe(
        {
          next: risp => {
            this.oCurrentSite = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Site modified correctly.", 'Site');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Site');
          }
        });
  }

  goBack()
  {
    this.location.back();
  }

}
