<div class="modal hide fade in" data-bs-keyboard="false" data-bs-backdrop="static" id="executionDetailsModal"
  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">Execution {{oCurrentExecution?.execid}} - details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="modalForm">

          <div class='row'>
            <div class="col-md-6">
              <label for="executionid-name" class="col-form-label">Execution Id</label>
              <input type="text" class="form-control" id="executionid-name" formControlName="executionid" readonly>
            </div>
            <div class="col-md-6">
              <label for="controllerid-name" class="col-form-label">Controller Id</label>
              <input type="text" class="form-control" id="controllerid-name" formControlName="controllerid" readonly>
            </div>
            <div class="col-md-6">
              <label for="projectid-name" class="col-form-label">Project Id</label>
              <input type="text" class="form-control" id="projectid-name" formControlName="projectid" readonly>
            </div>
            <div class="col-md-6">
              <label for="activity-name" class="col-form-label">Activity Id</label>
              <input type="text" class="form-control" id="activityid-name" formControlName="activityid" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-6">
              <label for="rectimestamp-name" class="col-form-label">Start</label>
              <input type="text" class="form-control" id="rectimestamp-name" formControlName="rectimestamp" readonly>
            </div>
            <div class="col-md-6">
              <label for="stoptimestamp-name" class="col-form-label">Stop</label>
              <input type="text" class="form-control" id="stoptimestamp-name" formControlName="stoptimestamp" readonly>
            </div>
            <div class="col-md-6">
              <label for="_elapsed_sec-name" class="col-form-label">Elapsed (sec)</label>
              <input type="text" class="form-control" id="_elapsed_sec-name" formControlName="_elapsed_sec" readonly>
            </div>
            <div class="col-md-6">
              <label for="execstatus-name" class="col-form-label">Status</label>
              <input type="text" class="form-control" id="execstatus-name" formControlName="_execstatus" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="exec_data-text" class="col-form-label">Execution data</label>
              <textarea class="form-control" style="height: 100px;" id="exec_data-text" formControlName="exec_data" readonly></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
      </div>
    </div>
  </div>
</div>