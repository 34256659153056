import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableConfiguration, ActionEvent, ActionColumnSort, SortStatus, ActionColumn } from './table.model';
import { DatePipe } from '@angular/common';
import { PagedData, PagingMetaData } from './pagedData';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input()
  set data(val: PagedData<any[]>) {
    if (val) {
      this.dataList = val.dataList;
      this.pagingMetadata = val.pagingMetadata;
      if (this.dataList && this.pagingMetadata?.currentPage === 1) {
        if (this.sortClicked) {
          this.sortClicked = false;
        }
        else {
          let found = this.sortStatus.find(element => element.active === true);
          if (found) {
            found.active = false;
          }
        }
      }
    }
  }

  @Input()
  set configuration(val: TableConfiguration) {
    if (val) {
      this._config = val;
      this.collapsibleButtons = val.actions?.filter(p => p.collapsible) ?? [];
      this.notCollapsibleButtons = val.actions?.filter(p => !p.collapsible) ?? [];
    }
  }

  @Input() hideFunction: (actionName: string, value: any) => boolean;

  @Output() pageChanged = new EventEmitter();
  @Output() action = new EventEmitter<ActionEvent>();
  @Output() sort = new EventEmitter<ActionColumnSort>();
  @Output() reload = new EventEmitter();

  dataList: any[];
  pagingMetadata: PagingMetaData;
  _config: TableConfiguration;

  sortStatus: SortStatus[] = [];
  sortClicked = false;

  collapsibleButtons: ActionColumn[];
  notCollapsibleButtons: ActionColumn[];

  counter = 0;

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.sortInit();
  }

  changePage(page: Number) {
    this.pageChanged.emit(page);
  }

  pressButton(actionName: string, value: any) {
    const action: ActionEvent = {
      actionName: actionName,
      value: value
    };
    this.action.emit(action);
  }

  hide(action: string, value: any, rowIndex: number) {
    if (this.hideFunction) {
      const ret = this.hideFunction(action, value);
      if (ret) {
        this.counter++;
      }
      /*  var a = this.buttons.find(p => p.rowIndex === rowIndex);
       if (a) {
         a.ret++;
       } else {
         this.buttons.push({ rowIndex: rowIndex, ret: 1 });
       } */
      /*  if (ret) {
         this.buttons[rowIndex]++;
       }
       console.log(rowIndex, ret); */
      return ret;
      // return this.hideFunction(action, value);
    }
    else return false;
  }

  hideble(value: any) {
    if (this.hideFunction && this._config.actions) {
      for (let i = 0; i < this._config.actions!.length; i++) {
        if (this._config.actions[i].collapsible) {
          const ret = this.hideFunction(this._config.actions[i].actionName, value);
          if (!ret) {
            return true;
          }
        }
      }
      return false;
    }
    else return false;
  }

  doAdd() {
    const action: ActionEvent = {
      actionName: 'add-button',
      value: null
    };
    this.action.emit(action);
  }

  doReload() {
    this.reload.emit();
  }

  doExcel() {
    const action: ActionEvent = {
      actionName: 'xlsx-button',
      value: null
    };
    this.action.emit(action);
  }

  doCSV() {
    const action: ActionEvent = {
      actionName: 'csv-button',
      value: null
    };
    this.action.emit(action);
  }

  doExportExcel(dataList: any[]) {
    //const dataRendered = this.doRederData(dataList);
    //this.utils.exportAsExcelFile(dataRendered, this._config.name ? this._config.name : 'Export');
  }

  doExportCSV(dataList: any[]) {
    //const dataRendered = this.doRederData(dataList);
    //this.utils.exportAsCSVFile(dataRendered, this._config.name ? this._config.name : 'Export');
  }

  doRederData(dataList: any[]): any {
    let dataRendered: any[] = [];

    if (dataList != null && dataList.length > 0) {
      dataRendered = dataList.map(row => {
        const renderedRow: any = {};
        this._config.columns.forEach(col => {
          if (col.name !== null && (col.exportable === undefined || col.exportable === true)) {
            let value = row[col.fieldName.replace('HTML', '')]
            if (col.type === 'date') {
              value = this.datePipe.transform(value, 'dd/MM/yyyy');
            }
            renderedRow[col.name] = value;
          }
        });

        return renderedRow;
      });
    }
    return dataRendered;
  }

  sortConfigure(column: string) {
    let found = this.sortStatus.find(element => element.fieldName === column);

    if (found) {
      this.sortClicked = true;
      if (found.active) {
        // Inverte ordinamento
        found.order = found.order === 'asc' ? 'desc' : 'asc';
      } else {
        // Attiva ordinamento
        found.active = true;
      }
      // Resetta gli stati degli altri campi
      this.sortStatus.forEach(element => {
        if (element.fieldName !== column) {
          element.active = false;
          element.order = element.defaultOrder;
        }
      });

      const action: ActionColumnSort = {
        fieldName: column,
        order: found.order
      };
      this.sort.emit(action);
    }
  }

  sortInit() {
    this.sortStatus = [];
    this._config.columns.forEach(element => {
      const item: SortStatus = {
        fieldName: element.fieldName,
        order: element.sortable?.order ?? 'asc',
        active: false,
        defaultOrder: element.sortable?.order ?? 'asc'
      };
      this.sortStatus.push(item);
    });
  }

  isDate(type: string | undefined): boolean {
    return type === 'date';
  }
  isDateTime(type: string | undefined): boolean {
    return type === 'datetime';
  }
  isCurrency(type: string | undefined): boolean {
    return type === 'currency';
  }
  isString(type: string | undefined): boolean {
    return type === undefined || type === 'string';
  }

}
