import { LoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';


// Create an HTTP Interceptor
// HTTP interceptor will be used to set the state of our loading spinner.
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(private loadingService:LoaderService) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isStats = request?.url.indexOf('stats') > 0;

    if (!isStats) {
      this.totalRequests++;
      this.loadingService.setLoading(true);
    } 

    return next.handle(request).pipe(
      finalize(() => {
        if (!isStats) {
          this.totalRequests--;
          if (this.totalRequests == 0){
            this.loadingService.setLoading(false);
          }
        }
      })
    );
  }
}
