import { Component, OnInit, ViewChild } from '@angular/core';
import { TProject } from 'src/app/model/tproject.model';
import { ToastService } from 'src/app/service/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogControllerProjectComponent } from '../dialog-controller-project/dialog-controller-project.component';
import { ProjectService } from 'src/app/service/project.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-projects',
  templateUrl: './controller-projects.component.html',
  styleUrls: ['./controller-projects.component.css']
})
export class ControllerProjectsComponent implements OnInit {

  listaproject: TProject[];
  errormsg;
  siteid: string;
  controllerid: string;

  selectedProjectId:string;

  selectedProject: TProject;

  @ViewChild(DialogControllerProjectComponent) detailsModal: DialogControllerProjectComponent;

  constructor(private route: ActivatedRoute,
              private router: Router, private utilService: UtilService,
              private projectservice: ProjectService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
    })
    this.getControllerProjectsObservable(this.siteid, this.controllerid);
  }

  doRefresh()
  {
    this.getControllerProjectsObservable(this.siteid, this.controllerid);
  }

  getControllerProjectsObservable(siteid: string, controllerid:string){
    this.errormsg ='';
    this.projectservice.getControllerProjectsObservable(siteid, controllerid)
      .subscribe(
        {
          next: risp => this.listaproject = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }


  doShowDetails(projectId:string)
  {
    this.selectedProjectId = projectId;
  }

  doShowActivities(siteid: string, controllerid, projectid)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/controller-projects/' + projectid + '/activities']);
  }

  patchUnlinkProjectsObservable(siteid: string, controllerid:string, projectid: string){
    this.errormsg ='';
    this.projectservice.patchUnassignControllerProjectsObservable(siteid, controllerid, projectid)
      .subscribe(
        {
          next: risp => {
            if (risp === true)
            {
              this.toastService.showSuccessToast("Project unassigned correctly.", 'Controller');
              this.doRefresh();
            }
            else
            {
              this.toastService.showErrorToast("Project cannot be unassigned.", 'Controller');
            }

          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }


  linkNewProject()
  {
    this.detailsModal.doShow(this.siteid, this.controllerid);
  }

  doUnlink(project: TProject)
  {
     this.selectedProject = project;
     this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected project [" + project.projectid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.patchUnlinkProjectsObservable(this.siteid, this.controllerid, this.selectedProject.projectid);
          }
        }
      });
  }

  onProjectDetailsClosed()
  {
    this.selectedProjectId = null;
    this.doRefresh();
  }
}
