import { Component, OnInit, VERSION } from '@angular/core';
import {TVersion} from '../../model/tversion.model';
import {RobserverService} from '../../service/robserver.service';
import { AuthService } from 'src/app/service/auth.service';
import { isValuedString } from '../Utils';
declare var require: any;

@Component({
  selector: 'ng-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  oVersion:TVersion;
  errormsg;
  sAngularVersion: string
  sAppVersion : string = require('../../../../package.json').version;


  constructor(private robservice: RobserverService, private authService: AuthService) {
    this.errormsg = '';
    this.sAngularVersion = VERSION.full;
  }

  ngOnInit(): void {
    this.authService.userChanged.subscribe(x=> {
      if(isValuedString(this.authService.fullName)){
        this.getVersionObservable();
      }
    });
    this.getVersionObservable();
  }

  getVersionObservable() {
    this.robservice.getVersionObservable()
      .subscribe(
        {
          next: risp => {
            this.oVersion = risp,
              this.errormsg = null
          },
          error: error => this.errormsg = error
        });
  }

}
