import { Component, OnInit } from '@angular/core';
import { TProject } from 'src/app/model/tproject.model';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/service/toast.service';
import { ProjectService } from 'src/app/service/project.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  selectedProjectId:string;

  listaproject: TProject[];
  errormsg;

  constructor(private router: Router,
              private projectservice: ProjectService,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.getProjectsObservable();
  }

  doReload() {
    this.getProjectsObservable();
  }


  getProjectsObservable(){
    this.errormsg ='';
    this.projectservice.getProjectsObservable()
      .subscribe(
        {
          next: risp => this.listaproject = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Projects');
          }
        });
  }

  getArtifactsCount(oProject: TProject)
  {
    if (oProject._artifactsCount != undefined)
    {
      if (oProject._artifactsCount >= 0)
      {
        return String(oProject._artifactsCount);
      }
      else
      {
        return "?";
      }
    }
    return "---";
  }

  getControllersCount(oProject: TProject)
  {
    if (oProject._controllersCount != undefined)
    {
      if (oProject._controllersCount >= 0)
      {
        return String(oProject._controllersCount);
      }
      else
      {
      return "?";
      }
    }
    return "---";
  }

  isTimedEnabled(oProject: TProject) {
    return oProject?.timedEnabled ?? false;
  }

  getVariablesCount(oProject: TProject)
  {
    if (oProject._variablesCount != undefined)
    {
      if (oProject._variablesCount >= 0)
      {
        return String(oProject._variablesCount);
      }
      else
      {
        return "?";
      }
    }
    return "---";
  }

  getProjectUploaded(oProject: TProject)
  {
    if (oProject.uploaded != undefined)
    {
      const dt = new Date(oProject.uploaded);
      return dt.toLocaleDateString() + " "+dt.toLocaleTimeString();
    }

    return "-";
  }

  getTitle(oProject: TProject)
  {
    if (oProject.version != undefined)
    {
      return oProject.title+ " <b>("+oProject.version+")</b>"
    }

    return oProject.title;
  }

  doShowDetails(projectId:string)
  {
    this.selectedProjectId = projectId;
  }

  doShowArtifacts(projectId:string)
  {
    this.router.navigate(['/projects/' + projectId +'/artifacts']);
  }

  doShowControllers(projectId:string)
  {
    this.router.navigate(['/projects/' + projectId +'/controllers']);
  }

  doShowVariables(projectId:string)
  {
    this.router.navigate(['/projects/' + projectId +'/variables']);
  }

  onDetailsClosed()
  {
    this.selectedProjectId = null;
    this.getProjectsObservable();
  }

}
