<div class="mx-2">
  <h1><i class="bi bi-play-fill text-warning mx-3"></i> Activities list</h1>
  <ng-container *ngIf="bSingleProject; then project else controller"></ng-container>
  <ng-template #controller>
    <h5>This page shows all activities for the specified controller.</h5>
    <br>
  </ng-template>
  <ng-template #project>
    <h5>This page shows all activities for the specified project (only one activity should be ACTIVE).</h5>
    <br>
  </ng-template>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <form [formGroup]="searchForm" class="mt-3 mb-5">
    <div class="row">
      <div class="col-md-4">
        <lib-select text="Status" [data]="statuses" [fieldId]="'id'" [fieldText]="'value'"
          formControlName="status" [submitted]="true" [addFirstItem]="true" >
        </lib-select>
      </div>
      <div class="form-group col-md-4">
        <label for="id">Id</label>
        <input type="text" class="form-control" id="id" formControlName="id">
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button  type="button" class="btn btn-primary" (click)="doSearch()"><i class="bi bi-arrow-repeat"></i>Search</button>
      </div>
    </div>
  </form>

  <div *ngIf="errormsg">{{errormsg}}</div>

  <!-- <div *ngIf="projectid" class="d-flex justify-content-between">
    <button class="btn btn-primary" (click)="doNewActivity()"><i class="bi bi-plus-circle"></i>New activity</button>
    <button type="button" class="btn btn-primary" title="Reoad" (click)="doReload()"><i class="bi bi-arrow-repeat"></i></button>
  </div> -->

  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Description</th>
      <th scope="col">Project Id</th>
      <th scope="col">Summary</th>
      <th scope="col">Created</th>
      <th scope="col">Status</th>
      <th scope="col">Status Updated</th>
      <th scope="col">Next run/timed</th>
      <th scope="col">Action</th>
      <th scope="col">Variables</th>
      <th scope="col">Executions</th>
      <th scope="col">Callers</th>
      <th scope="col">Details</th>
      <th scope="col">Logs</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let activity of listaactivity">
      <th scope="row">{{ activity.activityid }}</th>
      <td>{{ activity.description }}</td>
      <td>{{ activity.projectid }}</td>
      <td>{{ activity._projectSummary }}</td>
      <td>{{ convertDateToString(activity.rectimestamp) }}</td>
      <td>{{ getActivityStatus(activity) }}</td>
      <td>{{ convertDateToString(activity.updatestatustimestamp) }}</td>
      <td *ngIf="isTimedEnabled(activity)">
        <i class="bi bi-clock" data-toggle="tooltip" title="Timed enabled"></i>
      </td>
      <td *ngIf="!isTimedEnabled(activity)" >{{ convertDateToString(activity.nextRun) }}</td>
      <td><button *ngIf="!isActive(activity)" class="btn btn-primary" (click)="doActive(activity)"><i class="bi bi-play"></i>Active</button>
      <button *ngIf="isActive(activity)" class="btn btn-primary" (click)="doUnactive(activity)"><i class="bi bi-stop"></i>Unactive</button></td>
      <td><button class="btn btn-primary" (click)="doShowVariables(siteid, controllerid, activity.activityid)"><i class="bi bi-ui-checks"></i>Show</button></td>
      <td><button class="btn d-flex align-items-center flex-column flex-xl-row" [ngClass]="activity._executionsCount > 0? 'btn-primary' : 'btn-outline-primary'" [disabled]='activity._executionsCount > 0? null : true' (click)="doShowExecutions(siteid, controllerid, activity.projectid, activity.activityid)" style="width:80px; text-align: right;"><i class="bi bi-lightning-charge" style="float: left;"></i>{{getExecutionsCount(activity)}}</button></td>
      <td><button class="btn btn-primary" (click)="doShowCallers(siteid, controllerid, activity.projectid, activity.activityid)"><i class="bi bi-puzzle"></i>Show</button></td>
      <td><button class="btn btn-primary" (click)="doShowDetails(activity.activityid)"><i class="bi bi-pencil"></i>Edit</button></td>
      <td><button class="btn btn-primary" (click)="doShowLogs(siteid, controllerid, activity.projectid, activity.activityid)"><i class="bi bi-card-list"></i>Logs</button></td>
      <td><button class="btn btn-danger" (click)="doDeleteActivity(activity?.activityid)"><i class="bi bi-trash"></i>Delete</button></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<app-dialog-activity [openModal]="modifyParameters" [openModalCreate]="createParameters" (onClose)="onDetailsClosed()"></app-dialog-activity>

