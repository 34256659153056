import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagingMetaData } from '../pagedData';


@Component({
  selector: 'lib-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input()
  set pagingMetadata(val: PagingMetaData) {
    if (val) {
      this.totalCount = val.totalCount;
      this.currentPage = val.currentPage;
      this.totalPages = val.totalPages;

      this.minPage = this.currentPage - 3;
      if (this.minPage < 1) {
        this.minPage = 1;
      }

      this.maxPage = this.minPage + 6;
      if (this.maxPage > this.totalPages) {
        this.maxPage = this.totalPages;
      }

      this.pages.length = 0;
      for (let i = this.minPage; i <= this.maxPage; i++) {
        this.pages.push(i);
      }
      this.hasNext = val.hasNext;
      this.hasPrevious = val.hasPrevious;
    }
  }

  @Output() pageChanged = new EventEmitter<Number>();
  pages: number[] = [];
  totalCount: number;
  currentPage: number;
  totalPages: number;
  minPage: number;
  maxPage: number;
  hasNext = true;
  hasPrevious = false;
  constructor() { }

  ngOnInit(): void {
  }

  changePage(page: any) {
    this.currentPage = page;
    this.pageChanged.emit(page);
  }
}
