<div class="mx-2">
  <h1><i class="bi bi-arrow-repeat text-warning mx-3"></i>Batch list</h1>
  <h5>List of all batches for the specified execution; for each batch the number of tasks in error, success and running
    will be reported.</h5>
  <div class="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [projectid]="projectid" [executiontid]="executiontid"></app-parents>
  <br>

  <form [formGroup]="searchForm" class="mt-3 mb-5" (ngSubmit)="doSearch()">
    <div class="row">
      <div class="form-group col-md-4">
        <lib-select text="Status" [data]="statuses" [fieldId]="'id'" [fieldText]="'value'" formControlName="status"
          [submitted]="true" [addFirstItem]="true">
        </lib-select>
      </div>
      <div class="form-group col-md-4">
        <label for="number">Id</label>
        <input type="text" class="form-control" id="number" formControlName="number">
      </div>
      <div class="form-group col-md-4 d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="doSearch()"><i
            class="bi bi-arrow-repeat"></i>Search</button>
      </div>
    </div>
  </form>

  <div *ngIf="errormsg">{{errormsg}}</div>
  <div id="divChart">
    <canvas class="d-none d-sm-block" id="myChart" width="400" height="100"
      style="margin-left: 2rem;margin-right: 2rem;"></canvas>
  </div>
  <lib-table [data]="result" [configuration]="tableConfiguration" (action)="action($event)"
    (pageChanged)="pageChanged($event)" (sort)="sort($event)" (reload)="doReload()"
    [hideFunction]="hideFunction"></lib-table>
</div>