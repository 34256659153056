<div class="mx-2">
  <h1><i class="bi bi-box-arrow-right text-warning mx-3"></i> Task Output</h1>
  <h5>List the output item from the specified task.</h5>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [projectid]="projectid" [batchid]="batchid" [taskid]="taskid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>

  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Type</th>
      <th scope="col">Value</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let variable of listavariable">
      <th scope="row">{{ variable.varid }}</th>
      <td>{{ variable.name }}</td>
      <td>{{ getVarType(variable) }}</td>
      <td>{{ variable.value }}</td>
      <td><button class="btn btn-danger" (click)="doDeleteBatchTaskVar(variable?.varid)"><i class="bi bi-trash"></i>Delete</button></td>

    </tr>
    </tbody>
  </table>
</div>
</div>
