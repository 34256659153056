import { Page404Component } from './components/page404/page404.component';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { VersionComponent } from './lib/version/version.component';
import { SiteListComponent } from './components/site-list/site-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControllerListComponent } from './components/site/controller-list/controller-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SiteComponent } from './components/site/site.component';
import { ControllerComponent } from './components/controller/controller.component';
import { DialogControllerComponent } from './components/dialog-controller/dialog-controller.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ComboBoxComponent } from './lib/combo-box/combo-box.component';
import { LogsComponent } from './components/logs/logs.component';
import { ArtifactsComponent } from './components/repository/artifacts/artifacts.component';
import { AppRoutingModule } from './app-routing.module';
import { ControllerProjectsComponent } from './components/controller-projects/controller-projects.component';
import { ProjectsComponent } from './components/repository/projects/projects.component';
import { ExecutionsComponent } from './components/executions/executions.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { VariablesComponent } from './components/variables/variables.component';
import { SpinnerComponent } from './lib/spinner/spinner.component';
import { LoadingInterceptor } from './lib/spinner/loading.interceptor';
import { DialogArtifactComponent } from './components/dialog-artifact/dialog-artifact.component';
import { UploadFileComponent } from './lib/upload-file/upload-file.component';
import { ProgressComponent } from './lib/progress/progress.component';
import { DialogProjectComponent } from './components/dialog-project/dialog-project.component';
import { DialogActivityComponent } from './components/dialog-activity/dialog-activity.component';
import { ToastComponent } from './lib/toast/toast.component';
import { ToasterComponent } from './lib/toaster/toaster.component';
import { BreadcrumbComponent } from './lib/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './lib/breadcrumb/breadcrumb.service';
import { DialogControllerProjectComponent } from './components/dialog-controller-project/dialog-controller-project.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { TokenInterceptorService } from './service/token-interceptor.service';
import { LogonComponent } from './components/auth/logon/logon.component';
import { AuthGuard } from './service/auth.guard';
import { LoginComponent } from './components/auth/login/login.component';
import { DialogSiteComponent } from './components/dialog-site/dialog-site.component';
import { BatchesComponent } from './components/batches/batches.component';
import { UtilService } from './service/util.service';
import { ConfirmDialogModule } from './lib/confirm-dialog/confirm-dialog.module';
import { InformationComponent } from './components/information/information.component';
import { ProjectControllersComponent } from './components/repository/project-controllers/project-controllers.component';
import { ProjectVariablesComponent } from './components/repository/project-variables/project-variables.component';
import { TableModule } from './lib/table/table.module';
import { DatePipe } from '@angular/common';
import { DialogExecutionComponent } from './components/dialog-execution/dialog-execution.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TasksComponent } from './components/tasks/tasks.component';
import { DialogActivityVariableComponent } from './components/dialog-activity-variable/dialog-activity-variable.component';
import { DialogTaskComponent } from './components/dialog-task/dialog-task.component';
import { TaskOutputComponent } from './components/task-output/taskoutput.component';
import { CallersComponent } from './components/callers/callers.component';
import { DialogCallerComponent } from './components/dialog-caller/dialog-caller.component';
import { DialogCallerResponseComponent } from './components/dialog-caller-response/dialog-caller-response.component';
import { DialogCallerMoveToComponent } from './components/dialog-caller-moveto/dialog-caller-moveto.component';
import { SelectModule } from './lib/select/select.module';
import { ParentsComponent } from './lib/parents/parents.component';
import { SiteCardComponent } from './components/cards/site-card/site-card.component';
import { ActivityCardComponent } from './components/cards/activity-card/activity-card.component';
import { ControllerCardComponent } from './components/cards/controller-card/controller-card.component';
import { NgxConfigureModule, NgxConfigureOptions } from 'ngx-configure';
import { AppOptions } from './app-options';
import { CronEditorModule } from './lib/cron-editor/cron-editor.module';
import { DialogCronComponent } from './components/dialog-cron/dialog-cron.component';
registerLocaleData(localeIt);

export function storageFactory() : OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    AppComponent,

    BreadcrumbComponent,
    ComboBoxComponent,
    SpinnerComponent,
    UploadFileComponent,
    ProgressComponent,
    ToastComponent,
    ToasterComponent,
    VersionComponent,
    ParentsComponent,

    Page404Component,
    LoginComponent,
    LogonComponent,

    DashboardComponent,
    LogsComponent,
    SettingsComponent,
    InformationComponent,
    SiteListComponent,
    SiteComponent,
    SiteCardComponent,
    ControllerListComponent,
    ControllerComponent,
    ControllerCardComponent,
    ControllerProjectsComponent,
    ArtifactsComponent,
    ProjectControllersComponent,
    ProjectVariablesComponent,
    ProjectsComponent,
    ExecutionsComponent,
    BatchesComponent,
    TasksComponent,
    TaskOutputComponent,
    ActivitiesComponent,
    ActivityCardComponent,
    VariablesComponent,
    CallersComponent,

    DialogSiteComponent,
    DialogControllerComponent,
    DialogArtifactComponent,
    DialogProjectComponent,
    DialogActivityComponent,
    DialogActivityVariableComponent,
    DialogExecutionComponent,
    DialogControllerProjectComponent,
    DialogTaskComponent,
    DialogCallerComponent,
    DialogCallerResponseComponent,
    DialogCallerMoveToComponent,
    DialogCronComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ConfirmDialogModule,
    TableModule,
    SelectModule,
    CronEditorModule,
    NgxConfigureModule.forRoot(),
    OAuthModule.forRoot()
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: NgxConfigureOptions, useClass: AppOptions },
    BreadcrumbService,
    UtilService,
    DatePipe,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
