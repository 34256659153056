import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TActivityStatus } from 'src/app/model/tactivity.model';
import { CallerFilter, TCaller, getCallerStatus } from 'src/app/model/tcaller.model';
import { CallersService } from 'src/app/service/callers.service';
import { ToastService } from 'src/app/service/toast.service';
import { UtilService } from 'src/app/service/util.service';


@Component({
  selector: 'app-callers',
  templateUrl: './callers.component.html',
  styleUrls: ['./callers.component.css']
})
export class CallersComponent implements OnInit {

  listacaller: TCaller[];
  errormsg;
  siteid: string;
  controllerid: string;
  projectid: string|null;
  activityid: string;

  oCreateParamsEdit:any;
  oModifyParamsEdit:any;

  oCreateParamsResult:any;
  oModifyParamsResult:any;

  oModifyParamsMoveTo:any;

  filterCaller:CallerFilter;

  constructor(private route: ActivatedRoute,
              private router: Router, private utilService: UtilService,
              private callersService: CallersService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
      this.projectid=params['projectid'];
      this.activityid=params['activityid'];
    })

    this.filterCaller = new CallerFilter();
    //this.filterCaller.callerid = this.callerid;

    this.getCallersObservable(this.siteid, this.controllerid, this.activityid, this.filterCaller);
  }

  getCallersObservable(siteid:string, controllerid:string, activityid:string, filter:CallerFilter|null){
    this.errormsg ='';

    this.callersService.getCallersObservable(siteid, controllerid, activityid, filter)
      .subscribe(
        {
          next: risp => this.listacaller = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Callers');
          }
        });
  }

  getCallerStatus(oCaller: TCaller):string
  {
    return getCallerStatus(oCaller);
  }

  doNewCaller()
  {
    this.oCreateParamsEdit = {siteid: this.siteid, controllerid: this.controllerid, projectid: this.projectid, activityid: this.activityid};
  }

  onEditClosed()
  {
    this.oModifyParamsEdit = null;
    this.oCreateParamsEdit = null;
    this.getCallersObservable(this.siteid, this.controllerid, this.activityid, this.filterCaller);
  }

  onResultClosed()
  {
    this.oModifyParamsResult = null;
    this.oCreateParamsResult = null;
    this.getCallersObservable(this.siteid, this.controllerid, this.activityid, this.filterCaller);
  }

  onMoveToClosed()
  {
    this.oModifyParamsMoveTo = null;
    this.getCallersObservable(this.siteid, this.controllerid, this.activityid, this.filterCaller);
  }

   doShowMoveTo(callerid:string)
  {
    this.oModifyParamsMoveTo = {siteid:this.siteid, controllerid:this.controllerid, activityid:this.activityid, callerid: callerid};
  }

  doShowDetails(callerid:string)
  {
    this.oModifyParamsEdit = {siteid:this.siteid, controllerid:this.controllerid, activityid:this.activityid, callerid: callerid};
  }

  doShowResults(callerid: string)
  {
    this.oModifyParamsResult = {siteid:this.siteid, controllerid:this.controllerid, activityid:this.activityid, callerid: callerid};
  }

  doShowExecutions(siteid: string, controllerid: string, projectid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid +'/activities/' + activityid + '/executions']);
  }

  doShowLogs(siteid: string, controllerid: string, projectid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid +'/activities/' + activityid + '/logs']);
  }

  isActive(activity: TCaller){
    if(activity.status === TActivityStatus.ACTIVE){
      return true;
    }
    return false;
  }

  doActive(activity: TCaller){
    activity.status = TActivityStatus.ACTIVE;
    this.doUpdateStatusActivity(activity);
  }
  doUnactive(activity: TCaller){
    activity.status = TActivityStatus.UNACTIVE;
    this.doUpdateStatusActivity(activity);
  }


  doUpdateStatusActivity(oCaller: TCaller){
    this.errormsg ='';
    this.callersService.patchCallerStatusObservable(this.siteid, this.controllerid, this.activityid, oCaller.callerid, oCaller)
      .subscribe(
        {
          next: risp => {
            this.toastService.showSuccessToast("Caller saved correctly.", 'Caller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Caller');
          }
        });
  }

  doDeleteCaller(callerid: string){

    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected caller [" + callerid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.deleteCallerObservable(this.siteid, this.controllerid, this.activityid, callerid);
          }
        }
      });
  }

  deleteCallerObservable(siteId:string, controllerId:string, activityid:string, callerid: string){
    this.errormsg ='';
    this.callersService.deleteCallerObservable(siteId, controllerId, activityid, callerid)
      .subscribe(
        {
          next: risp =>
          {
            if (risp === true)
            {
              this.toastService.showSuccessToast("Caller ["+controllerId+"] deleted correctly.", 'Caller');
              this.getCallersObservable(this.siteid, this.controllerid, this.activityid, this.filterCaller);
            }
            else
            {
              this.toastService.showErrorToast("Cannot be deleted", 'Caller');
            }

          },
          error:error => {
            this.toastService.showErrorToast(error, 'Caller');
          }
        });
  }
}
