import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivityFilter, TActivity, TActivityStats } from '../model/tactivity.model';
import { getFilterParams, handleErrorObs } from '../lib/Utils';
import { TActivityVariableRequest } from '../model/tactivityvariablerequest.model';
import { TActivityVariableFull } from '../model/tactivityvariablefull.model';
import { TActivityVariable } from '../model/tactivityvariable.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getActivitiesObservable(siteid: string, controllerid: string, filter: ActivityFilter): Observable<TActivity[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activity-list", { params: getFilterParams(filter) })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }
  
  getActivityDetailsObservable(siteid: string, controllerid: string, activityid: string): Observable<TActivity> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activity-details/" + activityid, { params: null })
    .pipe(
      map((risposta: any) => risposta),
      catchError(handleErrorObs)
      );    
  }
  
  getActivityStatsObservable(siteid: string, controllerid: string, activityid: string): Observable<TActivityStats> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activity-stats/" + activityid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  postActivityDetailsObservable(siteid: string, oActivity: TActivity): Observable<TActivity> {
    return this.http.post(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + oActivity.controllerid + "/activity-details", oActivity)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  putActivityDetailsObservable(siteid: string, activityid: string, oActivity: TActivity): Observable<TActivity> {
    return this.http.put(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + oActivity.controllerid + "/activity-details/" + activityid, oActivity)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  patchActivityStatusObservable(siteid: string, activityid: string, oActivity: TActivity): Observable<TActivity> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + oActivity.controllerid + "/activity-details-status/" + activityid, oActivity)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteActivityObservable(siteid: string, controllerid: string,  activityid: string): Observable<boolean> {
    if (siteid) {
      return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activity-details/" + activityid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  getActivityVariablesObservable(siteid: string, controllerid: string, projectid: string, activityid: string): Observable<TActivityVariableFull[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/project-variables/"+projectid+"?activityid="+activityid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getActivityVariableObservable(siteid: string, controllerid: string, activityid: string, varid: string): Observable<TActivityVariableFull> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/"+siteid+"/controllers/"+controllerid+"/activities/"+activityid+"/variables/"+varid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  postActivityVariableObservable(siteid: string, controllerid: string, activityid: string, oRequest: TActivityVariableRequest): Observable<TActivityVariable> {
    return this.http.post(this.utilService.getBasePath() + "/api/v1/sites/"+siteid+"/controllers/"+controllerid+"/activity-variables/"+activityid, oRequest)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  putActivityVariableObservable(siteid: string, controllerid: string, activityid: string, varid: string, oRequest: TActivityVariableRequest): Observable<TActivityVariableFull> {
    return this.http.put(this.utilService.getBasePath() + "/api/v1/sites/"+siteid+"/controllers/"+controllerid+"/activities/"+ activityid+"/variables/"+varid, oRequest)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteActivityVariableObservable(siteid: string, controllerid: string, activityid:string, varid:string): Observable<boolean> {
    if (siteid) {
      return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/"+siteid+"/controllers/"+controllerid+"/activities/"+activityid+"/variables/"+varid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }
}



