import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/service/toast.service';
import { CallersService } from 'src/app/service/callers.service';
import { TCallerResponse } from 'src/app/model/tcallerresponse.model';
import { TBatchTask, doParsePropertiesBatchTask, getBatchTaskStatus } from 'src/app/model/tbatchtask.model';
import { getDateLocaleString } from 'src/app/lib/Utils';

// --- aggiungere per modal ----
declare var window: any;

@Component({
  selector: 'app-dialog-caller-response',
  templateUrl: './dialog-caller-response.component.html',
  styleUrls: ['./dialog-caller-response.component.css']
})
export class DialogCallerResponseComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal: any;

  siteid: string;
  controllerid: string;
  activityid: string;
  callerid: string;

  bCreate: boolean;

  @Input() set openModal(request: any) {
    if (request) {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.callerid = request.callerid;
      this.oCurrentCallerResponse = request;
      this.getCallerResultsObservable(this.siteid, this.controllerid, this.activityid, this.callerid);
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentCallerResponse: TCallerResponse;
  errormsg;

  modalForm = new FormGroup({
    callerid: new FormControl(),
    lastupdate: new FormControl()
  });

  constructor(private callersService: CallersService,
    public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("callerResponseModal")
    );
  }

  getTaskStatus(oTask: TBatchTask): string {
    return getBatchTaskStatus(oTask);
  }

  getTaskVars(oTask: TBatchTask): string {
    if (oTask.vVariables != undefined) {
      return String(oTask.vVariables.length);
    }
    return "-";
  }

  getTaskOutput(oTask: TBatchTask): string {
    if (oTask.vVariables != undefined) {
      var out = "";
      oTask.vVariables.forEach(element => {
        out = out + element.name + "=" + element.value + "; "
      });
      return out;
    }
    return "-";
  }

  updateForm() {
    let lastupdate = new Date(Math.max.apply(null, this.oCurrentCallerResponse?.vTasks?.map(function (e) {
      return new Date(e.lastupdate);
    })));
    this.modalForm.setValue(
      {
        callerid: this.oCurrentCallerResponse.callerid,
        lastupdate: (lastupdate && lastupdate.getTime()) ? getDateLocaleString(lastupdate) : null
      });
  }

  closeModal() {
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getCallerResultsObservable(siteid: string, controllerid: string, activityid: string, callerid: string) {
    this.errormsg = '';
    this.callersService.getCallerResultsObservable(callerid)
      .subscribe(
        {
          next: risp => {
            this.oCurrentCallerResponse = risp;
            this.oCurrentCallerResponse.vTasks = doParsePropertiesBatchTask(this.oCurrentCallerResponse.vTasks);
            this.updateForm();
            this.formModal.show();
          },

          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Caller response');
          }
        });
  }
}
