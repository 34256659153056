<div class="mx-2">
  <h1><i class="bi bi-robot text-warning mx-3"></i>Task list</h1>
  <h5>List of all tasks for the specified batch.</h5>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [projectid]="projectid" [batchid]="batchid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>
  <div id="divChart">
    <canvas class="d-none d-sm-block" id="myChart" width="400" height="100" style="margin-left: 2rem;margin-right: 2rem;"></canvas>
  </div>
  <lib-table [data]="result" [configuration]="tableConfiguration" (action)="action($event)"
    (pageChanged)="pageChanged($event)" (sort)="sort($event)" (reload)="doReload()"
    [hideFunction]="hideFunction"></lib-table>
</div>

<app-dialog-task [openModal]="modifyParameters" (onClose)="onDetailsClosed()"></app-dialog-task>
