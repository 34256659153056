<div class="modal hide fade in" data-bs-keyboard="false" data-bs-backdrop="static" id="projectDetailsModal"
  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">
            <i *ngIf="isTimedEnabled()" class="bi bi-clock h4" style="color:blue"></i>
            <i *ngIf="!isTimedEnabled()" class="bi bi-circle h4" style="color:lightgray"></i>
            Project {{oCurrentProject?.projectid}} - details
          </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="modalForm" (ngSubmit)="onFormSubmit()">
          <div class='row'>
            <div class="col-md-6">
              <label for="recipient-name" class="col-form-label">Project Id</label>
              <input type="text" class="form-control" id="recipient-name" formControlName="projectid" readonly>
            </div>
            <div class="col-md-6">
              <label for="recipient-name" class="col-form-label">Status</label>
              <input type="text" class="form-control" id="recipient-name" formControlName="status" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-6">
              <label for="title-name" class="col-form-label">Title</label>
              <input type="text" class="form-control" id="title-name" formControlName="title" readonly>
            </div>
            <div class="col-md-6">
              <label for="version-name" class="col-form-label">Version</label>
              <input type="text" class="form-control" id="version-name" formControlName="version" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-6">
              <label for="uploaded-name" class="col-form-label">Uploaded timestamp</label>
              <input type="text" class="form-control" id="uploaded-name" formControlName="uploaded" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-6">
              <label for="ctrlRunnerVersion-name" class="col-form-label">Runner version (Controller)</label>
              <input type="text" class="form-control" id="ctrlRunnerVersion-name" formControlName="ctrlRunnerVersion"
                readonly>
            </div>
            <div class="col-md-6">
              <label for="compatibility-name" class="col-form-label">Compatibility runner filter</label>
              <input type="text" class="form-control" id="compatibility-name" formControlName="compatibility" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="description-text" class="col-form-label">Description</label>
              <textarea class="form-control" id="description-text" formControlName="description" readonly></textarea>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="message-text" class="col-form-label">Summary</label>
              <textarea class="form-control" id="message-text" formControlName="summary"></textarea>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="note-text" class="col-form-label">Note</label>
              <textarea class="form-control" id="note-text" formControlName="note"></textarea>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
        <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
      </div>
    </div>
  </div>
</div>
