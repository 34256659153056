<div class="modal hide fade in" data-bs-keyboard="false" data-bs-backdrop="static" id="cronDetailsModal" tabindex="-1"
  role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">Cron expression generator </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col">
          <cron-editor [(cron)]="cronExpression" [disabled]="false" [options]="cronOptions"></cron-editor>
          <hr />
          <p>Result</p>
          <div class="form-group mx-sm-3 mb-2">
            <input type="text" class="form-control" [(ngModel)]="cronExpression" readonly>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
        <button type="button" class="btn btn-primary" (click)="doConfirm()">Confirm</button>
      </div>
    </div>
  </div>
</div>