import { Component, HostListener, VERSION } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { authCodeFlowConfig } from './auth.config';
import { AuthService } from './service/auth.service';
import { Title } from '@angular/platform-browser';
import { isValuedString } from './lib/Utils';
import { Router } from '@angular/router';
import { NgxConfigureService } from 'ngx-configure';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  appname = this.configService.config.appname;
  title = this.configService.config.appname;
  siteForm:FormGroup;
  sticky=false;
  isLogged = false;
  sUser= '';

  public appVersion: string = VERSION.full;

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  
  constructor(private router: Router, private fb: FormBuilder, private authService: AuthService,
    private titleService: Title, private configService: NgxConfigureService) {
    authCodeFlowConfig.issuer = this.configService.config.issuer;
    authCodeFlowConfig.clientId = this.configService.config.clientId;
    this.titleService.setTitle(this.configService.config.appname);
    if (this.title.startsWith('R')) {
      this.title = this.title.substring(1);
    }

    if(!this.authService.hasValidIdToken || !this.authService.hasValidAccessToken) {
      this.doLogin().then((res) => {
        //console.log("LOGGED", res);
        this.setUserLogged();
      });
    }
    else{
      this.setUserLogged();
    }
    
    this.authService.userChanged.subscribe(x=> {
      this.setUserLogged();
    });
  }

  setUserLogged() {
    if(isValuedString(this.authService.fullName) && this.authService.hasValidIdToken){
      this.sUser = String(this.authService.fullName);
      this.isLogged = true;
    }
    else {
      this.isLogged = false;
    }
  }

  getUserName() {
    return this.authService.fullName;
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      this.sticky = true;
    } else {
      header.classList.remove("sticky");
      this.sticky = false;
    }
  }

  ngOnInit(){
    this.siteForm = this.fb.group({
      site: [null]
    });
  }

  goToHomepage(){
    this.router.navigate(['/']);
  }

  async doLogin() {
    await this.authService.doLogin();
  }

  async doLogout() {
    await this.authService.doLogout();
  }
}
