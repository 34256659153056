<div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="artifactDetailsModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">{{Title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnCancelClick()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="artifactForm">
          <label for="artifactid">Id</label>
          <input type="text" id="txtartifactid" placeholder="artifact id" formControlName="txtartifactid" >
          <label for="txtprojectid">Project</label>
          <input type="text" id="txtprojectid" placeholder="project id" formControlName="txtprojectid" >
           <div class="modal-dialog modal-lg" role="document">
            {{oArtifact | json}}
          </div>

          <app-upload-file (OnUploaded) = "OnUpload($event)" ></app-upload-file>

        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="btnCancelClick()"> Close</button>
        <button type="button" class="btn btn-primary" (click)="btnCreateClick()">Save</button>
      </div>
    </div>
  </div>
</div>
