import { getElapsed, getElapsedSec } from "../lib/Utils";
import { SearchFilter } from "./searchfilter.model";

export enum TExecutionStatus {
  UNKNOWN = 0,
  RUNNING = 1,
  STOPPED = 2,
  StopRequest = 3,
  Completed = 4
}

export class TExecution {
  constructor(
    public execid: string,
    public controllerid: string,
    public projectid: string,
    public activityid: string,
    public execstatus: TExecutionStatus,
    public rectimestamp: Date,
    public stoptimestamp: Date,
    public exec_data: string,

    public updatetimestamp: Date,

    public _elapsed_sec: number,
    public _batchCount: number,
    public _batchOkCount: number,

    public _stats: string,
    public _updated_sec_ago: number,
  ) { }
}

export class TExecutionStats {
  execid: string
  countBatches: number
  countBatchSuccess: number
  countBatchError: number
}


export class ExecutionFilter extends SearchFilter {
  execstatus:number;
  executionid:string|undefined|null;
}

export function doParsePropertiesExec(execs: any[]): TExecution[] {
  execs.forEach(x => {
    x.execStatus = getExecutionStatus(x);
    var elapsedTmp = x._elapsed_sec;
    x._elapsed_sec = getElapsedSec(elapsedTmp);
    x._elapsed = getElapsed(elapsedTmp);
    x._updated_ago = getExecutionLastUpdate(x);
    x._stats = getExecutionStats(x)
  });
  return execs;
}


export function getExecutionStatus(oExec: TExecution) {
  switch (oExec.execstatus) {
    case TExecutionStatus.UNKNOWN:
      return "UNKNOWN";

    case TExecutionStatus.RUNNING:
      return "RUNNING";

    case TExecutionStatus.STOPPED:
      return "STOPPED";

    case TExecutionStatus.StopRequest:
      return "STOP REQUEST";

    case TExecutionStatus.Completed:
        return "COMPLETED";

    default:
      return "<" + (oExec.execstatus ?? '') + ">";
  }
}

export function getExecutionLastUpdate(oExec: TExecution)
  {
    if (oExec._updated_sec_ago != undefined)
    {
      if (oExec._updated_sec_ago > 60*60*24)
      {
        return (oExec._updated_sec_ago / (60*60*24)).toFixed(1) + ' day ago';
      }
      else if (oExec._updated_sec_ago > 60*60)
      {
        return (oExec._updated_sec_ago / 3600).toFixed(1) + ' hr ago';
      }
      else if (oExec._updated_sec_ago > 60)
      {
        return (oExec._updated_sec_ago / 60).toFixed(1) + ' min ago';
      }
      else
      {
       return oExec._updated_sec_ago.toFixed(1) + ' sec ago';
      }
    }
    else
    {
      return "-";
    }
  }



  export function getExecutionStats(oExec: TExecution)
  {
    if (oExec != undefined)
    {
       if (oExec._batchCount != null && oExec._batchOkCount != null)
       {
          if (oExec._batchCount === oExec._batchOkCount)
          {
            return oExec._batchCount;
          }
          else
          {
            return oExec._batchOkCount+" / "+ oExec._batchCount;
          }
       }
       else
       {
          if (oExec._batchCount != null)
          {
            return oExec._batchCount;
          }
          else if (oExec._batchOkCount != null)
          {
            return oExec._batchOkCount + " / 0";
          }
          else
          {
            return "-";
          }
       }
    }
    else
    {
      return "-";
    }
  }
