
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '../lib/confirm-dialog/confirm-dialog.service';
import { NgxConfigureService } from 'ngx-configure';

@Injectable({ providedIn: 'root' })
export class UtilService {

  constructor(private confirmDialogService: ConfirmDialogService, private configService: NgxConfigureService) { }

  public getBasePath(): string {
    return this.configService.config.apiUrl;
  }
  public getAuthenticationIssuer(): string {
    return this.configService.config.issuer;
  }

  public cleanDialog() {
    this.confirmDialogService.cleanDialog();
  }

  public openConfirmDialog(message: string, useYesButton = true, title = 'Confirm'): Observable<boolean> {
    return this.confirmDialogService.setConfirmation(message, useYesButton, title, undefined);
  }

  public openConfirmDialogHTML(messageHTML: string, useYesButton = true, title = 'Confirm'): Observable<boolean> {
    return this.confirmDialogService.setConfirmation(undefined, useYesButton, title, messageHTML);
  }

  

}
