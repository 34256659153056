import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/service/toast.service';
import { TExecution, getExecutionStatus } from 'src/app/model/texecution.model';
import { ExecutionService } from 'src/app/service/execution.service';
import { getDateLocaleString } from 'src/app/lib/Utils';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-execution',
  templateUrl: './dialog-execution.component.html',
  styleUrls: ['./dialog-execution.component.css']
})
export class DialogExecutionComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  siteid:string;
  controllerid:string;
  projectid:string;
  activityid:string;
  executionid: string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.controllerid = request.controllerid;
      this.projectid = request.projectid;
      this.activityid = request.activityid;
      this.executionid = request.executionid;
      this.oCurrentExecution = request;
      this.getExecutionDetailsObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executionid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.executionid = request.executionid;
      this.oCurrentExecution = new TExecution(null, request.controllerid, request.projectid, request.activityid, null, null, null, null, null, null, null, null, null, null);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentExecution:TExecution;
  errormsg;

  modalForm = new FormGroup({
    executionid: new FormControl(),
    activityid: new FormControl(),
    controllerid: new FormControl(),
    projectid: new FormControl(),
    _execstatus: new FormControl(),
    rectimestamp: new FormControl(),
    stoptimestamp: new FormControl(),
    exec_data: new FormControl(),
    _elapsed_sec: new FormControl()
  });

  constructor(private executionService:ExecutionService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("executionDetailsModal")
    );

  }

  updateForm()
  {
    this.modalForm.setValue({executionid: this.oCurrentExecution.execid,
      controllerid: this.oCurrentExecution.controllerid,
      activityid: this.oCurrentExecution.activityid,
      projectid: this.oCurrentExecution.projectid??null,
      _execstatus: getExecutionStatus(this.oCurrentExecution),
      rectimestamp: this.oCurrentExecution.rectimestamp ? getDateLocaleString(this.oCurrentExecution.rectimestamp) : null,
      stoptimestamp: this.oCurrentExecution.stoptimestamp ? getDateLocaleString(this.oCurrentExecution.stoptimestamp) : null,
      exec_data: this.oCurrentExecution.exec_data??null,
      _elapsed_sec: this.oCurrentExecution._elapsed_sec??null} );
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getExecutionDetailsObservable(siteid:string, controllerid:string, projectid: string, activityid:string, executionid:string){
    this.errormsg ='';
    this.executionService.getExecutionDetailsObservable(siteid, controllerid, projectid, activityid, executionid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentExecution = risp;
            this.updateForm();
            this.formModal.show();
          },

          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Executions');
          }
        });
  }
}
