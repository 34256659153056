<div class="mx-2">
  <h1><i class="bi bi-puzzle text-warning mx-3"></i>Callers list</h1>
  <h5>This page shows all callers for the specified activity.</h5>
  <div class="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [projectid]="projectid" [activityid]="activityid"></app-parents>
  <br>

  <div class="p-3 bg-info bg-opacity-10 border border-info border-start-1 rounded-end" style="font-size:medium">
    <strong>NOTICE:</strong> An external application (aka caller) that wants to use the output of an automation should
    know its &lt;CallerID&gt; to receive from RobotycaServer the last automation's results.
  </div>
  <br>

  <div *ngIf="errormsg">{{errormsg}}</div>

  <button *ngIf="projectid" class="btn btn-primary" (click)="doNewCaller()"><i class="bi bi-plus-circle"></i>New
    caller</button>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Activity Id</th>
          <th scope="col">Description</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
          <th scope="col">Copy to</th>
          <th scope="col">Details</th>
          <th scope="col">Results</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let oCaller of listacaller">
          <th scope="row">{{ oCaller.callerid }}</th>
          <td>{{ oCaller.activityid }}</td>
          <td>{{ oCaller.description }}</td>
          <td>{{ getCallerStatus(oCaller) }}</td>
          <td>
            <button *ngIf="!isActive(oCaller)" class="btn btn-primary" (click)="doActive(oCaller)"><i
                class="bi bi-play"></i>Active</button>
            <button *ngIf="isActive(oCaller)" class="btn btn-primary" (click)="doUnactive(oCaller)"><i
                class="bi bi-stop"></i>Unactive</button>
          </td>
          <td><button class="btn btn-primary" (click)="doShowMoveTo(oCaller.callerid)"><i
                class="bi bi-arrows-move"></i>Copy</button></td>
          <td><button class="btn btn-primary" (click)="doShowDetails(oCaller.callerid)"><i
                class="bi bi-pencil"></i>Edit</button></td>
          <td><button class="btn btn-primary" (click)="doShowResults(oCaller.callerid)"><i
                class="bi bi-eye"></i>Results</button></td>
          <td><button class="btn btn-danger" (click)="doDeleteCaller(oCaller?.callerid)"><i
                class="bi bi-trash"></i>Delete</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-dialog-caller [openModal]="oModifyParamsEdit" [openModalCreate]="oCreateParamsEdit"
  (onClose)="onEditClosed()"></app-dialog-caller>

<app-dialog-caller-moveto [openModal]="oModifyParamsMoveTo" (onClose)="onMoveToClosed()"></app-dialog-caller-moveto>

<app-dialog-caller-response [openModal]="oModifyParamsResult" (onClose)="onResultClosed()"></app-dialog-caller-response>