import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  issuer: '',
  clientId: '',
  redirectUri: window.location.origin + '/auth/logon',
  postLogoutRedirectUri: window.location.origin + '/auth/logout',
  responseType: 'code',
  scope: 'openid profile',
  requireHttps: false,
  //disablePKCE: true
  //showDebugInformation: true
  //sessionChecksEnabled: true,
  // timeoutFactor: 0.01
};
