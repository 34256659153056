import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TProject } from 'src/app/model/tproject.model';
import { ProjectService } from 'src/app/service/project.service';
import { ToastService } from 'src/app/service/toast.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-project',
  templateUrl: './dialog-project.component.html',
  styleUrls: ['./dialog-project.component.css']
})
export class DialogProjectComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  projectid:string;

  @Input() set openModal(projectid:string){
    if (projectid)
    {
      this.projectid = projectid;
      this.getProjectDetailsObservable(this.projectid);
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentProject:TProject;
  errormsg;

  modalForm = new FormGroup({
    projectid: new FormControl(),
    uploaded: new FormControl(),
    status: new FormControl(),
    summary: new FormControl(),
    title: new FormControl(),
    version: new FormControl(),
    description: new FormControl(),
    compatibility: new FormControl(),
    ctrlRunnerVersion: new FormControl(),
    note: new FormControl()
  });

  constructor(private location: Location,
              private projectservice:ProjectService,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("projectDetailsModal")
    );
  }

  isTimedEnabled(){
    return this.oCurrentProject?.timedEnabled ?? false;
  }

  doSave(){
    this.oCurrentProject.projectid = this.modalForm.get('projectid').value;
    this.oCurrentProject.status = this.modalForm.get('status').value;
    this.oCurrentProject.summary = this.modalForm.get('summary').value;
    this.oCurrentProject.note = this.modalForm.get('note').value;

    this.doSaveProjectDetailsObservable(this.oCurrentProject);
  }

  onFormSubmit()
  {
    this.oCurrentProject.projectid = this.modalForm.get('projectid').value;
    this.oCurrentProject.status = this.modalForm.get('status').value;
    this.oCurrentProject.summary = this.modalForm.get('summary').value;
    this.oCurrentProject.note = this.modalForm.get('note').value;

    this.doSaveProjectDetailsObservable(this.oCurrentProject);
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getProjectDetailsObservable(projectid:string){
    this.errormsg ='';
    this.projectservice.getProjectDetailsObservable(projectid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentProject = risp;
            this.modalForm.patchValue({
                                projectid: this.oCurrentProject.projectid,
                                uploaded:this.oCurrentProject.uploaded,
                                status: this.oCurrentProject.status,
                                summary: this.oCurrentProject.summary,
                                title: this.oCurrentProject.title,
                                version: this.oCurrentProject.version,
                                description: this.oCurrentProject.description,
                                compatibility: this.oCurrentProject.compatibility,
                                ctrlRunnerVersion: this.oCurrentProject.ctrlRunnerVersion,
                                note: this.oCurrentProject.note,
                              } )
          },

          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Projects');
          }
        });
  }

  doSaveProjectDetailsObservable(oCurrentProject:TProject){
    this.errormsg ='';
    this.projectservice.putProjectDetailsObservable(oCurrentProject)
      .subscribe(
        {
          next: risp => {
            this.oCurrentProject = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Project saved correctly.", 'Projects');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Projects');
          }
        });
  }

  goBack()
  {
    this.location.back();
  }

}
