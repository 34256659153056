import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TSite, TSiteStats, doCalculateIndex } from 'src/app/model/tsite.model';
import { SiteService } from 'src/app/service/site.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.css']
})
export class SiteCardComponent implements OnInit, OnChanges  {

  @Input() site:TSite;
  siteStats: TSiteStats;
  isLoaded= false;
  index= 0;

  constructor(public siteservice: SiteService, public toastService: ToastService,  private router:Router) { }

  ngOnInit(): void {
    // if(this.site){
    //   this.getSitesStatsObservable(this.site.siteid);
    // }
  }

  ngOnChanges(){
    if(this.site){
      this.getSiteStatsObservable(this.site.siteid);
    }
  }

  getSiteStatsObservable(siteid: string){
    this.siteservice.getSiteStatsObservable(siteid)
      .subscribe(
        {
          next: risp => {
            this.siteStats = risp;
            this.isLoaded= true;
            this.doCalculateIndex();
          },
          error:error => {}
        });
  }

  doCalculateIndex() {
    this.index = doCalculateIndex(this.siteStats);
  }

  gotoSite(site: TSite){
    this.router.navigate(['/site/'+ site.siteid]);
  }

}
