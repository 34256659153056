import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CallerFilter, TCaller } from '../model/tcaller.model';
import { getFilterParams, handleErrorObs } from '../lib/Utils';
import { TCallerResponse } from '../model/tcallerresponse.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CallersService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getCallersObservable(siteid: string, controllerid: string, activityid:string, filter: CallerFilter): Observable<TCaller[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/"+activityid+"/caller-list", { params: getFilterParams(filter) })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );


  }

  getCallerDetailsObservable(siteid: string, controllerid: string, activityid: string, callerid: string): Observable<TCaller> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/"+activityid+"/caller-details/"+callerid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
      }


  postCallerDetailsObservable(siteid: string, controllerid:string, activityid:string, oCaller: TCaller): Observable<TCaller> {
    return this.http.post(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/"+activityid+"/caller-details", oCaller)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  putCallerDetailsObservable(siteid: string, controllerid:string, activityid: string, callerid:string, oCaller: TCaller): Observable<TCaller> {
    return this.http.put(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/" + activityid+"/caller-details/"+callerid, oCaller)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  patchCallerStatusObservable(siteid: string, controllerid:string, activityid: string, callerid:string, oCaller: TCaller): Observable<TCaller> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/" + activityid+"/caller-details-status/"+callerid, oCaller)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  patchCallerActivityObservable(siteid: string, controllerid:string, activityid: string, callerid:string, oCaller: TCaller): Observable<TCaller> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/" + activityid+"/caller-details-activity/"+callerid, oCaller)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteCallerObservable(siteid: string, controllerid: string,  activityid: string, callerid:string): Observable<boolean> {
    if (siteid) {
      return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activities/" + activityid+"/caller-details/" + callerid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  getCallerResultsObservable(callerid: string): Observable<TCallerResponse> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/caller-results/"+callerid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );


  }
}



