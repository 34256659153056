<div class="container">
  <div class="row">
    <div class="col-md-12 text-center my-5 py-5">
      <div>
        <div class="container">
          <h1>See you soon!</h1>
          <a class="btn btn-secondary" (click)="doLogin()"> Click here to Login again</a>
        </div>
      </div>
    </div>
  </div>
</div>