import { EventEmitter, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userChanged = new EventEmitter<boolean>();

  constructor(
    private oauthService: OAuthService) {
  }

  doLogin() {
    //this.doClearStorageAndCookie();
    if(this.hasValidIdToken) {
      this.userChanged.emit(true);
    }
    else{
      this.oauthService.configure(authCodeFlowConfig);
      this.oauthService.loadDiscoveryDocumentAndLogin().then(() => this.userChanged.emit(true));
    }
  }

  async doLogout(): Promise<any> {
    return this.oauthService.loadDiscoveryDocument().then((res) => {
      this.oauthService.revokeTokenAndLogout();
      this.doClearStorageAndCookie();
      this.userChanged.emit(false);
    });
  }

  doClearStorageAndCookie(){
    localStorage.removeItem('token');
  }

  get userName(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    const field = 'preferred_username';
    return claims[field as keyof typeof claims]
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  get hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken();
  }

   get fullName() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    const fiedName = 'given_name';
    const fielSurname = 'family_name';
    return claims[fiedName as keyof typeof claims] + ' ' + claims[fielSurname as keyof typeof claims]
  }

  refresh() {
    this.oauthService.refreshToken();
    const token = this.oauthService.getAccessToken();
    localStorage.setItem('token', token);
  }

  isTokenExpired(): boolean {
    var exp = this.oauthService.getAccessTokenExpiration();
    if(exp < new Date().getTime()){
      return true;
    }
    return false;
  }

  public hasRobotycAClaims(): boolean {
    const claims = this.oauthService.getIdentityClaims();
    if(claims) {
      var ra = claims['resource_access'];
      if (ra && ra['robotycaserver']) {
        return ra['robotycaserver']?.roles?.indexOf('robotycaserver') >= 0;
      }
    }
    return false;
  }
}
