import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-information',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  apiUrl: string = this.utilService.getBasePath();
  issuer: string = this.utilService.getAuthenticationIssuer();

  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
  }

}
