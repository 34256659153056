import { TArtifactRequest } from './../../model/tartifactrequest.model';
import { ToastService } from 'src/app/service/toast.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TArtifact } from 'src/app/model/tartifact.model';

import { Component, ViewEncapsulation, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-artifact',
  templateUrl: './dialog-artifact.component.html',
  styleUrls: ['./dialog-artifact.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogArtifactComponent implements OnInit{

  // --- aggiungere per modal ----
  formModal: any;

  dlgName: string = 'dlgEdit';
  oArtifact: TArtifact;

  oRequest: TArtifactRequest = new TArtifactRequest();

  public artifactForm: FormGroup;

  @Output() OnClosed = new EventEmitter();
  @Input() siteId: any;
  @Input() controllerId: any;
  @Input() projectId: any;

  Title: string = "Titolo 1";

  errormsg: string;
  bInsert: boolean = false;

  oNewArtifact: TArtifact;

  @Input() set openModal(articaft: string) {
    if (articaft) {
      this.formModal.show();
    }
  }

  constructor(
    private projectservice: ProjectService,
    private fb: FormBuilder,
    private toastService: ToastService) {

    this.artifactForm = this.fb.group({
      txtartifactid: '',
      txtprojectid: '',
    });


  }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("artifactDetailsModal")
    );

  }

  public doShow(artifact: TArtifact, title: string) {
    this.Title = title;
    this.oArtifact = artifact;

    if (!this.oArtifact.artifactid) {
      this.oArtifact.artifactid = '';
    }

    if (!this.oArtifact.projectid) {
      this.oArtifact.projectid = '';
    }

    this.artifactForm.setValue({
      txtartifactid: this.oArtifact.artifactid,
      txtprojectid: this.oArtifact.projectid
    });

  }



  btnCancelClick() {
    this.OnClosed.emit(false);
    this.formModal.hide();
  }

  btnCreateClick() {

    this.oArtifact.artifactid = this.artifactForm.get('txtartifactid').value;
    this.oArtifact.projectid = this.artifactForm.get('txtprojectid').value;

    if (this.oArtifact.filename.length > 0) {
      this.doCreateArtifactObservable(this.projectId, this.oArtifact);

      if (this.bInsert) {
        this.OnClosed.emit(true);
        this.formModal.hide();
      }
    }
    else {
      this.toastService.showInfoToast("Please choose a file.", 'Error');
    }
  }



  doCreateArtifactObservable(projectid: string, oArtifact: TArtifact) {
    this.errormsg = '';
    this.bInsert = false;

    this.oRequest.oArtifact = oArtifact;

    this.projectservice.doCreateArtifactObservable(projectid, this.oRequest)
      .subscribe(
        {
          next: resp => {
            this.oNewArtifact = resp;
            this.bInsert = true;
            this.toastService.showSuccessToast('Artifact loaded', 'Artifact');
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Error');
          }
        });

  }

  OnUpload(files: any[]) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e: any) => {
      var base64result = btoa(reader.result.toString());
      this.oRequest.oBinaryData = base64result;
    }

    files.forEach(element => {
      this.oArtifact.filename = element.name;
      this.oArtifact.size = element.size;
      this.oArtifact.contenttype = element.type;
      this.oArtifact.size = element.size;

      this.oRequest.mimeType = element.type;
      //this.oRequest.oBinaryData = reader.readAsDataURL(element);
    });
  }
}
