
  <div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="siteDetailsModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Site {{oCurrentSite?.siteid}} - details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (ngSubmit)="doSave()">
            <div *ngIf="!bCreate">
              <label for="siteid" class="col-form-label">Site Id</label>
              <input type="text" class="form-control" id="siteid" formControlName="siteid" readonly="!bCreate">
            </div>
            <label for="name" class="col-form-label">Name</label>
            <input type="text" class="form-control" id="name" formControlName="name">
            <div *ngIf="form.controls['name'].invalid" class="alert alert-danger">
              <div *ngIf="form.controls['name'].errors?.['required']">
                Name is required.
              </div>
            </div>
            <div>
              <lib-select text="Icon" [data]="icons" [fieldId]="'id'" [fieldText]="'value'"
                formControlName="icon" [submitted]="true" [addFirstItem]="true" >
              </lib-select>
            </div>

            <label for="description" class="col-form-label">Description</label>
            <textarea class="form-control" id="description" formControlName="description"></textarea>
            
            <label for="note" class="col-form-label">Note</label>
            <textarea class="form-control" id="note" formControlName="note"></textarea>

          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
          <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
        </div>
      </div>
    </div>
  </div>

