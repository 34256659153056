<div class="container">
  <div class="row">
    <div class="col-md-12 text-center my-5 py-5">
      <div>
        <div class="container">
          <h1>Login successfull</h1>
          <h6>Please wait. You will be redirected shortly... </h6>
          <a href="/" >Click here if you are not redirected</a>
        </div>
      </div>
    </div>
  </div>
</div>