import { Component, Input, OnInit, Output } from '@angular/core';
import { TSite } from 'src/app/model/tsite.model';
import { EventEmitter } from '@angular/core';
import { SiteService } from 'src/app/service/site.service';

@Component({
  selector: 'ng-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.css']
})
export class SiteListComponent implements OnInit {

@Input() siteId;
@Output() siteIdChange = new EventEmitter<string>()

  listasite: TSite[];
  selectedSiteId: string = '';
  errormsg;

  constructor(private siteService: SiteService) { }

  ngOnInit(): void {
    this.getSitesObservable();

    this.selectedSiteId = this.siteId;

    this.siteIdChange.emit(this.selectedSiteId);
  }

  getSitesObservable(){
    this.siteService.getSitesObservable()
      .subscribe(
        {
          next: risp => this.listasite = risp,
          error:error => this.errormsg = error
        });
  }

  onChange(newValue) {
    this.siteIdChange.emit(newValue);
  }
}
