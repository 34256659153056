<div (click)="gotoSite(site)">
  <div class="card-header d-flex justify-content-between">
    <div class="col-10">
      <h5 class="card-title title-style">{{site.name}}</h5>
      <h6 class="sub-title-style">{{site.description}}</h6>
    </div>
    <div class="col-auto">
      <div *ngIf="!isLoaded" class="spinner-border sr-only" role="status">
        <!-- <span class="">...</span> -->
      </div>
      <div *ngIf="isLoaded">
        <i *ngIf="index == 1" class="bi bi-brightness-high h1" style ="color: green;"></i>
        <i *ngIf="index == 2" class="bi bi-cloud-sun h1" style ="color: yellow;"></i>
        <i *ngIf="index == 3" class="bi bi-cloud-rain h1" style ="color: red;"></i>
        <i *ngIf="index == 4" class="bi bi-toggle-off h1" style ="color: grey;"></i>
      </div>
    </div>
  </div>

  <div>
    <div class="card-body d-lg-block">
      <div class="card-header row mb-1">
        <i class="col-auto h-100 d-flex align-items-center justify-content-center bi {{site.icon}} h1"></i>
        <p class="col d-flex align-items-center card-text text-left">{{site.note}}</p>
      </div>
    </div>
  </div>
