import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getVariableType, TVariable } from 'src/app/model/tvariable.model';
import { ToastService } from 'src/app/service/toast.service';
import { ActivitiesService } from 'src/app/service/activities.service';
import { TActivity } from 'src/app/model/tactivity.model';
import { TActivityVariableFull } from 'src/app/model/tactivityvariablefull.model';

@Component({
  selector: 'app-project-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.css']
})

export class VariablesComponent implements OnInit {

  listavariable: TActivityVariableFull[];
  errormsg;
  siteid:string;
  controllerid:string;
  activityid:string;

  currentActivity: TActivity;

  modifyParameters:any;
  createParameters:any;

  constructor(private route: ActivatedRoute, private activitiesService: ActivitiesService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
      this.activityid=params['activityid'];
    });

    // ------------------------------------------------------------------------
    //  Carico i dettagli dell'attività e l'elenco delle variabili
    // ------------------------------------------------------------------------
    this.getActivityObservable(this.siteid, this.controllerid, this.activityid);

  }

  getVarType(item: TVariable):string
  {
    return getVariableType(item);
  }

  getCurrentValue(item: TVariable):string
  {
    if (item._activityVariable != undefined)
    {
      return item._activityVariable.value;
    }
    else
    {
      return "";
    }
  }

  doShowDetails(varid:string)
  {
    this.modifyParameters = {siteid:this.siteid, controllerid:this.controllerid, activityid:this.activityid, projectid:this.currentActivity.projectid, varid: varid};
  }

  onDetailsClosed()
  {
    this.modifyParameters = null;
    this.createParameters = null;
    this.getVariablesObservable(this.siteid, this.controllerid, this.currentActivity.projectid, this.activityid);
  }

  getActivityObservable(siteid: string, controllerid: string, activityid: string){
    this.errormsg ='';
    this.activitiesService.getActivityDetailsObservable(siteid, controllerid, activityid)
      .subscribe(
        {
          next: risp =>
          {
            this.currentActivity = risp
            this.activitiesService.getActivityVariablesObservable(siteid, controllerid, this.currentActivity.projectid, activityid)
              .subscribe(
                {
                next: risp => this.listavariable = risp,
                error:error => {
                  this.errormsg = error;
                  this.toastService.showErrorToast(error, 'Variables');
                }
              });
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }

  getVariablesObservable(siteid: string, controllerid: string, projectid:string, activityid: string){
    this.errormsg ='';
    this.activitiesService.getActivityVariablesObservable(siteid, controllerid, projectid, activityid)
      .subscribe(
        {
          next: risp => this.listavariable = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Variables');
          }
        });
  }


}
