import { SearchFilter } from "./searchfilter.model";

export enum TCallerStatus{
  UNACTIVE = -1,
  UNKNOWN = 0,
  ACTIVE = 1
}

export class TCaller{
  constructor(
     public callerid:string,
     public activityid: string,
     public description: string,
     public status: Number,
     public note: string
  ){}
}


export class CallerFilter extends SearchFilter {
  callerid:string|undefined|null
}


export function getCallerStatus(oCaller: TCaller) {
  switch (oCaller.status) {
    case TCallerStatus.UNACTIVE:
      return "UNACTIVE";
    case TCallerStatus.UNKNOWN:
      return "UNKNOWN";
    case TCallerStatus.ACTIVE:
      return "ACTIVE";
    default:
      return "<" + oCaller.status?.toString() + ">";
  }
}
