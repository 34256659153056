import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RobSrvControllerLastExecution, RobSrvControllerRequest, TController, TControllerStats } from '../model/tcontroller.model';
import { handleErrorObs } from '../lib/Utils';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getControllersOfProjectObservable(projectid: string): Observable<TController[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/projects/" + projectid + "/controllers")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getControllersObservable(siteid: string): Observable<TController[]> {
    if (siteid) {
      return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers")
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  getControllerObservable(siteid: string, controllerid: string): Observable<TController> {
    if (siteid) {
      return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  getControllerStatsObservable(siteid: string, controllerid: string): Observable<TControllerStats> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controller-stats/" + controllerid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getControllerLastExecutionObservable(siteid: string, controllerid: string): Observable<RobSrvControllerLastExecution> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/lastexecution", { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  postControllerObservable(siteid: string, oRequest: RobSrvControllerRequest): Observable<TController> {
    if (siteid) {
      return this.http.post(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers", oRequest)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  putControllerObservable(oRequest: RobSrvControllerRequest): Observable<TController> {
    if (oRequest && oRequest.siteid) {
      return this.http.put(this.utilService.getBasePath() + "/api/v1/sites/" + oRequest.siteid + "/controllers/" + oRequest.controllerid, oRequest)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  deleteControllerObservable(siteid: string, controllerid: string): Observable<boolean> {
    if (siteid) {
      return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid)
        .pipe(
          map((risposta: any) => risposta),
          catchError(handleErrorObs)
        );
    }
    else {
      return null;
    }
  }

  patchControllerStatusObservable(siteid: string, controllerid: string, oRequest: TController): Observable<TController> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controller-details-status/" + controllerid, oRequest)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

}



