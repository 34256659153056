<div class="mx-2">
  <div *ngIf="bSiteLog">
    <h1><i class="bi bi-card-list text-warning mx-3"></i>Site logs</h1>
  </div>
  <div *ngIf="bControllerLog">
    <h1><i class="bi bi-card-list text-warning mx-3"></i>Controller logs</h1>
  </div>
  <div *ngIf="bServerLog">
    <h1><i class="bi bi-card-list text-warning mx-3"></i>Server logs</h1>
  </div>
  <div *ngIf="bActivityLog">
    <h1><i class="bi bi-card-list text-warning mx-3"></i>Activity logs</h1>
  </div>
  <app-parents [siteid]="siteid" [controllerid]="controllerid" [activityid]="activityid"></app-parents>

  <form [formGroup]="searchForm" class="mt-3 mb-5" (ngSubmit)="doSearch()">
    <div class="row">
      <div class="form-group col-md-4">
        <lib-select text="Level" [data]="levels" [fieldId]="'id'" [fieldText]="'value'" formControlName="level"
          [submitted]="true" [addFirstItem]="true">
        </lib-select>
      </div>
      <div class="form-group col-md-4">
        <label for="siteid">Site</label>
        <input type="text" class="form-control" id="siteid" formControlName="siteid" [readonly]="bSiteLog || bControllerLog || bActivityLog">
      </div>
      <div class="form-group col-md-4">
        <label for="controllerid">Controller</label>
        <input type="text" class="form-control" id="controllerid" formControlName="controllerid" [readonly]="bControllerLog || bActivityLog">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-4">
        <label for="activityid">Activity</label>
        <input type="text" class="form-control" id="activityid" formControlName="activityid"  [readonly]="bActivityLog">
      </div>
      <div class="form-group col-md-4">
        <label for="reference">Reference</label>
        <input type="text" class="form-control" id="reference" formControlName="reference">
      </div>
      <div class="form-group col-md-4">
        <label for="message">Message</label>
        <input type="text" class="form-control" id="message" formControlName="message">
      </div>
      <div class="form-group col-md-12 d-flex justify-content-end mt-2">
        <button type="button" class="btn btn-primary" (click)="doSearch()"><i
            class="bi bi-arrow-repeat"></i>Search</button>
      </div>
    </div>
  </form>

  <div *ngIf="errormsg">{{errormsg}}</div>
  
  <div *ngIf="bSiteLog" class="d-flex justify-content-end">
    <button class="btn btn-danger" (click)="deleteSiteLogs()"><i class="bi bi-eraser-fill"></i>Clear Logs</button>
  </div>

  <div *ngIf="bControllerLog" class="d-flex justify-content-end">
    <button class="btn btn-danger" (click)="deleteControllerLogs()"><i class="bi bi-eraser-fill"></i>Clear Logs</button>
  </div>

  <lib-table [data]="result" [configuration]="tableConfiguration" (action)="action($event)"
  (pageChanged)="pageChanged($event)" (sort)="sort($event)" (reload)="doReload()"
  [hideFunction]="hideFunction"></lib-table>

</div>
