<h3 class="text-center">Login required area</h3>

<div class="text-center" *ngIf="hasPermission()">
  <div class="col-12">
    <button type="button" class="btn btn-primary btn-lg mt-2"
      (click)="doLogin()">Click here to access</button><br><br><br>
  </div>
</div>

<div class="text-center" *ngIf="!hasPermission()">
  <div class="col-12">
    <p>
      You are logged in but do not have useful permissions for RobotycA Sever
    </p>
    <p>
      for further information contact the administration
    </p><br><br><br>
  </div>
</div>