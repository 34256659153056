
  <div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="callerResponseModal"  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Caller {{oCurrentCallerResponse?.callerid}} - last results</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="modalForm" class ="mb-3">
            <label for="callerid-name" class="col-form-label">Caller Id</label>
            <input type="text" class="form-control" id="callerid-name" formControlName="callerid" readonly>
            <label for="callerid-name" class="col-form-label">Last update</label>
            <input type="text" class="form-control" id="callerid-name" formControlName="lastupdate" readonly>
          </form>

          <div *ngIf="oCurrentCallerResponse?.vTasks?.length > 0" class="table-responsive">
            <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">Task Id</th>
              <th scope="col">Batch Id</th>
              <th scope="col">Rule Id</th>
              <th scope="col">Elapsed</th>
              <th scope="col">Status</th>
              <th scope="col">Vars</th>
              <th scope="col">Output</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let oTask of oCurrentCallerResponse?.vTasks">
              <th scope="row">{{ oTask.taskid }}</th>
              <td>{{ oTask.batchid }}</td>
              <td>{{ oTask.ruleid }}</td>
              <td>{{ oTask._elapsed }}</td>
              <td>{{ getTaskStatus(oTask) }}</td>
              <td>{{ getTaskVars(oTask) }}</td>
              <td>{{ getTaskOutput(oTask) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="oCurrentCallerResponse?.vTasks?.length <= 0" class="d-flex justify-content-center  mb-3">No results</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
        </div>
      </div>
    </div>
  </div>

