import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogFilter, TLog, doParsePropertiesLog, getLogLevelName } from 'src/app/model/tlog.model';
import { ToastService } from 'src/app/service/toast.service';
import { RobserverService } from 'src/app/service/robserver.service';
import { UtilService } from 'src/app/service/util.service';
import { isValuedString } from 'src/app/lib/Utils';
import { SiteService } from 'src/app/service/site.service';
import { ActionColumnSort, ActionEvent, TableConfiguration } from 'src/app/lib/table/table.model';
import { SearchFilter } from 'src/app/model/searchfilter.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  errormsg;
  siteid: string;
  controllerid: string;
  activityid: string;

  bServerLog = false;
  bSiteLog = false;
  bControllerLog = false;
  bActivityLog = false;

  searchForm: FormGroup;
  searchFilter: LogFilter;

  levels = [
    { id: 0, value: getLogLevelName(0) },
    { id: 1, value: getLogLevelName(1) },
    { id: 2, value: getLogLevelName(2) },
    { id: 3, value: getLogLevelName(3) }
  ];


  result: any;
  tableConfiguration: TableConfiguration = {
    name: '',
    enableAdd: false,
    enableReload: false,
    columns: [
      {
        name: 'ID',
        fieldName: 'logtimestamp',
        columnHead: true,
        type: 'datetime',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Level',
        fieldName: '_levelDescr',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Ambit',
        fieldName: '_ambitDescr',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Site',
        fieldName: 'siteid',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Controller',
        fieldName: 'controllerid',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Activity',
        fieldName: 'activityid',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Reference',
        fieldName: 'reference',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Message',
        fieldName: 'message',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      }
    ],
    actions: [
      // {
      //   name: 'Delete',
      //   actionName: 'delete'
      // }
    ]
  };

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private utilService: UtilService,
    private robservice: RobserverService, private siteService: SiteService,
    public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.activityid = params['activityid'];
    });
    if (isValuedString(this.siteid) && !isValuedString(this.controllerid)) {
      this.bSiteLog = true;
    }
    else if (isValuedString(this.siteid) && isValuedString(this.controllerid) && !isValuedString(this.activityid)) {
      this.bControllerLog = true;
    }
    else if (isValuedString(this.siteid) && isValuedString(this.controllerid) && isValuedString(this.activityid)) {
      this.bActivityLog = true;
    }
    else {
      this.bServerLog = true;
    }

    this.doInitSearchForm();
    this.doGetLogs();
  }

  doInitSearchFilter() {
    this.searchFilter = new LogFilter();
  }

  doInitSearchForm() {
    this.doInitSearchFilter();
    this.searchForm = this.formBuilder.group({
      activityid: null,
      controllerid: null,
      siteid: null,
      level: null,
      reference: null,
      message: null,
    });
  }

  doSearch() {
    this.doInitSearchFilter();this.searchFilter
    if (this.searchForm.controls.activityid.valid && this.searchForm.value.activityid) {
      this.searchFilter.activityid = this.searchForm.value.activityid;
    }
    if (this.searchForm.controls.controllerid.valid && this.searchForm.value.controllerid) {
      this.searchFilter.controllerid = this.searchForm.value.controllerid;
    }
    if (this.searchForm.controls.level.valid && this.searchForm.value.level) {
      this.searchFilter.level = this.searchForm.value.level;
    }
    if (this.searchForm.controls.siteid.valid && this.searchForm.value.siteid) {
      this.searchFilter.siteid = this.searchForm.value.siteid;
    }
    if (this.searchForm.controls.message.valid && this.searchForm.value.message) {
      this.searchFilter.message = this.searchForm.value.message;
    }
    if (this.searchForm.controls.reference.valid && this.searchForm.value.reference) {
      this.searchFilter.reference = this.searchForm.value.reference;
    }

    this.doGetLogs();
  }

  doGetLogs() {
    if (this.bSiteLog) {
      this.getSiteLogsObservable(this.siteid, this.searchFilter);
    }
    else if (this.bControllerLog) {
      this.getControllerLogsObservable(this.siteid, this.controllerid, this.searchFilter);
    }
    else if (this.bActivityLog) {
      this.getActivityLogsObservable(this.siteid, this.controllerid, this.activityid, this.searchFilter);
    }
    else {
      this.getServerLogsObservable(this.searchFilter);
    }
  }

  getSiteLogsObservable(siteid: string, filter: SearchFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.siteService.getSiteLogsObservable(siteid, filter)
      .subscribe(
        {
          next: risp => {
            this.result = risp;
            this.result.dataList = doParsePropertiesLog(risp.dataList);
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Logs');
          }
        });
  }

  getControllerLogsObservable(siteid: string, controllerid: string, filter: SearchFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.robservice.getControllerLogsObservable(siteid, controllerid, filter)
      .subscribe(
        {
          next: risp => {
            this.result = risp;
            this.result.dataList = doParsePropertiesLog(risp.dataList);
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Logs');
          }
        });
  }

  getActivityLogsObservable(siteid: string, controllerid: string, activityid: string,  filter: SearchFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.robservice.getActivityLogsObservable(siteid, controllerid, activityid, filter)
      .subscribe(
        {
          next: risp => {
            this.result = risp;
            this.result.dataList = doParsePropertiesLog(risp.dataList);
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Logs');
          }
        });
  }

  getServerLogsObservable(filter: SearchFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.robservice.getLogsObservable(filter)
      .subscribe(
        {
          next: risp => {
            this.result = risp;
            this.result.dataList = doParsePropertiesLog(risp.dataList);
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Logs');
          }
        });
  }

  deleteSiteLogs() {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete logs?", true).subscribe({
        next: (res) => {
          if (res) {
            this.robservice.deleteLogsSiteObservable(this.siteid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Logs cleared correctly.", 'Logs');
                    this.doGetLogs();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Logs');
                  }
                });
          }
        }
      });
  }


  deleteControllerLogs() {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete logs?", true).subscribe({
        next: (res) => {
          if (res) {
            this.robservice.deleteLogsControllerObservable(this.siteid, this.controllerid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Logs cleared correctly.", 'Logs');
                    this.doGetLogs();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Logs');
                  }
                });
          }
        }
      });
  }

  action(event: ActionEvent) {
    switch (event.actionName) {
    }
  }

  doDelete() {
  }

  pageChanged(event: ActionEvent) {
    this.searchFilter.pageNumber = Number(event);
    this.doGetLogs();
  }

  doReload() {
    this.result = [];
    this.doGetLogs();
  }

  hideFunction = (actionName: any, value: TLog): boolean => {
    switch (actionName) {
    }
    return false;
  }


  sort(event: ActionColumnSort) {
    this.searchFilter.OrderBySQLClause = event.fieldName + ' ' + event.order;
    this.doGetLogs();
  }

}
