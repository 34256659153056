
<!-- <script src="https://cdn.jsdelivr.net/npm/chart.js"></script> -->

<div class="mx-2">
  <h1>Dashboard</h1>
  <div class="d-flex flex-row-reverse">
    <i class="click bi bi-arrow-clockwise fs-3 mx-2"  title="Reload stats" (click)="doReload()"></i>
    <i class="click bi bi-plus-circle-fill fs-3 mx-2"  title="Add a new Site" (click)="addNewSite()"></i>
  </div>

  <div class="row mt-3 mb-3 card-container">
    <div class="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" *ngFor="let site of listasite">
      <app-site-card class="card text-white bg-dark mb-3" [site]="site"></app-site-card>
    </div>
  </div>
</div>

<app-dialog-site [openModalCreate]="createSiteParameters" (onClose)="onDetailsClosed()"></app-dialog-site>
