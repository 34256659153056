
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userProfile: object;

  constructor(public router: Router,
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    
  }

  async doLogin(){
    await this.authService.doLogin();
  }

  hasPermission() {
    return this.authService.hasRobotycAClaims();
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
