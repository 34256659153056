import { TVariable } from "./tvariable.model";



export class TActivityVariableFull{
  constructor(
     public varid: string,
     public activityid: string,
     public value: string,

     public _projectVariable: TVariable
  ){}

}



