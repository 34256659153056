import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getDateLocaleString } from 'src/app/lib/Utils';
import { RobSrvControllerRequest, TController, TControllerStatus, getControllerStatus } from 'src/app/model/tcontroller.model';
import { ControllerService } from 'src/app/service/controller.service';
import { ToastService } from 'src/app/service/toast.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-controller',
  templateUrl: './dialog-controller.component.html',
  styleUrls: ['./dialog-controller.component.css']
})
export class DialogControllerComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  controllerId:string;
  siteId:string;
  bActive: boolean = false;

  bCreate: boolean;

  @Input() set openModal(request: any){
    if (request)
    {
      this.siteId = request.siteid;
      this.controllerId = request.controllerid;

      this.bCreate = false;

      this.getControllerDetailsObservable(this.siteId, this.controllerId);
    }
  }


  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.siteId = request.siteid;
      this.controllerId = request.controllerid;
      this.bCreate = true;

      this.oCurrentController= new TController(null, request.siteid, null, null, null, null, null, null, null, null, null, null, null, null, null);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentController:TController;
  errormsg;

  form = new FormGroup({
    controllerid: new FormControl(null, [Validators.required]),
    controllerdescr: new FormControl(),
    siteid: new FormControl(),
    controllerip: new FormControl(),
    userid: new FormControl(),

    status: new FormControl(), // 					varchar(10) not null, -- 'NEW', 'ACTIVE', 'UNACTIVE'

    connection_reqs: new FormControl(), // 	    int default 0,
    connecttimestamp: new FormControl(), // 		timestamptz ,
    disconnecttimestamp: new FormControl(), //     timestamptz,
    updatetimestamp: new FormControl(), // 		timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    assigned_token: new FormControl(), //			varchar(64),

    // Retention
    log_retention_days: new FormControl(), //      int, -- Numero massimo di giorni di log da conservare (0 = nessuna cancellazione)


    note: new FormControl(),
  });

  constructor(private location: Location,
              private controllerService:ControllerService,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("controllerDetailsModal")
    );
  }

  updateForm()
  {
      this.form.setValue({controllerid: this.oCurrentController.controllerid,
        controllerdescr: this.oCurrentController.controllerdescr?? null,
        controllerip: this.oCurrentController.controllerip?? null,

        siteid: this.oCurrentController.siteid?? null,
        userid: this.oCurrentController.userid?? null,

        status: this.oCurrentController.status,

        connection_reqs: this.oCurrentController.connection_reqs?? null,
        connecttimestamp: this.oCurrentController.connecttimestamp ? getDateLocaleString(this.oCurrentController.connecttimestamp) : null,
        disconnecttimestamp: this.oCurrentController.disconnecttimestamp ? getDateLocaleString(this.oCurrentController.disconnecttimestamp) : null,
        updatetimestamp: this.oCurrentController.updatetimestamp ? getDateLocaleString(this.oCurrentController.updatetimestamp) : null,
        assigned_token: this.oCurrentController.assigned_token?? null,

        // Retention
        log_retention_days: this.oCurrentController.log_retention_days?? null,

        note: this.oCurrentController.note?? null
      } );
  }

  doSave(){
    this.onFormSubmit();
  }

  doActive(){
    this.oCurrentController.status = TControllerStatus.ACTIVE;
    this.doUpdateStatusController(this.oCurrentController.siteid, this.oCurrentController.controllerid, this.oCurrentController);
  }
  doUnactive(){
    this.oCurrentController.status = TControllerStatus.UNACTIVE;
    this.doUpdateStatusController(this.oCurrentController.siteid, this.oCurrentController.controllerid, this.oCurrentController);
  }

  doUpdateStatusController(siteid: string, controllerid: string, oRequest: TController){
    this.errormsg ='';
    this.controllerService.patchControllerStatusObservable(siteid, controllerid, oRequest)
      .subscribe(
        {
          next: risp => {
            this.toastService.showSuccessToast("Controller saved correctly.", 'Controller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  onFormSubmit()
  {
    this.oCurrentController.controllerid = this.form.get('controllerid').value;
    this.oCurrentController.controllerip = this.form.get('controllerip').value;

    this.oCurrentController.controllerdescr = this.form.get('controllerdescr').value;
    this.oCurrentController.siteid = this.form.get('siteid').value;
    this.oCurrentController.userid = this.form.get('userid').value;

    this.oCurrentController.status = this.form.get('status').value;

    this.oCurrentController.connection_reqs = this.form.get('connection_reqs').value;
    this.oCurrentController.connecttimestamp = this.form.get('connecttimestamp').value;
    this.oCurrentController.disconnecttimestamp = this.form.get('disconnecttimestamp').value;
    this.oCurrentController.updatetimestamp = this.form.get('updatetimestamp').value;
    this.oCurrentController.assigned_token = this.form.get('assigned_token').value;

    // Retention
    this.oCurrentController.log_retention_days = this.form.get('log_retention_days').value;

    this.oCurrentController.note = this.form.get('note').value;

    if (this.bCreate)
    {
      this.doSaveControllerDetailsObservable(this.oCurrentController);
    }
    else
    {
      this.doModifyControllerDetailsObservable(this.oCurrentController);
    }
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getControllerDetailsObservable(siteid, controllerid:string){
    this.errormsg ='';
    this.controllerService.getControllerObservable(siteid, controllerid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentController = risp;
            this.bActive = this.oCurrentController.status === getControllerStatus(TControllerStatus.ACTIVE);
            this.updateForm();
            this.formModal.show();
          },

          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controllers');
          }
        });
  }

  doSaveControllerDetailsObservable(oRequest: RobSrvControllerRequest){
    this.errormsg ='';
    this.controllerService.postControllerObservable(oRequest.siteid, oRequest)
      .subscribe(
        {
          next: risp => {
            this.oCurrentController = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Controller saved correctly.", 'Controller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  doModifyControllerDetailsObservable(oRequest: RobSrvControllerRequest){
    this.errormsg ='';
    this.controllerService.putControllerObservable(oRequest)
      .subscribe(
        {
          next: risp => {
            this.oCurrentController = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Controller modified correctly.", 'Controller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  goBack()
  {
    this.location.back();
  }

}
