import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

// loader service to manage the loading state
export class LoaderService {

  private loading:boolean = false;

  constructor() { }

  setLoading(loading: boolean)
  {
    this.loading = loading;

  }

  getLoading():boolean{
    return this.loading;
  }

}
