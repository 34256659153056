import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivityFilter, TActivity} from 'src/app/model/tactivity.model';
import { ToastService } from 'src/app/service/toast.service';
import { ActivitiesService } from 'src/app/service/activities.service';
import { TCaller, getCallerStatus } from 'src/app/model/tcaller.model';
import { CallersService } from 'src/app/service/callers.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-caller-moveto',
  templateUrl: './dialog-caller-moveto.component.html',
  styleUrls: ['./dialog-caller-moveto.component.css']
})
export class DialogCallerMoveToComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  activityList: TActivity[];

  siteid:string;
  controllerid:string;
  activityid:string;
  callerid: string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.callerid = request.callerid;
      this.oCurrentCaller = request;

      this.getActivitiesObservable(this.siteid, this.controllerid, null);

      this.getCallerDetailsObservable(this.siteid, this.controllerid, this.activityid, this.oCurrentCaller.callerid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;

      this.oCurrentCaller = new TCaller(null, request.controllerid, request.projectid, null, null);
      this.updateForm();
      this.formModal.show();

      this.getActivitiesObservable(this.siteid, this.controllerid, null);
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentCaller:TCaller;
  errormsg;

  modalForm = new FormGroup({
    callerid: new FormControl(),
    activityid: new FormControl(),
    description: new FormControl(),
    status: new FormControl(),
    _status: new FormControl(),
    note: new FormControl()
  });

  constructor(private callersService:CallersService,
    private activityService:ActivitiesService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("callerMoveToModal")
    );


  }

  ngAfterViewInit(): void {

  }

  getActivitiesObservable(siteid: string, controllerid: string, filter: ActivityFilter){
    this.errormsg ='';
    this.activityService.getActivitiesObservable(siteid, controllerid, filter)
      .subscribe(
        {
          next: risp => this.activityList = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Callers');
          }
        });
  }

   doSave(){
    this.oCurrentCaller.callerid = this.modalForm.get('callerid').value;
    this.oCurrentCaller.activityid = this.modalForm.get('activityid').value;
    this.oCurrentCaller.description = this.modalForm.get('description').value;
    this.oCurrentCaller.status = this.modalForm.get('status').value;
    this.oCurrentCaller.note = this.modalForm.get('note').value;


      this.doUpdateCallerActivityObservable(this.siteid, this.controllerid, this.activityid, this.oCurrentCaller.callerid, this.oCurrentCaller);

  }

  updateForm()
  {
    this.modalForm.setValue(
      {
        callerid: this.oCurrentCaller.callerid,
        activityid: this.oCurrentCaller.activityid,
        description: this.oCurrentCaller.description??null,
        status: this.oCurrentCaller.status??null,
        _status: getCallerStatus(this.oCurrentCaller)??null,
        note: this.oCurrentCaller.note??null} );
  }


  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getCallerDetailsObservable(siteid:string, controllerid:string, activityid:string, callerid: string){
    this.errormsg ='';
    this.callersService.getCallerDetailsObservable(siteid, controllerid, activityid, callerid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentCaller = risp;
            this.updateForm();
            this.formModal.show();
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Callers');
          }
        });
  }

  doUpdateCallerActivityObservable(siteid: string, controllerid: string, activityid: string, callerid:string, oCurrentCaller:TCaller){
    this.errormsg ='';
    this.callersService.patchCallerActivityObservable(siteid, controllerid, activityid, callerid, oCurrentCaller)
      .subscribe(
        {
          next: risp => {
            this.oCurrentCaller = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Caller saved correctly.", 'Callers');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Callers');
          }
        });
  }
}
