import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/service/toast.service';
import { TCaller, getCallerStatus } from 'src/app/model/tcaller.model';
import { CallersService } from 'src/app/service/callers.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-caller',
  templateUrl: './dialog-caller.component.html',
  styleUrls: ['./dialog-caller.component.css']
})
export class DialogCallerComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  siteid:string;
  controllerid:string;
  activityid:string;
  callerid: string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.callerid = request.callerid;
      this.oCurrentCaller = request;
      this.getCallerDetailsObservable(this.siteid, this.controllerid, this.activityid, this.callerid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.oCurrentCaller = new TCaller(null, request.activityid, null, null, null);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentCaller:TCaller;
  errormsg;

  modalForm = new FormGroup({
    callerid: new FormControl(),
    activityid: new FormControl(),
    description: new FormControl(),
    status: new FormControl(),
    _status: new FormControl(),
    note: new FormControl()
  });

  constructor(private callersService:CallersService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("activityDetailsModal")
    );

  }


  doSave(){

    this.oCurrentCaller.callerid = this.modalForm.get('callerid').value;
    this.oCurrentCaller.activityid = this.modalForm.get('activityid').value;
    this.oCurrentCaller.description = this.modalForm.get('description').value;
    this.oCurrentCaller.note = this.modalForm.get('note').value;

    if(this.bCreate){
      this.oCurrentCaller.status = 0;
      this.doCreateCallerDetailsObservable(this.siteid, this.controllerid, this.activityid,  this.oCurrentCaller);
    }
    else{
      this.doUpdateCallerDetailsObservable(this.siteid, this.controllerid, this.activityid, this.oCurrentCaller.callerid, this.oCurrentCaller);
    }
  }

  updateForm()
  {
    this.modalForm.setValue(
      {
        callerid: this.oCurrentCaller.callerid,
        activityid: this.oCurrentCaller.activityid,
        description: this.oCurrentCaller.description??null,
        status: this.oCurrentCaller.status??null,
        _status: getCallerStatus(this.oCurrentCaller)??null,
        note: this.oCurrentCaller.note??null} );
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getCallerDetailsObservable(siteid:string, controllerid:string, activityid:string, callerid: string){
    this.errormsg ='';
    this.callersService.getCallerDetailsObservable(siteid, controllerid, activityid, callerid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentCaller = risp;
            this.updateForm();
            this.formModal.show();
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Caller');
          }
        });
  }

  doCreateCallerDetailsObservable(siteid: string, controllerid: string, activityid: string, oCurrentCaller:TCaller){
    this.errormsg ='';
    this.callersService.postCallerDetailsObservable(siteid, controllerid, activityid, oCurrentCaller)
      .subscribe(
        {
          next: risp => {
            this.oCurrentCaller = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Caller saved correctly.", 'Caller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Caller');
          }
        });
  }

  doUpdateCallerDetailsObservable(siteid: string, controllerid: string, activityid: string, callerid: string, oCurrentCaller:TCaller){
    this.errormsg ='';
    this.callersService.putCallerDetailsObservable(siteid, controllerid, activityid, callerid, oCurrentCaller)
      .subscribe(
        {
          next: risp => {
            this.oCurrentCaller = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Caller saved correctly.", 'Caller');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Caller');
          }
        });
  }
}
