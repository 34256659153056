<div class="mx-2">
  <h1><i class="bi bi-archive text-warning mx-3"></i>Project list</h1>
  <h5>This page shows all projects.</h5>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>

  <div class="d-flex flex-row-reverse">
    <button type="button" class="btn btn-primary" title="Reoad" (click)="doReload()"><i class="bi bi-arrow-repeat"></i></button>
  </div>
  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Upload date</th>
      <th scope="col">Status</th>
      <th scope="col">Title</th>
      <th scope="col">Summary</th>
      <th scope="col">Timed</th>
      <th scope="col">Artifacts</th>
      <th scope="col">Controllers</th>
      <th scope="col">Variables</th>
      <th scope="col">Details</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let oProject of listaproject">
      <th scope="row">{{ oProject.projectid }}</th>
      <td>{{ getProjectUploaded(oProject)}}</td>
      <td>{{ oProject.status}}</td>
      <td [innerHTML]="getTitle(oProject)"></td>
      <td>{{ oProject.summary}}</td>
      <td>
        <i *ngIf="isTimedEnabled(oProject)" class="bi bi-clock"></i>
      </td>
      <td><button class="btn" [ngClass]="oProject._artifactsCount > 0? 'btn-primary' : 'btn-outline-primary'" [disabled]='oProject._artifactsCount > 0? null : true' (click)="doShowArtifacts(oProject.projectid)" style="width:80px; text-align: right;"><i class="bi bi-eye" style="float: left;"></i>{{getArtifactsCount(oProject)}}</button></td>
      <td><button class="btn" [ngClass]="oProject._controllersCount > 0? 'btn-primary' : 'btn-outline-primary'" [disabled]='oProject._controllersCount > 0? null : true' (click)="doShowControllers(oProject.projectid)" style="width:80px; text-align: right;"><i class="bi bi-eye" style="float: left;"></i>{{getControllersCount(oProject)}}</button></td>
      <td><button class="btn" [ngClass]="oProject._variablesCount > 0? 'btn-primary' : 'btn-outline-primary'" [disabled]='oProject._variablesCount > 0? null : true' (click)="doShowVariables(oProject.projectid)" style="width:80px; text-align: right;"><i class="bi bi-eye" style="float: left;"></i>{{getVariablesCount(oProject)}}</button></td>
      <td><button class="btn btn-primary" (click)="doShowDetails(oProject.projectid)"><i class="bi bi-pencil"></i>Edit</button></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<app-dialog-project [openModal]="selectedProjectId" (onClose)="onDetailsClosed()"></app-dialog-project>
