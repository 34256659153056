import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getVariableType, TVariable } from 'src/app/model/tvariable.model';
import { ToastService } from 'src/app/service/toast.service';
import { ProjectService } from 'src/app/service/project.service';

@Component({
  selector: 'app-project-variables',
  templateUrl: './project-variables.component.html',
  styleUrls: ['./project-variables.component.css']
})

export class ProjectVariablesComponent implements OnInit {

  listavariable: TVariable[];
  errormsg;
  projectid:string;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectid=params['projectid'];
    });
    
    this.getProjectVariablesObservable(this.projectid);
  }

  getVarType(item: TVariable):string
  {
    return getVariableType(item);
  }

  getProjectVariablesObservable(projectid: string){
    this.errormsg ='';
    this.projectService.getProjectVariablesObservable(projectid)
      .subscribe(
        {
          next: risp => this.listavariable = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Variables');
          }
        });
  }

}
