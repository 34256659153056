

<div class="mx-2">
  <div class="d-flex flex-row">
    <div class="mx-3">
      <div *ngIf="!isStatsLoaded" class="spinner-border sr-only" role="status">
        <!-- <span class="">...</span> -->
      </div>
      <div *ngIf="isStatsLoaded">
        <div *ngIf="statsIndex == 0" class="spinner-grow text-success" role="status">
          <!-- <span class="sr-only">Loading...</span> -->
        </div>
        <i *ngIf="statsIndex == 1" class="bi bi-brightness-high h1" style="color: green;"></i>
        <i *ngIf="statsIndex == 2" class="bi bi-cloud-sun h1" style="color: yellow;"></i>
        <i *ngIf="statsIndex == 3" class="bi bi-cloud-rain h1" style="color: red;"></i>
        <i *ngIf="statsIndex == 4" class="bi bi-toggle-off h1" style="color: grey;"></i>
        <i *ngIf="statsIndex == 5" class="bi bi-toggle-on h1" style="color: green;"></i>
      </div>
    </div>
    <div>
      <h1>Controller {{controllerid}}</h1>
    </div>
  </div>
  <br>

  <div class="d-lg-none">
    <div>
      <button class="btn btn-primary" (click)="doNewActivity()"><i class="bi bi-plus-circle"></i>New activity</button>
      <button class="btn btn-primary" (click)="gotoLogs()"><i class="bi bi-card-list"></i>Logs</button>
      <button class="btn btn-primary mx-2" (click)="updateController()"><i class="bi bi-pencil"></i>Edit controller</button>
      <button class="btn btn-danger" (click)="deleteController()"><i class="bi bi-trash"></i>Delete controller</button>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div class="d-flex justify-content-between">
      <div>
        <button class="btn btn-primary" (click)="doNewActivity()"><i class="bi bi-plus-circle"></i>New activity</button>
        <button class="btn btn-primary" (click)="gotoActivities()"><i class="bi bi-eye"></i>View activities</button>
      </div>
      <div>
        <button class="btn btn-primary" (click)="gotoLogs()"><i class="bi bi-card-list"></i>Logs</button>
        <button class="btn btn-primary mx-2" (click)="updateController()"><i class="bi bi-pencil"></i>Edit controller</button>
        <button class="btn btn-danger" (click)="deleteController()"><i class="bi bi-trash"></i>Delete controller</button>
      </div>
    </div>
  </div>

  <div class="row mt-3 mb-3 card-container">
    <h3>Activities</h3>
    <div class="col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch" *ngFor="let activity of listaactivity">
      <app-activity-card class="card text-white bg-dark mb-3" [siteid]="siteid" [activity]="activity"></app-activity-card>
    </div>
  </div>

  <div class="row card-container">
    <h3>Projects</h3>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card text-white bg-secondary mb-3 " (click)="gotoProjects()">
        <div class="card-header"><h5>{{oController?._projectCount}} Assigned projects</h5></div>
        <div class="card-body">
          <p class="card-text">Here you can manage all assigned projects.</p>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card text-white bg-secondary mb-3" (click)="gotoActivities()">
        <div class="card-header"><h5>{{oController?._activityCount}} Activities</h5></div>
        <div class="card-body">
          <p class="card-text">Here you can view and manage activities.</p>
        </div>
      </div>
    </div> -->
  </div>
</div>


<app-dialog-controller [openModal]="updateControllerParameters" (onClose)="onDetailsClosed()"></app-dialog-controller>
<app-dialog-activity [openModalCreate]="createActivityParameters" (onClose)="onDetailsClosed()"></app-dialog-activity>
