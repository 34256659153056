

export enum TVariableType{
  UNKNOWN = 0,
  STRING = 1,
  INTEGER = 2,
  SHORT = 3,
  LONG = 4,
  DOUBLE = 5,
  BOOLEAN = 6,
  DATE = 7
}

export class TExecBatchTaskVariable {
  constructor(
    public varid: string,
    public taskid: string,
    public name: string,
    public type: TVariableType,
    public value: string
  ) { }

}


export function getVariableType(oVar: TExecBatchTaskVariable) {
  switch (oVar.type) {
    case TVariableType.UNKNOWN:
      return "UNKNOWN";

    case TVariableType.STRING:
      return "STRING";

    case TVariableType.INTEGER:
      return "INTEGER";

    case TVariableType.SHORT:
      return "SHORT";

    case TVariableType.LONG:
      return "LONG";

    case TVariableType.DOUBLE:
      return "DOUBLE";

    case TVariableType.BOOLEAN:
      return "BOOLEAN";

    case TVariableType.DATE:
      return "DATE";

    default:
      return "<" + (oVar.type??'') + ">";
  }
}
