import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TArtifact } from 'src/app/model/tartifact.model';
import { ToastService } from 'src/app/service/toast.service';
import { DialogArtifactComponent } from '../../dialog-artifact/dialog-artifact.component';
import { ProjectService } from 'src/app/service/project.service';
import { getDateLocaleString } from 'src/app/lib/Utils';


@Component({
  selector: 'app-project-controllers',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.css']
})
export class ArtifactsComponent implements OnInit {

 @ViewChild('dlgArtifact') dlgArtifact: DialogArtifactComponent;

  listaartifact: TArtifact[];
  errormsg;
  siteid: string;
  controllerid: string;
  projectid: string;

  fileName:string;

  closeResult: string = '';

  artifact:string;

  constructor(private route: ActivatedRoute, private projectservice: ProjectService, public toastService: ToastService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.projectid = params['projectid'];
    });
    this.getArtifactsObservable(this.siteid, this.controllerid, this.projectid);
  }

  getArtifactsObservable(siteid: string, controllerid: string, projectid: string){
    this.errormsg ='';
    this.projectservice.getArtifactsObservable(projectid)
      .subscribe(
        {
          next: risp => this.listaartifact = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Error');
          }
        });
  }

  convertDateToString(date:Date){
    return getDateLocaleString(date);
  }

  btnShow(artifact:any)
  {
    this.artifact = artifact;
    this.dlgArtifact.doShow(artifact, "Detail artifact");
  }

  doDeleteArtifact(artifact:any)
  {
    this.errormsg ='';
    this.projectservice.doDeleteArtifactObservable(this.projectid, artifact.artifactid)
      .subscribe(
        {
          next: risp =>
          {
            if (risp === true) {
              this.toastService.showSuccessToast("Artifact [" + artifact.artifactid + "] deleted correctly.", 'Artifact');
              this.getArtifactsObservable(this.siteid, this.controllerid, this.projectid);
            }
            else {
              this.toastService.showErrorToast("Cannot be deleted", 'Artifact');
            }

          },
          error:error => {
            this.toastService.showErrorToast(error, 'Artifact');
          }
        });
  }


  OnClosed($event)
  {
    this.artifact = null;
  }

}
