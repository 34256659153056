import { HttpClient } from '@angular/common/http';
import { TVersion } from '../model/tversion.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TLog } from '../model/tlog.model';
import { getFilterParams, handleErrorObs } from '../lib/Utils';
import { PagedData } from '../lib/table/pagedData';
import { SearchFilter } from '../lib/table/search.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class RobserverService {
  constructor(private http: HttpClient, private utilService: UtilService) { }

  getVersionObservable(): Observable<TVersion> {
    return this.http.get<TVersion>(this.utilService.getBasePath() + "/api/v1/pub/version")
      .pipe(
        map((risposta: TVersion) => risposta),
        catchError(handleErrorObs)
      );
  }

  getLogsObservable(filter: SearchFilter|null): Observable<PagedData<TLog[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/logs", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getControllerLogsObservable(siteid: string, controllerid: string, filter: SearchFilter|null): Observable<PagedData<TLog[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/logs", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getActivityLogsObservable(siteid: string, controllerid: string, activityid: string, filter: SearchFilter|null): Observable<PagedData<TLog[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/activity-details/"+ activityid + "/logs", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteLogsSiteObservable(siteid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/logs")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteLogsControllerObservable(siteid: string, controllerid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/logs")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }
}



