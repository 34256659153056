import { getElapsed, getElapsedSec } from "../lib/Utils";
import { TExecBatchTaskVariable } from "./texecbatchtaskvariable.model";

export enum TBatchTaskStatus{
  UNKNOWN,
  NEW,
  EMPTY,
  RUNNING,
  ERROR,
  SUCCESS
}

export class TBatchTask{
  constructor(
     public taskid: string,
     public batchid: string,
     public ruleid: string,
     public taskstatus: number, //UNKNOWN, NEW, EMPTY, RUNNING, ERROR, SUCCESS
     public startdatetime: Date,
     public lastupdate: Date,
     public rectimestamp: Date,
     public lastmessage: string,

     public _elapsed_sec: number,
     public _elapsed: number,

     public vVariables:TExecBatchTaskVariable[]
  ){}
}

export function getBatchTaskStatus(oExec: TBatchTask)
{
  switch(oExec.taskstatus)
    {
      case TBatchTaskStatus.UNKNOWN:
        return "UNKNOWN";

        case TBatchTaskStatus.NEW:
        return "NEW";

        case TBatchTaskStatus.EMPTY:
        return "EMPTY";

        case TBatchTaskStatus.RUNNING:
        return "RUNNING";

        case TBatchTaskStatus.ERROR:
        return "ERROR";

        case TBatchTaskStatus.SUCCESS:
        return "SUCCESS";


      default:
        return "<"+oExec.taskstatus?.toString()+">";
    }
}

export function doParsePropertiesBatchTask(execs: any[]): TBatchTask[] {
  execs.forEach(x => {
    x.taskStatus = getTaskStatus(x);
    var elapsedTmp = x._elapsed_sec;
    x._elapsed_sec = getElapsedSec(elapsedTmp);
    x._elapsed = getElapsed(elapsedTmp);
  });
  return execs;
}


  export function getTaskStatus(oExec: TBatchTask)
  {
    return getBatchTaskStatus(oExec)
  }
