import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TActivity, getActivityStatus } from 'src/app/model/tactivity.model';
import { ToastService } from 'src/app/service/toast.service';
import { ActivitiesService } from 'src/app/service/activities.service';
import { TProject } from 'src/app/model/tproject.model';
import { ProjectService } from 'src/app/service/project.service';
import { getDateLocaleString } from 'src/app/lib/Utils';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-activity',
  templateUrl: './dialog-activity.component.html',
  styleUrls: ['./dialog-activity.component.css']
})
export class DialogActivityComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  projectList: TProject[];

  siteid:string;
  controllerid:string;
  activityid:string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.oCurrentActivity = request;
      this.getActivityDetailsObservable(this.siteid, this.controllerid, this.activityid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.oCurrentActivity = new TActivity(null, request.controllerid, request.projectid, null, null, null, null, null, null, null, false, false, null, null, null, null, null, null);
      this.updateForm();
      this.getProjectsObservable();
      this.formModal.show();
    }
  }

  getProjectsObservable(){
    this.errormsg ='';
    this.projectservice.getControllerProjectsObservable(this.siteid, this.controllerid)
      .subscribe(
        {
          next: risp => this.projectList = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentActivity:TActivity;
  errormsg;

  modalForm = new FormGroup({
    activityid: new FormControl(),
    controllerid: new FormControl(),
    projectid: new FormControl(null, [Validators.required]),
    _status: new FormControl(),
    rectimestamp: new FormControl(),
    updatetimestamp: new FormControl(),
    updatestatustimestamp: new FormControl(),
    description: new FormControl(),
    exec_retention: new FormControl(),
    batch_retention: new FormControl(),
    restartAfterSuccess: new FormControl(),
    restartAfterError: new FormControl(),
    schedule: new FormControl(),
    nextRun: new FormControl(),
    note: new FormControl()
  });

  constructor(private activitiesService:ActivitiesService,
    private projectservice: ProjectService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("activityDetailsModal")
    );

  }

  doSave(){
    this.oCurrentActivity.activityid = this.modalForm.get('activityid').value;
    this.oCurrentActivity.controllerid = this.modalForm.get('controllerid').value;
    this.oCurrentActivity.projectid = this.modalForm.get('projectid').value;
    if(this.oCurrentActivity.projectid === null){
      this.toastService.showErrorToast("Project field is mandatory", 'Activity');
      return;
    }
    this.oCurrentActivity.description = this.modalForm.get('description').value;

    this.oCurrentActivity.exec_retention = this.modalForm.get('exec_retention').value;
    this.oCurrentActivity.batch_retention = this.modalForm.get('batch_retention').value;

    this.oCurrentActivity.restartAfterSuccess = this.modalForm.get('restartAfterSuccess').value;
    this.oCurrentActivity.restartAfterError = this.modalForm.get('restartAfterError').value;
    this.oCurrentActivity.schedule = this.modalForm.get('schedule').value;

    this.oCurrentActivity.note = this.modalForm.get('note').value;

    if((this.oCurrentActivity.restartAfterSuccess || this.oCurrentActivity.restartAfterError)
      && !this.oCurrentActivity.schedule){
      this.toastService.showWarningToast('If restart option is selected, a schedule is mandatory', 'Activities');
      return;
    }

    if(this.bCreate){
      this.oCurrentActivity.status = 0;
      this.doCreateActivityDetailsObservable(this.siteid, this.oCurrentActivity);
    }
    else{
      this.doUpdateActivityDetailsObservable(this.siteid, this.activityid, this.oCurrentActivity);
    }
  }

  updateForm()
  {
    this.modalForm.setValue({activityid: this.oCurrentActivity.activityid,
      controllerid: this.oCurrentActivity.controllerid,
      projectid: this.oCurrentActivity.projectid??null,
      _status: getActivityStatus(this.oCurrentActivity),
      rectimestamp: this.oCurrentActivity.rectimestamp ? getDateLocaleString(this.oCurrentActivity.rectimestamp) : null,
      updatetimestamp: this.oCurrentActivity.updatetimestamp ? getDateLocaleString(this.oCurrentActivity.updatetimestamp) : null,
      updatestatustimestamp: this.oCurrentActivity.updatestatustimestamp ? getDateLocaleString(this.oCurrentActivity.updatestatustimestamp) : null,
      description: this.oCurrentActivity.description ?? null,
      exec_retention: this.oCurrentActivity.exec_retention??null,
      batch_retention: this.oCurrentActivity.batch_retention??null,
      restartAfterSuccess: this.oCurrentActivity.restartAfterSuccess??null,
      restartAfterError: this.oCurrentActivity.restartAfterError??null,
      schedule: this.oCurrentActivity.schedule??null,
      nextRun: this.oCurrentActivity.nextRun ? getDateLocaleString(this.oCurrentActivity.nextRun) : null,
      note: this.oCurrentActivity.note??null,
    } );
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getActivityDetailsObservable(siteid:string, controllerid:string, activityid:string){
    this.errormsg ='';
    this.activitiesService.getActivityDetailsObservable(siteid, controllerid, activityid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentActivity = risp;
            this.updateForm();
            this.formModal.show();
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activities');
          }
        });
  }

  doCreateActivityDetailsObservable(siteid: string, oCurrentActivity:TActivity){
    this.errormsg ='';
    this.activitiesService.postActivityDetailsObservable(siteid, oCurrentActivity)
      .subscribe(
        {
          next: risp => {
            this.oCurrentActivity = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Activity saved correctly.", 'Activity');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }

  doUpdateActivityDetailsObservable(siteid: string, activityid: string, oCurrentActivity:TActivity){
    this.errormsg ='';
    this.activitiesService.putActivityDetailsObservable(siteid, activityid, oCurrentActivity)
      .subscribe(
        {
          next: risp => {
            this.oCurrentActivity = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Activity saved correctly.", 'Activity');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }

  updateCron(){
    this.updateCronParameter = {cronExpression: this.oCurrentActivity?.schedule};
  }

  updateCronParameter:any;

  onCronClosed(cronExpression: any)
  {
    this.modalForm.controls.schedule.setValue(cronExpression);
  }
}
