<!-- Toaster -->
<app-toaster></app-toaster>

<!-- Spinner -->
<app-spinner></app-spinner>

<div class="top-container d-flex justify-content-between">
  <div style="display: flex;">
    <img style="cursor: pointer;" src="favicon.ico" width="50" height="50" (click)="goToHomepage()">
    <h3 class="d-none d-lg-block"><span style="color:white"><b>{{title}}</b></span></h3>
  </div>
  <div class="nav-item dropdown" *ngIf="isLogged">
    <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
      aria-expanded="false">
      <span style="color:white"><b><i class="bi bi-person-circle fs-3 mx-2 align-middle"></i><span class="align-middle">{{getUserName()}}</span></b></span>
    </a>
    <ul class="dropdown-menu" style="cursor: pointer;" aria-labelledby="navbarDropdown">
      <li><a class="dropdown-item text-black" aria-current="page" href="https://www.gpagroup.it/wp-content/uploads/2023/07/GPA_RobotycA_Server_UserManual_ITA.pdf" target=”_blank”><span class="align-middle">Manual</span></a></li>
      <li><a class="dropdown-item text-black" aria-current="page" (click)="doLogout()"><span class="align-middle">Logout</span></a></li>
    </ul>
  </div>
</div>

<div class="page">
  <div id="myHeader">

    <!-- Toolbar -->
    <nav class="navbar navbar-expand-lg navbar-light" style="background:rgb(186, 186, 128)">
      <div class="container-fluid">
        <a *ngIf="sticky" class="navbar-brand" [routerLink]="['/']"><span style="color:white"><b>{{appname}}</b></span></a>
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active text-white" aria-current="page" [routerLink]="['/']">Dashboard</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownRepository" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Repository
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownRepository">
                <a class="dropdown-item text-black" aria-current="page" [routerLink]="['/projects']">Projects</a>
              </div >
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownTools" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Tools
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownTools">
                <a class="dropdown-item text-black" aria-current="page" [routerLink]="['/logs']">Logs</a>
                <a class="dropdown-item text-black" aria-current="page" [routerLink]="['/settings']">Settings</a>
                <a class="dropdown-item text-black" aria-current="page" [routerLink]="['/information']">Information</a>
              </div>
            </li>

            <!-- <li class="nav-item">
              <a class="nav-link disabled">Disabled</a>
            </li> -->
          </ul>
          <ng-version>Loading version...</ng-version>
        </div>
      </div>
    </nav>
  </div>

  <lib-breadcrumb></lib-breadcrumb>

  <router-outlet *ngIf="isLogged"></router-outlet>
  
  <!-- Footer -->
  <div class="footer">
    <b>Robotyca Suite</b>&nbsp;-&nbsp;<a href="http://www.gpagroup.it" target="_blank" rel="noopener"
      Style="color:white"> GPA Gruppo Progetti Avanzati Srl</a>
  </div>

  <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677"
    viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39"
      d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z"
      transform="translate(142.69 -634.312)" fill="#eee" />
  </svg>

</div>

<app-confirm-dialog></app-confirm-dialog>
