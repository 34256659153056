import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/service/toast.service';
import { ActivitiesService } from 'src/app/service/activities.service';
import { TActivityVariableFull } from 'src/app/model/tactivityvariablefull.model';
import { TActivityVariableRequest } from 'src/app/model/tactivityvariablerequest.model';
import { getVariableType } from 'src/app/model/tvariable.model';
import { ProjectService } from 'src/app/service/project.service';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-activity-variable',
  templateUrl: './dialog-activity-variable.component.html',
  styleUrls: ['./dialog-activity-variable.component.css']
})
export class DialogActivityVariableComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  siteid:string;
  controllerid:string;
  activityid:string;
  projectid: string;
  varid: string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.siteid = request.siteid;
      this.bCreate = false;
      this.projectid = request.projectid;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.varid = request.varid;
      this.oCurrentActivityVar = request;
      this.getActivityVariableObservable(this.siteid, this.controllerid, this.activityid, this.projectid, this.varid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.oCurrentActivityVar = new TActivityVariableFull(null, request.activityid, request.value, request._projectVariable);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentActivityVar:TActivityVariableFull;
  errormsg;

  modalForm = new FormGroup({
    variablename: new FormControl(),
    variabletype: new FormControl(),
    variablevalue: new FormControl(),
    value: new FormControl(),
  });

  constructor(private projectservice:ProjectService,
              private activitiesService:ActivitiesService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("activityDetailsModal")
    );

  }

  doSave(){

    this.oCurrentActivityVar.value = this.modalForm.get('value').value;

    if(this.bCreate){
      this.doCreateActivityVariableObservable(this.siteid, this.controllerid, this.activityid, this.oCurrentActivityVar);
    }
    else{
      this.doUpdateActivityVariableObservable(this.siteid, this. controllerid, this.activityid, this.varid, this.oCurrentActivityVar);
    }
  }

  updateForm()
  {
    this.modalForm.setValue({
      variablename: this.oCurrentActivityVar._projectVariable.name,
      variabletype: this.getVariableTypeString(),
      variablevalue: this.oCurrentActivityVar._projectVariable.value,
      value: this.oCurrentActivityVar.value,
    } );
  }

  getVariableTypeString()
  {
    if (this.oCurrentActivityVar != undefined)
    {
    return getVariableType(this.oCurrentActivityVar._projectVariable);

    }
    else return "???";
  }

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  deleteVariable(){
    this.activitiesService.deleteActivityVariableObservable(this.siteid, this.controllerid, this.activityid, this.varid)
      .subscribe(
        {
          next: risp =>
          {
            if (risp == true)
            {
              this.onClose.emit("done");
              this.formModal.hide();
            }
          },
          error: error =>
          {
            this.toastService.showErrorToast(error, 'Set default to activity\'s variable');
          }
        });

  }

  getActivityVariableObservable(siteid:string, controllerid:string, activityid:string, projectid: string, varid: string){
    this.errormsg ='';
    this.activitiesService.getActivityVariableObservable(siteid, controllerid, activityid, varid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentActivityVar = risp;
            this.updateForm();
            this.formModal.show();
          },

          error: error => {
            this.errormsg = error;
            if (this.errormsg.endsWith("not found"))
            {
              this.projectservice.getProjectVariableObservable(projectid, varid)
              .subscribe(
              {
                next: risp =>
                {
                  // ---------------------------------------------------------------------
                  //  Esiste la project variable, allora posso creare l'activity
                  //  variable che non esiste
                  // ---------------------------------------------------------------------
                  this.bCreate = true;
                  this.oCurrentActivityVar = new TActivityVariableFull(this.varid, this.activityid, risp.value, risp);
                  this.updateForm();
                  this.formModal.show();
                },
                error: error =>{
                  this.toastService.showErrorToast(error, 'Activities variable');
                }
              });

            }
            else
            this.toastService.showErrorToast(error, 'Activities variable');
          }
        });
  }

  doCreateActivityVariableObservable(siteid: string, controllerid: string, activityid: string, oCurrentActivity:TActivityVariableRequest){
    this.errormsg ='';
    this.activitiesService.postActivityVariableObservable(siteid, controllerid, activityid, oCurrentActivity)
      .subscribe(
        {
          next: risp => {
            //this.oCurrentActivityVar = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Activity variable saved correctly.", 'Activity variable');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity variable');
          }
        });
  }

  doUpdateActivityVariableObservable(siteid: string, controllerid: string, activityid: string, varid: string, oRequest:TActivityVariableRequest){
    this.errormsg ='';
    this.activitiesService.putActivityVariableObservable(siteid, controllerid, activityid, varid, oRequest)
      .subscribe(
        {
          next: risp => {
            this.oCurrentActivityVar = risp;
            this.onClose.emit("done");
            this.formModal.hide();
            this.toastService.showSuccessToast("Activity variable saved correctly.", 'Activity variable');
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity variable');
          }
        });
  }
}
