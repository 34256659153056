import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})
export class LogonComponent implements OnInit {

  constructor(private router: Router, 
    private authService: AuthService, private oauthService: OAuthService) { }

  ngOnInit(): void {
    if(this.authService.hasValidIdToken) {
      this.goToHomepage();
      return;
    }

    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received' || e.type == 'token_expires'))
      .subscribe((e) => {
        if (e.type === 'token_received'){
          this.oauthService.loadUserProfile();
          this.goToHomepage();
        }
        if (e.type === 'token_expires'){
          this.authService.doLogout();
        }
      });
  }

  goToHomepage(){
    this.router.navigate(['/']);
  }

}
