<div (click)="gotoActivity(activity)">
  <div class="card-header d-flex justify-content-between">
    <div class="col-10">
      <h5 class="card-title title-style">{{activity.description}}</h5>
      <i class="card-title title-style">{{activity._projectSummary}}</i>
    </div>
    <div class="col-auto">
      <div *ngIf="!isLoaded" class="spinner-border sr-only" role="status">
        <!-- <span class="">...</span> -->
      </div>
      <div *ngIf="isLoaded">
        <div *ngIf="index == 0" class="spinner-grow text-success" role="status">
          <!-- <span class="sr-only">Loading...</span> -->
        </div>
        <i *ngIf="index == 1" class="bi bi-brightness-high h1" style ="color: green;"></i>
        <i *ngIf="index == 2" class="bi bi-cloud-sun h1" style ="color: yellow;"></i>
        <i *ngIf="index == 3" class="bi bi-cloud-rain h1" style ="color: red;"></i>
        <i *ngIf="index == 4" class="bi bi-toggle-off h1" style ="color: grey;"></i>
        <i *ngIf="index == 5" class="bi bi-toggle-on h1" style="color: green;"></i>
      </div>
    </div>
  </div>

  <div>
    <div class="card-body d-lg-block">
      <div class="card-header row mb-1">
        <h6 class="sub-title-style">{{getActivityStatus(activity)}}</h6>
        <p class="col d-flex align-items-center card-text text-left">{{activity.note}}</p>
      </div>
    </div>
  </div>
