import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TSite } from 'src/app/model/tsite.model';
import { SiteService } from 'src/app/service/site.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  listasite: TSite[];
  errormsg;

  createSiteParameters:any;

  constructor(private siteservice: SiteService, private router:Router) { }

  ngOnInit(): void {
    this.doReload()
  }
  
  doReload() {
    this.getSitesObservable();
  }
  
  getSitesObservable(){
    this.siteservice.getSitesObservable()
      .subscribe(
        {
          next: risp => {
            this.listasite = risp;
          },
          error:error => this.errormsg = error
        });
  }

  gotoProjects(){
    this.router.navigate(['/projects']);
  }


  addNewSite()
  {
    this.createSiteParameters = {};
  }

  onDetailsClosed()
  {
    this.createSiteParameters = null;
    this.getSitesObservable();
  }

}
