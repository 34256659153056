<div class="modal hide fade in" data-bs-keyboard="false" data-bs-backdrop="static" id="controllerDetailsModal"
  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">Project {{oCurrentController?.controllerid}} - details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
          <label for="controllerid" class="col-form-label">Controller Id</label>
          <input type="text" class="form-control" id="controllerid" formControlName="controllerid"
            [readonly]="!bCreate">
          <div *ngIf="form.controls['controllerid'].invalid" class="alert alert-danger">
            <div *ngIf="form.controls['controllerid'].errors?.['required']">
              ID is required.
            </div>
          </div>

          <label for="siteid" class="col-form-label">Site</label>
          <input type="text" class="form-control" id="siteid" formControlName="siteid" readonly>

          <label for="controllerdescr" class="col-form-label">Description</label>
          <textarea class="form-control" id="controllerdescr" formControlName="controllerdescr"></textarea>
          <div class='row'>
            <div class="col-md-3">
              <label for="controllerip" class="col-form-label">IP</label>
              <input type="text" class="form-control" id="controllerip" formControlName="controllerip">
            </div>
            <div class="col-md-3">
              <label for="userid" class="col-form-label">User Id</label>
              <input type="text" class="form-control" id="userid" formControlName="userid">
            </div>
          </div>

          <div class='row'>
            <div class="col-md-8">
              <label for="status-name" class="col-form-label">Status</label>
              <input type="text" class="form-control" id="status-name" formControlName="status" readonly>
            </div>
            <div class="col-md-3 mt-4">
              <button *ngIf="!bActive && !bCreate" class="btn btn-primary" (click)="doActive()"><i
                  class="bi bi-play"></i>Active</button>
              <button *ngIf="bActive && !bCreate" class="btn btn-primary" (click)="doUnactive()"><i
                  class="bi bi-stop"></i>Unactive</button>
            </div>
          </div>

          <div class='row'>
            <div class="col-md-4">
              <label for="connection_reqs-name" class="col-form-label">Total connection requests received</label>
              <input type="text" class="form-control" id="connection_reqs-name" formControlName="connection_reqs"
                readonly>
            </div>
            <div class="col-md-4">
              <label for="connecttimestamp-name" class="col-form-label">Last connection timestamp</label>
              <input type="text" class="form-control" id="connecttimestamp-name" formControlName="connecttimestamp"
                readonly>
            </div>
            <div class="col-md-4">
              <label for="disconnecttimestamp-name" class="col-form-label">Last disconnection timestamp</label>
              <input type="text" class="form-control" id="disconnecttimestamp-name"
                formControlName="disconnecttimestamp" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="assigned_token-name" class="col-form-label">Assigned token</label>
              <input type="text" class="form-control" id="assigned_token-name" formControlName="assigned_token"
                readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="log_retention_days-text" class="col-form-label">Log retention days - (0 = disabled)</label>
              <input class="form-control" id="log_retention_days-text" formControlName="log_retention_days">
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="note" class="col-form-label">Note</label>
              <textarea class="form-control" id="note" formControlName="note"></textarea>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
        <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
      </div>
    </div>
  </div>
</div>