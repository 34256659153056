import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/service/toast.service';
import { ExecutionService } from 'src/app/service/execution.service';
import { TBatchTask, getBatchTaskStatus } from 'src/app/model/tbatchtask.model';
import { getDateLocaleString } from 'src/app/lib/Utils';

// --- aggiungere per modal ----
declare var window:any;

@Component({
  selector: 'app-dialog-task',
  templateUrl: './dialog-task.component.html',
  styleUrls: ['./dialog-task.component.css']
})
export class DialogTaskComponent implements OnInit {

  // --- aggiungere per modal ----
  formModal:any;

  siteid:string;
  controllerid:string;
  projectid:string;
  activityid:string;
  executionid: string;
  batchid: string;
  taskid: string;

  bCreate: boolean;

  @Input() set openModal(request:any){
    if (request)
    {
      this.bCreate = false;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.projectid = request.projectid;
      this.activityid = request.activityid;
      this.executionid = request.executionid;
      this.batchid = request.batchid;
      this.taskid = request.taskid;
      this.oCurrentTask = request;
      this.getTaskDetailsObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executionid, this.batchid, this.taskid);

    }
  }

  @Input() set openModalCreate(request:any){
    if (request)
    {
      this.bCreate = true;
      this.siteid = request.siteid;
      this.controllerid = request.controllerid;
      this.activityid = request.activityid;
      this.executionid = request.executionid;
      this.batchid = request.batchid;
      this.oCurrentTask = new TBatchTask(null, request.batchid,null, 0, null, null, null, null, null, null, null);
      this.updateForm();
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();


  oCurrentTask:TBatchTask;
  errormsg;

  modalForm = new FormGroup({
    taskid: new FormControl(),
    batchid: new FormControl(),
    ruleid: new FormControl(),
    controllerid: new FormControl(),
    projectid: new FormControl(),
    activityid: new FormControl(),
    rectimestamp: new FormControl(),
    _elapsed_sec: new FormControl(),
    _execstatus: new FormControl(),
    lastmessage: new FormControl()
  });

  constructor(private executionService:ExecutionService,
              public toastService: ToastService) { }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("taskDetailsModal")
    );

  }

  updateForm()
  {
    this.modalForm.setValue(
    {
      taskid: this.oCurrentTask.taskid,
      batchid: this.oCurrentTask.batchid,
      ruleid: this.oCurrentTask.ruleid,
      controllerid: this.controllerid?? null,
      projectid: this.projectid ?? null,
      activityid: this.activityid ?? null,
      rectimestamp: this.oCurrentTask.rectimestamp ? getDateLocaleString(this.oCurrentTask.rectimestamp) : null,
      _elapsed_sec: this.oCurrentTask._elapsed_sec ?? null,
      _execstatus: getBatchTaskStatus(this.oCurrentTask),
      lastmessage: this.oCurrentTask.lastmessage ?? null
    });
  }
  

  closeModal(){
    this.onClose.emit("done");
    this.formModal.hide();
  }

  getTaskDetailsObservable(siteid:string, controllerid:string, projectid: string, activityid:string, executionid:string, batchid: string, taskid: string){
    this.errormsg ='';
    this.executionService.getTaskDetailsObservable(siteid, controllerid, projectid, activityid, executionid, batchid, taskid)
      .subscribe(
        {
          next: risp =>
          {
            this.oCurrentTask = risp;
            this.updateForm();
            this.formModal.show();
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Executions');
          }
        });
  }
}
