import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { TController } from 'src/app/model/tcontroller.model';
import { ControllerService } from 'src/app/service/controller.service';
import { ToastService } from 'src/app/service/toast.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-controller-list',
  templateUrl: './controller-list.component.html',
  styleUrls: ['./controller-list.component.css']
})
export class ControllerListComponent implements OnInit {

  @Input() siteId;

  listacontroller: TController[];
  errormsg;
  myChart:Chart;
  selectedController: TController;

  constructor(private controllerService: ControllerService,
    public toastService: ToastService,
    private router: Router,
    private utilService: UtilService) { }

  ngOnInit(): void {
    this.getControllersObservable(this.siteId);

    Chart.register(...registerables);
  }

  getConnectTimeStamp(oController: TController)
  {
    if (oController.connecttimestamp != undefined)
    {
      const dt = new Date(oController.connecttimestamp);
      return dt.toLocaleDateString() + " "+dt.toLocaleTimeString();
    }

      return "-";

  }

  doShowDetails(controllerid: string)
  {
    this.router.navigate(['/site/' + this.siteId +'/controller/' + controllerid]);
  }

  getControllersObservable(siteId:string){
    this.errormsg ='';
    this.controllerService.getControllersObservable(siteId)
      .subscribe(
        {
          next: risp =>
          {
            this.listacontroller = risp;
            this.doUpdateChart();
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }

  doUpdateChart() {
    var vlabels: string[] = [];

    var vData: number[] = [];

    var vDataPrj: number[] = [];

    this.listacontroller.forEach(oController => {
      vlabels.push(oController.controllerid);
      vData.push(oController._activityCount);
      vDataPrj.push(oController._projectCount);
    });

    this.myChart?.destroy();  // il chart, se pieno, va prima distrutto per evitare errori in console e rigenerare l'animazione
    this.myChart = new Chart("myChart", {
      type: 'bar',
      data: {
        labels: vlabels,
        datasets: [{
          label: 'Activities',
          data: vData,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            //'rgba(54, 162, 235, 0.2)',
            //'rgba(255, 206, 86, 0.2)',
            //'rgba(75, 192, 192, 0.2)',
            //'rgba(153, 102, 255, 0.2)',
            //'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            //'rgba(54, 162, 235, 1)',
            //'rgba(255, 206, 86, 1)',
            //'rgba(75, 192, 192, 1)',
            //'rgba(153, 102, 255, 1)',
            //'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        },
        {
          label: 'Projects',
          data: vDataPrj,
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1
        }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  getCollectionSize()
  {
    return this.listacontroller.length;
  }

  getPage()
  {
    return 1;
  }

  getPageSize()
  {
    return 10;
  }

  refreshControllers()
  {
    this.getControllersObservable(this.siteId);
  }

  doDelete(controller: TController) {
    this.selectedController = controller;

    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected controller [" + controller.controllerid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.deleteControllerObservable(this.siteId, this.selectedController.controllerid);
          }
        }
      });
  }


  deleteControllerObservable(siteId:string, controllerId:string){
    this.errormsg ='';
    this.controllerService.deleteControllerObservable(siteId, controllerId)
      .subscribe(
        {
          next: risp =>
          {
            if (risp === true)
            {
              this.toastService.showSuccessToast("Controller ["+controllerId+"] deleted correctly.", 'Controller');
              this.refreshControllers();
            }
            else
            {
              this.toastService.showErrorToast("Cannot be deleted", 'Controller');
            }

          },
          error:error => {
            this.toastService.showErrorToast(error, 'Controller');
          }
        });
  }
}
