<div class="mx-2">
  <h1><i class="bi bi-ui-checks text-warning mx-3"></i>Activity variable list</h1>
  <h5>This page shows all variables for the specified activity.</h5>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [activityid]="activityid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>

  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Type</th>
      <th scope="col">Default</th>
      <th scope="col">Value</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let variable of listavariable">
      <th scope="row">{{ variable.varid }}</th>
      <td>{{ variable.name }}</td>
      <td>{{ getVarType(variable) }}</td>
      <td>{{ variable.value }}</td>
      <td>{{ getCurrentValue(variable) }}</td>
      <td><button class="btn btn-primary" (click)="doShowDetails(variable.varid)"><i class="bi bi-pencil"></i>Edit</button></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<app-dialog-activity-variable [openModal]="modifyParameters" [openModalCreate]="createParameters" (onClose)="onDetailsClosed()"></app-dialog-activity-variable>
