import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/service/toast.service';
import { TBatchTask, doParsePropertiesBatchTask} from 'src/app/model/tbatchtask.model';
import { Chart, registerables } from 'chart.js';
import { UtilService } from 'src/app/service/util.service';
import { ExecutionService } from 'src/app/service/execution.service';
import { SearchFilter } from 'src/app/model/searchfilter.model';
import { ActionColumnSort, ActionEvent, TableConfiguration } from 'src/app/lib/table/table.model';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  siteid;
  controllerid;
  projectid;
  activityid;
  executiontid;
  batchid;

  createParameters:any;
  modifyParameters:any;

  myChart:Chart;

  searchFilter = new SearchFilter;
  result: any;

  errormsg;

  tableConfiguration: TableConfiguration = {
    name: '',
    enableAdd: false,
    enableReload: true,
    columns: [
      {
        name: 'Id',
        fieldName: 'taskid',
        columnHead: true,
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Rule',
        fieldName: 'ruleid',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Status',
        fieldName: 'taskStatus',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Start',
        fieldName: 'startdatetime',
        type: 'datetime',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Elapsed',
        fieldName: '_elapsed',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Message',
        fieldName: 'lastmessage',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      }
    ],
    actions: [
      {
        name: 'Details',
        actionName: 'details',
        iconClass: 'bi bi-eye'
      },
      {
        name: 'Output',
        actionName: 'output',
        iconClass: 'bi bi-box-arrow-right'
      },
      {
         name: 'Delete',
         actionName: 'delete',
         buttonClass: 'btn-danger',
         iconClass: 'bi bi-trash'
       }
    ]
  };

  constructor(private route: ActivatedRoute, private utilService: UtilService,
    private executionService: ExecutionService, public toastService: ToastService,
    private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
      this.projectid=params['projectid'];
      this.activityid=params['activityid'];
      this.executiontid=params['executionid'];
      this.batchid=params['batchid'];
    })
    this.getBatchTasksObservable(this.siteid, this.controllerid, this.projectid , this.activityid, this.executiontid, this.batchid, this.searchFilter);
    Chart.register(...registerables);
  }

  // --------------------------------------- Tabella -------------------------------------------

  action(event: ActionEvent) {
    switch (event.actionName) {
      case 'delete':
        this.doDeleteBatchTask(event.value?.taskid);
        break;
      case 'details':
        this.doShowDetails(event.value?.taskid);
      break;
      case 'output':
        this.doShowVariables(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.batchid, event.value?.taskid);
      break;
    }
  }

  doShowVariables(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string, taskid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid + '/activities/' + activityid + '/executions/' + executionid + '/batches/'+batchid+"/tasks/"+taskid+"/output"]);
  }

  sort(event: ActionColumnSort) {
    this.searchFilter.OrderBySQLClause = event.fieldName + ' ' + event.order;
    this.refreshBatchTasks();
  }

  pageChanged(event: ActionEvent) {
    this.searchFilter.pageNumber = Number(event);
    this.refreshBatchTasks();
  }

  doReload() {
    //this.result = [];
    this.refreshBatchTasks();
  }

  hideFunction = (actionName: any, value: TBatchTask): boolean => {
    switch (actionName) {
    }
    return false;
  }

  // -------------------------------------------------------------------------------------------

  doShowDetails(taskid:string)
  {
    this.modifyParameters = {siteid:this.siteid, controllerid:this.controllerid, projectid: this.projectid, activityid:this.activityid, executiontid: this.executiontid, batchid: this.batchid, taskid : taskid };
  }

  onDetailsClosed()
  {
    this.modifyParameters = null;
    this.createParameters = null;
    this.refreshBatchTasks();
  }

  refreshBatchTasks()
  {
    this.getBatchTasksObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.batchid, this.searchFilter);
  }

  getBatchTasksObservable(siteid: string, controllerid: string , projectid: string, activityid: string, executiontid: string, batchid: string, searchFilter: SearchFilter){
    this.errormsg ='';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.executionService.getBatchTasksObservable(siteid, controllerid, projectid, activityid, executiontid, batchid, searchFilter)
      .subscribe(
        {
          next: resp => {
            this.result = resp;
            this.result.dataList = doParsePropertiesBatchTask(resp.dataList);
            //this.listtask = resp;
            if(this.result.dataList?.length > 0){
              this.doUpdateChart();
            }
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Tasks');
          }
        });
  }


  doUpdateChart() {
    var vlabels: string[] = [];

    var vDataRun: number[] = [];

    this.result.dataList.slice(0, 20).forEach(x => {
      if(x.startdatetime){
        vlabels.push(x.startdatetime.toString());
        vDataRun.push(x._elapsed_sec);
      }
    });

    this.myChart?.destroy();
    this.myChart = new Chart("myChart", {
      type: 'bar',
      data: {
        labels: vlabels,
        datasets: [
        {
          label: 'Elapsed (sec)',
          data: vDataRun,
          backgroundColor: [
            'rgba(100, 100, 103, 0.2)'
          ],
          borderColor: [
            'rgba(120, 120, 123, 2)'
          ],
          borderWidth: 1
        }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }



  doDeleteBatchTask(taskid: string)
  {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected task [" + taskid + "]?", true).subscribe(
        {
        next: (res) => {
          if (res) {
            this.executionService.deleteBatchTaskObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.executiontid, this.batchid, taskid )
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Execution Task deleted correctly.", 'Task');
                    this.refreshBatchTasks();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Task');
                  }
                });
            }
          }
        }
      );
  }

}
