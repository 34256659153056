<div class="mx-2">
    <h1><i class="bi bi-info-circle text-warning mx-3"></i>Information</h1>

    <table class="table table-striped">

      <tbody>
        <tr>
          <th scope="row" style="width:40%">Web application version</th>
          <td>{{sAppVersion}}</td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">Backend service version</th>
          <td>{{oVersion.number}}</td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">Framework version</th>
          <td>{{sAngularVersion}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>
