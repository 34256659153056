import { PagingMetaData } from "../lib/table/pagedData";

export class SearchFilter {
  pageNumber: number = 1;
  pageSize: number = 10;
  OrderBySQLClause: string;

  managePagination(pagingMetadata: PagingMetaData, lenghtPage :number) {
    if (pagingMetadata) {
      if(!pagingMetadata.hasNext && this.pageNumber > 1 && lenghtPage === 1){
        this.pageNumber--;
      }
    }
  }
}

