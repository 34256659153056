import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { doParsePropertiesExec, ExecutionFilter, getExecutionStatus, TExecution, TExecutionStatus } from 'src/app/model/texecution.model';
import { ToastService } from 'src/app/service/toast.service';
import { ExecutionService } from 'src/app/service/execution.service';
import { UtilService } from 'src/app/service/util.service';
import { ActionColumnSort, ActionEvent, TableConfiguration } from 'src/app/lib/table/table.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-executions',
  templateUrl: './executions.component.html',
  styleUrls: ['./executions.component.css']
})
export class ExecutionsComponent implements OnInit {

  siteid: string;
  controllerid: string;
  projectid: string;
  activityid: string;
  executionid: string;

  result: any;

  searchForm: FormGroup;
  searchFilter: ExecutionFilter;

  statuses = [
    { id: TExecutionStatus.UNKNOWN, value: 'UNKNOWN' },
    { id: TExecutionStatus.RUNNING, value: 'RUNNING' },
    { id: TExecutionStatus.STOPPED, value: 'STOPPED' },
    { id: TExecutionStatus.StopRequest, value: 'STOP REQUEST' },
    { id: TExecutionStatus.Completed, value: 'COMPLETED' }
  ];

  createParameters: any;
  modifyParameters: any;
  errormsg;

  tableConfiguration: TableConfiguration = {
    name: '',
    enableAdd: false,
    enableReload: false,
    columns: [
      {
        name: 'Id',
        fieldName: 'execid',
        columnHead: true,
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Controller',
        fieldName: 'controllerid',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Status',
        fieldName: 'execStatus',
        sortable: {
          enabled: true,
          order: 'asc'
        }
      },
      {
        name: 'Start',
        fieldName: 'rectimestamp',
        type: 'datetime',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Last update',
        fieldName: '_updated_ago',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Elapsed',
        fieldName: '_elapsed',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      },
      {
        name: 'Batches (ok/tot)',
        fieldName: '_stats',
        sortable: {
          enabled: true,
          order: 'desc'
        }
      }
    ],
    actions: [
      {
        name: 'Stop request',
        actionName: 'stop',
        iconClass: 'bi bi-stop'
      },
      {
        name: 'Batches',
        actionName: 'batches',
        iconClass: 'bi bi-arrow-repeat'
      },
      {
        name: 'Details',
        actionName: 'details',
        iconClass: 'bi bi-eye'
      },
      {
        name: 'Delete',
        actionName: 'delete',
        buttonClass: 'btn-danger',
        iconClass: 'bi bi-trash'
      }
    ]
  };

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private executionService: ExecutionService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.projectid = params['projectid'];
      this.activityid = params['activityid'];
    });
    
    this.route.queryParams
      .subscribe(params => {
        this.executionid = params.executionid;
        this.doInitSearchForm(); 
        this.refreshExecutions();
      }
    );
  }


  doInitSearchFilter() {
    this.searchFilter = new ExecutionFilter();
    if(this.executionid) {
      this.searchFilter.executionid = this.executionid;
    }
  }

  doInitSearchForm() {
    this.doInitSearchFilter();
    this.searchForm = this.formBuilder.group({
      status: null,
      id: this.executionid ?? null
    });
  }

  doSearch() {
    this.doInitSearchFilter();
    if (this.searchForm.controls.status.valid && this.searchForm.value.status) {
      this.searchFilter.execstatus = this.searchForm.value.status;
    }
    if (this.searchForm.controls.id.valid && this.searchForm.value.id) {
      this.searchFilter.executionid = this.searchForm.value.id;
    }
    else{
      this.searchFilter.executionid = null;
    }

    this.getExecutionsObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.searchFilter);
  }

  getExecutionsObservable(siteid: string, controllerid: string, projectid: string, activityid: string, filter: ExecutionFilter) {
    this.errormsg = '';
    this.searchFilter.managePagination(this.result?.pagingMetadata, this.result?.dataList?.length);
    this.executionService.getExecutionsObservable(siteid, controllerid, projectid, activityid, filter)
      .subscribe(
        {
          next: resp => {
            this.result = resp;
            this.result.dataList = doParsePropertiesExec(resp.dataList);
          },
          error: error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Executions');
          }
        });
  }


  // --------------------------------------- Tabella -------------------------------------------



  getExecutionStatus(oExec: TExecution) {
    return getExecutionStatus(oExec)
  }

  action(event: ActionEvent) {
    switch (event.actionName) {
      case 'stop':
        this.doAskStopExecution(event.value);
        break;

      case 'batches':
        this.doShowBatches(this.siteid, this.controllerid, event.value.projectid, event.value.activityid, event.value.execid);
        break;

      case 'details':
        this.doShowDetails(event.value.execid);
        break;

      case 'delete':
        this.deleteExecution(event.value.execid);
        break;
    }
  }

  sort(event: ActionColumnSort) {
    this.searchFilter.OrderBySQLClause = event.fieldName + ' ' + event.order;
    this.refreshExecutions();
  }

  pageChanged(event: ActionEvent) {
    this.searchFilter.pageNumber = Number(event);
    this.refreshExecutions();
  }

  doReload() {
    //this.result = [];
    this.refreshExecutions();
  }

  hideFunction = (actionName: any, value: TExecution): boolean => {
    switch (actionName) {
      case 'stop':
        return !this.isRunning(value);
    }
    return false;
  }

  // -------------------------------------------------------------------------------------------

  doShowBatches(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string) {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid + '/activities/' + activityid + '/executions/' + executionid + '/batches']);
  }

  doShowDetails(executionid: string) {
    this.modifyParameters = { siteid: this.siteid, controllerid: this.controllerid, projectid: this.projectid, activityid: this.activityid, executionid: executionid };
  }

  onDetailsClosed() {
    this.modifyParameters = null;
    this.createParameters = null;
    this.refreshExecutions();
  }

  deleteExecution(executionid: string) {
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected execution [" + executionid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.executionService.deleteExecutionObservable(this.siteid, this.controllerid, this.projectid, this.activityid, executionid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Execution information deleted correctly.", 'Execution');
                    this.refreshExecutions();
                  },
                  error: error => {
                    this.toastService.showErrorToast(error, 'Execution');
                  }
                });
          }
        }
      });
  }

  refreshExecutions() {
    this.getExecutionsObservable(this.siteid, this.controllerid, this.projectid, this.activityid, this.searchFilter);
  }

  isRunning(execution: TExecution) {
    if (execution.execstatus === TExecutionStatus.RUNNING) {
      return true;
    }
    return false;
  }

  doAskStopExecution(execution: TExecution) {
    this.errormsg = '';
    this.utilService.openConfirmDialog(
      "Are you sure you want to send stop request of the selected execution [" + execution.execid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.executionService.sendStopRequestExecutionObservable(this.siteid, this.controllerid, this.projectid, this.activityid, execution.execid)
              .subscribe(
                {
                  next: risp => {
                    this.toastService.showSuccessToast("Execution stop request sent.", 'Execution');
                    this.refreshExecutions();
                  },
                  error: error => {
                    this.errormsg = error;
                    this.toastService.showErrorToast(error, 'Execution');
                  }
                });
          }
        }
      });
  }

}
