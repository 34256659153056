<div>
  <label class="active">{{text}}</label>
  <select class="form-control form-select" [(ngModel)]="value" (change)="onChange($event)" [disabled]="disabled">
    <option *ngIf="addFirstItem" value="">Select an option</option>
    <option *ngFor="let item of data" [value]="item[fieldId]">{{item[fieldText]}}</option>
  </select>
  <div class="invalid-feedback" *ngIf="submitted && control?.errors?.required">
    Field required
  </div>
</div>
<div class="notfound" *ngIf="valueNotFound()">
  <{{value}}>
</div>
