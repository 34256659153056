import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor, Validator {
  value: any;
  //errors: ValidationErrors;
  control: any;
  @Input() submitted = false;
  @Input() addFirstItem: boolean = false;
  @Input() text: string;
  @Input() path: string;
  @Input() data: any[];
  @Input() fieldText: string;
  @Input() fieldId: string;
  @Output() selected = new EventEmitter();
  @Input() disabled: boolean = false;
  oldValue: string;

  propagateChange = (_: any) => { };
  onTouched: () => void = () => { };
  onValidatorChange: () => void = () => { };

  constructor() {
  }


  writeValue(obj: any): void {
    this.value = obj ?? '';
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    return null;
  }

  ngOnInit(): void {
  }

  onChange(event: any) {
    this.propagateChange(this.value);
    this.selected.emit(this.value);
  }

  valueNotFound() {
    const res = this.value !== undefined && this.value != '' && this.data.findIndex(x => {
      return String(x[this.fieldId]) === String(this.value);
    }) < 0;
    return res;
  }
}
