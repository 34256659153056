import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TProject } from '../model/tproject.model';
import { handleErrorObs } from '../lib/Utils';
import { TArtifact } from '../model/tartifact.model';
import { TArtifactRequest } from '../model/tartifactrequest.model';
import { TVariable } from '../model/tvariable.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getControllerProjectsObservable(siteid: string, controllerid: string): Observable<TProject[]> {

    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  patchUnassignControllerProjectsObservable(siteid: string, controllerid: string, projectid: string): Observable<boolean> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/project-unassign/" + projectid, null)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  patchAssignControllerProjectsObservable(siteid: string, controllerid: string, projectid: string): Observable<boolean> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/project-assign/" + projectid, null)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }


  getProjectsObservable(): Observable<TProject[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/project-details")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getProjectDetailsObservable(projectid: string): Observable<TProject> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/project-details/" + projectid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  putProjectDetailsObservable(oProject: TProject): Observable<TProject> {
    return this.http.put(this.utilService.getBasePath() + "/api/v1/project-details/" + oProject.projectid, oProject)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteProjectObservable(projectid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/project-details/" + projectid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getArtifactsObservable(projectid: string): Observable<TArtifact[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/projects/" + projectid + "/artifacts")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  doCreateArtifactObservable(projectid: string, oRequest: TArtifactRequest): Observable<TArtifact> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(oRequest);
    return this.http.post(this.utilService.getBasePath() + "/api/v1/projects/" + projectid + "/artifacts", body, { 'headers': headers })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  doDeleteArtifactObservable(projectid: string, artifactid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/projects/" + projectid + "/artifacts/" + artifactid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getProjectVariablesObservable(projectid: string): Observable<TVariable[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/project-variables/" + projectid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getProjectVariableObservable(projectid: string, varid: string): Observable<TVariable> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/projects/" + projectid+"/variables/"+varid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }
}



