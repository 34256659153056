<div class="modal hide fade in" data-bs-keyboard="false" data-bs-backdrop="static" id="activityDetailsModal"
  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalLabel">Activity {{oCurrentActivity?.activityid}} - details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="modalForm">

          <div class='row'>
            <div class="col-md-6">
              <label for="activityid-name" class="col-form-label">Activity Id</label>
              <input type="text" class="form-control" id="activityid-name" formControlName="activityid" readonly>
            </div>
            <div class="col-md-6">
              <label for="controllerid-name" class="col-form-label">Controller Id</label>
              <input type="text" class="form-control" id="controllerid-name" formControlName="controllerid" readonly>
            </div>
          </div>

          <div class='row'>
            <div class="col-md-6">
              <label for="projectid-name" class="col-form-label">Project Id</label>
              <input *ngIf="!bCreate" type="text" class="form-control" id="projectid-name" formControlName="projectid"
                readonly>
              <select *ngIf="bCreate" class="form-control form-select" aria-label="Project" id="projectid-name"
                formControlName="projectid" required>
                <option *ngFor="let project of projectList" [value]="project?.projectid"
                  [selected]="project?.projectid === modalForm.value.projectid?.projectid">
                  {{project?.projectid}} {{project?.summary}}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="status-name" class="col-form-label">Status</label>
              <input type="text" class="form-control" id="status-name" formControlName="_status" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-4">
              <label for="rectimestamp-name" class="col-form-label">Created</label>
              <input type="text" class="form-control" id="rectimestamp-name" formControlName="rectimestamp" readonly>
            </div>
            <div class="col-md-4">
              <label for="updatetimestamp-name" class="col-form-label">Last update</label>
              <input type="text" class="form-control" id="updatetimestamp-name" formControlName="updatetimestamp"
                readonly>
            </div>
            <div class="col-md-4">
              <label for="updatestatustimestamp-name" class="col-form-label">Last status update</label>
              <input type="text" class="form-control" id="updatestatustimestamp-name"
                formControlName="updatestatustimestamp" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="description-text" class="col-form-label">Description</label>
              <textarea class="form-control" id="description-text" formControlName="description"></textarea>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-6">
              <label for="exec_retention-text" class="col-form-label">Execution retention - (0 = disabled)</label>
              <input class="form-control" id="exec_retention-text" formControlName="exec_retention">
            </div>
            <div class="col-md-6">
              <label for="batch_retention-text" class="col-form-label">Batch retention - (0 = disabled)</label>
              <input class="form-control" id="batch_retention-text" formControlName="batch_retention">
            </div>
          </div>
          <div class='row'>
            <div class="col-md-3">
              <div class="row mx-3 my-3 form-check form-switch">
                <input class="form-check-input h5" type="checkbox" id="restartAfterSuccess-text"
                  formControlName="restartAfterSuccess">
                <label for="restartAfterSuccess-text" class="mt-1 form-check-label">Restart After Success</label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row mx-3 my-3 form-check form-switch">
                <input class="form-check-input h5" type="checkbox" id="restartAfterError-text"
                  formControlName="restartAfterError">
                <label for="restartAfterError-text" class="mt-1 form-check-label">Restart After Error</label>
              </div>
            </div>
            <div class="col-md-4">
              <label for="schedule-text" class="col-form-label">Schedule (Cron)</label>
              <input class="form-control" id="schedule-text" formControlName="schedule" readonly>
            </div>
            <div class="col-md-2 mt-4">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="updateCron()">Edit Cron</button>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="nextRun-text" class="col-form-label">Next scheduled execution</label>
              <input class="form-control" id="nextRun-text" formControlName="nextRun" readonly>
            </div>
          </div>
          <div class='row'>
            <div class="col-md-12">
              <label for="note-text" class="col-form-label">Note</label>
              <textarea class="form-control" id="note-text" formControlName="note"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
      </div>
    </div>
  </div>
</div>

<app-dialog-cron [openModal]="updateCronParameter" (onClose)="onCronClosed($event)"></app-dialog-cron>