<div *ngIf="errormsg">{{errormsg}}</div>

<div id="divChart">
  <canvas class="d-none d-sm-block" id="myChart" width="400" height="100" style="margin-left: 2rem;margin-right: 2rem;"></canvas>
</div>

<!-- <button type="button" class="btn btn-primary mt-5" (click)="refreshControllers()" >
  <i class="bi bi-arrow-clockwise"></i>
  Refresh
</button>

<div class="table-responsive">
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Id</th>
    <th scope="col">Site</th>
    <th scope="col">Description</th>
    <th scope="col">Status</th>
    <th scope="col">Connections</th>
    <th scope="col">Last connection</th>
    <th scope="col">Projects</th>
    <th scope="col">Activities</th>
    <th scope="col">Details</th>
    <th scope="col">Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let controller of listacontroller">
    <th scope="row">{{ controller.controllerid }}</th>
    <td>{{siteId}}</td>
    <td>{{ controller.controllerdescr }}</td>
    <td>{{ controller.status }}</td>
    <td>{{ controller.connection_reqs}}</td>
    <td>{{ getConnectTimeStamp(controller) }}</td>
    <td>{{ controller._projectCount }}</td>
    <td>{{ controller._activityCount }}</td>
    <td><button class="btn btn-primary" (click)="doShowDetails(controller.controllerid)"><i class="bi bi-eye"></i>Details</button></td>
    <td><button class="btn btn-danger" (click)="doDelete(controller)"><i class="bi bi-trash"></i>Delete</button></td>
  </tr>
  </tbody>
</table>
</div> -->

