<div class="mx-2">
    <h1><i class="bi bi-gear text-warning mx-3"></i>Settings</h1>

    <table class="table table-striped">

      <tbody>
        <tr>
          <th scope="row" style="width:40%">Robotyca service URL</th>
          <td>{{apiUrl}}</td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">Issuer</th>
          <td>{{issuer}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>

</div>
