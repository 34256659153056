import { HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject, throwError } from "rxjs";

export function deepCopy(obj: any): any {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' !== typeof obj) { return obj; }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  // if (obj instanceof Object) {
  //   copy = {};
  //   for (const attr in obj) {
  //     if (obj.hasOwnProperty(attr)) { copy[attr] = deepCopy(obj[attr]); }
  //   }
  //   return copy;
  // }

  throw new Error('Unable to copy obj! Its type isn\'t supported.');
}


export function isValuedString(value: string | null | undefined): boolean {
  if (value == null || value === undefined) { return false; }
  if (typeof value !== 'string') { throw Error('Value is supposed to be a string.'); }
  if (value.length <= 0) { return false; }
  return true;
}

export function isNullOrWhitespace(input: string): boolean {
  return input == null || input === undefined || (typeof input === 'string' && input.trim().length <= 0);
}

/**
 *
 * @param data può essere booleano o null
 */
export function getStringfromBoolean(data: boolean): string {
  return (data != null) ? data.toString() : '';
}

/**
 *
 * @param data può essere booleano o stringa
 */
export function readAsBoolean(data: any): boolean | null {
  let retVal = null;
  if (data == null) { retVal = null; }
  else {
    if (typeof data === 'boolean') { retVal = data; }
    else if (typeof data === 'string') {
      if (data.toLowerCase() === 'true') { retVal = true; }
      else if (data.toLowerCase() === 'false') { retVal = false; }
      else if (data.toLowerCase() === '') { retVal = null; }
      else { throw Error(data + '" value does not represent an expected boolean case.'); }
    }
    else { throw Error(data + '" value of type  "' + typeof data + '" does not represent an expected boolean case.'); }

  }

  return retVal;
}

/**
 *
 * @param data può essere stringa o null
 */
export function readAsString(data: any): string {
  let retVal: string = '';
  if (data == null) { retVal = ''; }
  else {
    if (typeof data === 'string') { retVal = data; }
    else { throw Error(data + '" value of type  "' + typeof data + '" does not represent an expected string case.'); }
  }
  return retVal;
}

/**
 *
 * @param data può essere date o stringa
 */
export function readAsDate(data: any): Date | null {
  let retVal = null;
  if (data == null) { retVal = null; }
  else {
    if (typeof data === 'object' && data instanceof Date) { retVal = data; }
    else if (typeof data === 'string') {
      const tDate = new Date(data);
      if (tDate != null) { retVal = tDate; }
      else { throw Error('The value "' + data + '" could not be converted to a valid date.'); }
    }
    else { throw Error(data + '" value of type  "' + typeof data + '" does not represent an expected date case.'); }
  }

  return retVal;
}

export function convertStringToDate(date: string): Date | null {
  if (date == null) { return null; }

  var dateParts = date.split("/");
  return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
}

export function convertDateToString(date: Date): string | null {
  if (date == null) { return null; }

  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0');
  var yyyy = date.getFullYear();

  return dd + '/' + mm + '/' + yyyy;
}

export function getDateLocaleString(date: any): any { return new Date(date).toLocaleString(); }


export function fileToBase64(file: File): Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event: any) => {
    let fileToLoad = event.target.result.toString().split(',');
    result.next(fileToLoad[1]);
  }
  return result;
}

export function getDaysBetweenTwoDates(startDate: Date, endDate: Date): number {
  if (startDate == null || endDate == null) { return NaN; }
  if (typeof startDate === 'string') {
    startDate = new Date(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = new Date(endDate);
  }
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  const diffTime = endDate.getTime() - startDate.getTime();
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

export function getMonthsBetweenTwoDates(startDate: Date, endDate: Date): number {
  if (startDate == null || endDate == null) { return NaN; }
  if (typeof startDate === 'string') {
    startDate = new Date(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = new Date(endDate);
  }
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  const diffTime = endDate.getTime() - startDate.getTime();
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

export const toCamelCase = (str: string, firstLowerCase = true): string =>
  str
    //.split(/(?=(?:(?<![A-Z])[A-Z])|(?:(?<!\d)\d))|[-_]/)    //non supportato su safari
    .split(' ')
    .map(
      ([first, ...rest], index) =>
        (firstLowerCase && index === 0
          ? first.toLowerCase()
          : first.toUpperCase()) + rest.join("").toLowerCase()
    )
    .join(' ');

/**
  * format bytes
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  */
export function formatBytes(bytes: number, decimals: number) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function niceBytes(bytes: string): string {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(bytes, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export function getElapsedSec(num: number) {
  if (num != undefined) {
    return num.toFixed(1);
  }
  else {
    return "-";
  }
}

export function getElapsed(num: any) {
  if (num != undefined && typeof num === 'number') {
    if (num > 60 * 60 * 24) {
      return (num / (60 * 60 * 24)).toFixed(1) + ' day';
    }
    else if (num > 60 * 60) {
      return (num / 3600).toFixed(1) + ' hr';
    }
    else if (num > 60) {
      return (num / 60).toFixed(1) + ' min';
    }
    else {
      return num.toFixed(1) + " sec";
    }
  }
  else {
    return "-";
  }
}

export function doParseMessage(msg: any): string {
  let mex = '';
  if (msg == null || msg === undefined || msg === '') { mex = 'No response from the server.'; }
  else if (typeof msg === 'string') {
    if (this.isHTML(msg)) {
      mex = 'A problem occurred during the execution of the request (format not valid).'
    }
    else {
      mex = msg;
    }'errors'
  }
  else if (typeof msg !== 'string') {
    if (msg['error'] != null && typeof msg['error'] === 'object') {
      msg = msg['error'];
    }
    if (msg['error'] != null && msg['error'] !== undefined &&
      msg['error']['description'] != null && msg['error']['description'] !== undefined &&
      typeof msg['error']['description'] === 'string') {
      mex = msg['error']['description'];
    }
    else if (msg['errors'] != null && msg['errors'] !== undefined &&
      typeof msg['errors'] === 'object' &&
      msg['errors'][0] != null && msg['errors'][0] !== undefined &&
      msg['errors'][0]['message'] != null && msg['errors'][0]['message'] !== undefined &&
      typeof msg['errors'][0]['message'] === 'string') {
      mex = msg['errors'][0]['message'];
    }
    else if (msg['description'] != null && msg['description'] !== undefined && typeof msg['description'] === 'string') { mex = msg['description']; }
    else if (msg['message'] != null && msg['message'] !== undefined && typeof msg['message'] === 'string') { mex = msg['message']; }
    else { mex = 'A problem occurred during the execution of the request.'; }
  }
  return mex;
}

export function isHTML(str: string) {
  var a = document.createElement('div');
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
}



export function  handleErrorObs(error: any) {
  var res = doParseMessage(error)
  return throwError(res);
}


export function getFilterParams(filter: any): HttpParams {
  let filterParams = new HttpParams();
  if (filter != null) {
    for (const key in filter) {
      let sValue;
      if (filter[key] instanceof Array) {
        for (var i = 0; i < filter[key].length; i++) {
          filterParams = filterParams.append(key, filter[key][i]);
        }
      }
      else {
        if (filter[key])
        {
          if (filter[key] instanceof Date) {
            const date = new Date(Date.parse(String(filter[key])));
            sValue = date.toISOString();
          } else {

            sValue = String(filter[key]);

          }
          filterParams = filterParams.append(key, sValue);
        }
      }
    }
  }

  return filterParams;
}

/*export function exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
export function saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
}

export function exportAsCSVFile(data: any, fileName: string): void {

  const replacer = (key: any, value: null) => value === null ? '' : value; // specify how you want to handle null values here
  const header = Object.keys(data[0]);
  let csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  let csvArray = csv.join('\r\n');

  var blob = new Blob([csvArray], { type: 'text/csv' })
  FileSaver.saveAs(blob, fileName + '_' + new Date().getTime() + CSV_EXTENSION);
}*/