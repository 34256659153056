<div class="mx-2">
  <h1><i class="bi bi-archive text-warning mx-3"></i>Controller's projects list</h1>
  <app-parents [projectid]="projectid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>
  
  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Site</th>
      <th scope="col">Description</th>
      <th scope="col">IP</th>
      <th scope="col">UserId</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let controller of listacontroller">
      <th scope="row">{{ controller.controllerid }}</th>
      <td>{{ controller.siteid }}</td>
      <td>{{ controller.controllerdescr }}</td>
      <td>{{ controller.controllerip }}</td>
      <td>{{ controller.userid }}</td>
      <td><button class="btn btn-primary" (click)="goToController(controller)"><i class="bi bi-eye"></i>Controller</button></td>
    </tr>
    </tbody>
  </table>
</div>
</div>
