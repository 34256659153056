import { Page404Component } from './components/page404/page404.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ArtifactsComponent } from './components/repository/artifacts/artifacts.component';
import { ControllerComponent } from './components/controller/controller.component';
import { ControllerProjectsComponent } from './components/controller-projects/controller-projects.component';
import { ExecutionsComponent } from './components/executions/executions.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogsComponent } from './components/logs/logs.component';
import { ProjectsComponent } from './components/repository/projects/projects.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SiteComponent } from './components/site/site.component';
import { VariablesComponent } from './components/variables/variables.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { LogonComponent } from './components/auth/logon/logon.component';
import { AuthGuard } from './service/auth.guard';
import { LoginComponent } from './components/auth/login/login.component';
import { BatchesComponent } from './components/batches/batches.component';
import { InformationComponent as InformationComponent } from './components/information/information.component';
import { ProjectControllersComponent } from './components/repository/project-controllers/project-controllers.component';
import { ProjectVariablesComponent } from './components/repository/project-variables/project-variables.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TaskOutputComponent } from './components/task-output/taskoutput.component';
import { CallersComponent } from './components/callers/callers.component';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Dashboard'
    },
    children: [
      {
        path: '',
        data: {
          breadcrumb: null
        },
        component: DashboardComponent,
      },
      {
        path: 'site/:siteid',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Site'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: 'Site',
            },
            component: SiteComponent,
          },
          {
            path: 'logs',
            data: {
              breadcrumb: 'Logs',
            },
            component: LogsComponent,
          },
          {
            path: 'controller/:controllerid',
            data: {
              breadcrumb: 'Controller',
            },
            children: [
              {
                path: '',
                data: {
                  breadcrumb: 'Controller',
                },
                component: ControllerComponent,
              },
              {
                path: 'controller-projects',
                data: {
                  breadcrumb: 'Controller Projects',
                },
                children: [
                  {
                    path: '',
                    data: {
                      breadcrumb: 'Controller Detail',
                    },
                    component: ControllerProjectsComponent,
                  },
                  {
                    path: ':projectid/activities',
                    data: {
                      breadcrumb: 'Activities',
                    },
                    component: ActivitiesComponent,
                  }
                ]
              },
              {
                path: 'project',
                data: {
                  breadcrumb: '',
                },
                children: [
                  {
                    path: ':projectid/activities',
                    data: {
                      breadcrumb: 'Activities',
                    },
                    children: [
                      {
                        path: '',
                        data: {
                          breadcrumb: null,
                        },
                        component: ActivitiesComponent,
                      },
                      {
                        path: ':activityid/logs',
                        data: {
                          breadcrumb: 'Logs',
                        },
                        component: LogsComponent,
                      },
                      {
                        path: ':activityid/executions',
                        data: {
                          breadcrumb: 'Executions',
                        },
                        children: [
                          {
                            path: '',
                            data: {
                              breadcrumb: null,
                            },
                            component: ExecutionsComponent,
                          },
                          {
                            path: ':executionid/batches',
                            data: {
                              breadcrumb: 'Batches',
                            },
                            children:[
                                {
                                  path: '',
                                  data: {
                                    breadcrumb: null,
                                  },
                                  component: BatchesComponent,
                                },
                                {
                                  path: ':batchid/tasks',
                                  data: {
                                    breadcrumb: 'Tasks',
                                  },
                                  children:[
                                    {
                                      path: '',
                                      data: {
                                        breadcrumb: null,
                                      },
                                      component: TasksComponent,
                                    },
                                    {
                                      path: ':taskid/output',
                                      data: {
                                        breadcrumb: 'Output',
                                      },
                                      component: TaskOutputComponent,
                                    }
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: ':activityid/callers',
                        data: {
                          breadcrumb: 'Callers',
                        },
                        component: CallersComponent,
                      }
                    ]
                  }
                ]
              },
              {
                path: 'activities',
                data: {
                  breadcrumb: 'Activities',
                },
                children: [
                  {
                    path: '',
                    data: {
                      breadcrumb: null,
                    },
                    component: ActivitiesComponent,
                  },
                  {
                    path: ':activityid/variables',
                    data: {
                      breadcrumb: 'Variables',
                    },
                    component: VariablesComponent,
                  }
                ],
              },
              {
                path: 'logs',
                data: {
                  breadcrumb: 'Logs',
                },
                component: LogsComponent,
              }
            ]
          },
        ]
      },
      {
        path: 'logs',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Logs'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
            },
            component: LogsComponent,
          },
        ],
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Settings'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
            },
            component: SettingsComponent,
          },
        ],
      },
      {
        path: 'information',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Information'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
            },
            component: InformationComponent,
          },
        ],
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Projects'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
            },
            component: ProjectsComponent,
          },
          {
            path: ':projectid/variables',
            data: {
              breadcrumb: 'Variables',
            },
            component: ProjectVariablesComponent,
          },
          {
            path: ':projectid/controllers',
            data: {
              breadcrumb: 'Controllers',
            },
            component: ProjectControllersComponent,
          },
          {
            path: ':projectid/artifacts',
            data: {
              breadcrumb: 'Artifacts',
            },
            component: ArtifactsComponent,
          },
        ],
      },

    ],
  } ,
  {
    path: 'auth/login', pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'auth/logout', pathMatch: 'full',
    component: LogoutComponent
  },
  {
    path: 'auth/logon', pathMatch: 'full',
    component: LogonComponent
  },
  {
    path: '**', pathMatch: 'full',
    component: Page404Component
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
