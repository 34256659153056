import { SearchFilter } from "./searchfilter.model";

export class TLog {
  constructor(
    public logtimestamp: string, // Timestamp
    public level: number,
    public siteid: string,
    public activityid: string,
    public controllerid: string,
    public ambit: number,
    public reference: string,
    public message: string,
    public _levelDescr: string,
    public _ambitDescr: string
  ) { }
}

export class LogFilter extends SearchFilter {
  activityid: string;
  controllerid: string;
  level: number;
  siteid: string;
  reference: string;
  message: string;
}

export function doParsePropertiesLog(logs: any[]): TLog[] {
  logs.forEach(x => {
    x._levelDescr = getLogLevelName(x.level);
    x._ambitDescr = getAmbitName(x.ambit);
  });
  return logs;
}

export function getLogLevelName(iLevel: number): string {
  let sRC = '<Unknown>';

  switch (iLevel) {
    case 3:
      sRC = 'Error';
      break;

    case 2:
      sRC = "Warning";
      break;

    case 1:
      sRC = "Debug";
      break;

    case 0:
      sRC = "Info";
      break;

    default:
      sRC = '<' + String(iLevel) + '>';
      break;
  }

  return sRC;

}

export function getAmbitName(iLevel: number): string {
  let sRC = '<Unknown>';

  switch (iLevel) {
    case 0:
      sRC = "General";
      break;

    default:
      sRC = '<' + String(iLevel) + '>';
      break;
  }

  return sRC;

}