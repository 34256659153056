import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable() export class ConfirmDialogService {
  private subject = new Subject<any>();

  cleanDialog(){
    this.subject.next(false);
  }

  setConfirmation(message: string|undefined, useYesButton = true,  title = 'Confirm', messageHTML: string|undefined): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const that = this;
    this.subject.next({
      type: 'confirm',
      text: message,
      useYesButton: useYesButton,
      title: title,
      textHTML: messageHTML,
      yesFn(): any {
        that.subject.next(false);
        result.next(true);
      },
      noFn(): any {
        that.subject.next(false);
        result.next(false);
      }
    });
    return result;
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
