import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss'],
})
export class ParentsComponent implements OnInit {

  @Input()
  siteid: string;

  @Input()
  controllerid: string;

  @Input()
  projectid: string;
  
  @Input()
  activityid: string;

  @Input()
  executiontid: string;

  @Input()
  batchid: string;

  @Input()
  taskid: string;

  ngOnInit() {
    
  }

}
