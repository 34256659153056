export enum TControllerStatus{
  UNACTIVE = "UNACTIVE",
  UNKNOWN = "UNKNOWN",
  NEW  = "NEW",
  ACTIVE = "ACTIVE"
}

export function getControllerStatus(tStatus: TControllerStatus)
{
  switch(tStatus)
  {
    case TControllerStatus.UNACTIVE:
      return "UNACTIVE";

    case TControllerStatus.UNKNOWN:
      return "UNKNOWN";

    case TControllerStatus.NEW:
      return "NEW";

    case TControllerStatus.ACTIVE:
      return "ACTIVE";

    default:
      return "<" + Object.keys(tStatus)[0] ?? '' + ">";
  }
}

export class TController{
  constructor(
     public controllerid: string,
     public siteid: string, // Identificativo del sito
     public controllerdescr: string,
     public controllerip: string,
     public userid: string,

     public status: TControllerStatus, // 					varchar(10) not null, -- 'NEW', 'ACTIVE', 'UNACTIVE'

     public connection_reqs: number, // 	    int default 0,
     public connecttimestamp: Date, // 		timestamptz ,
     public disconnecttimestamp: Date, //     timestamptz,
     public updatetimestamp: Date, // 		timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
     public assigned_token: string, //			varchar(64),

     // Retention
     public log_retention_days: number, //      int, -- Numero massimo di giorni di log da conservare (0 = nessuna cancellazione)
     public note: string,

     public _projectCount: number,
     public _activityCount: number
  ){}
}

export class TControllerStats {
  controllerid: string
  countActivities: number
  countActivitiesActive: number
  countActivitiesRunning: number
  countExecutions: number
  countExecutionsRunning: number
  countBatches: number
  countBatchSuccess: number
  countBatchError: number
}

export function doCalculateIndex(stats: TControllerStats, status: TControllerStatus) : number {

  if (stats?.countExecutionsRunning <= 0) {
    return (status === TControllerStatus.ACTIVE) ? 5 : 4;
  }
  if (stats?.countBatchError === 0) {
    if (stats?.countBatchSuccess > stats?.countBatches) {
      return 0;
    }
    if (stats?.countBatchSuccess === stats?.countBatches) {
      return 1;
    }
  }
  else if (stats?.countBatchError < stats?.countBatchSuccess) {
    return 2;
  }
  else {
    return 3;
  }
  
  return 0;
}


export class RobSrvControllerRequest{
  constructor(
     public controllerid: string,
     public siteid: string, // Identificativo del sito
     public controllerdescr: string,
     public controllerip: string,
     public note: string,
     public userid: string,

     public _projectCount: number,
     public _activityCount: number
  ){}
}

export class RobSrvControllerLastExecution {
  siteid: string
  controllerid: string
  projectid: string
  activityid: string
  executionid: string
  startdatetime: string
}

