<ul *ngIf="(breadcrumbs$ | async).length > 1">
  <li *ngFor="let breadcrumb of (breadcrumbs$ | async)">
    <div *ngIf="breadcrumb.hasChild">
      <a [routerLink]="breadcrumb.url" style="font-weight: normal;">{{ breadcrumb.label }}</a>
      <span class="separator"  style="padding-left: 10px;">/</span>
    </div>
    <div *ngIf="!breadcrumb.hasChild" style="font-weight: 600;">
      {{ breadcrumb.label }}
    </div>
  </li>
</ul>
<hr>
