import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CronOptions } from 'src/app/lib/cron-editor/CronOptions';
import { ToastService } from 'src/app/service/toast.service';

declare var window:any;

@Component({
  selector: 'app-dialog-cron',
  templateUrl: './dialog-cron.component.html',
  styleUrls: ['./dialog-cron.component.css']
})
export class DialogCronComponent implements OnInit {
  // --- aggiungere per modal ----
  formModal:any;

  public cronExpression = null;

  @Input() set openModal(request: any){
    if (request) {
      if (request.cronExpression) {
        this.cronExpression = request.cronExpression;
      }
      else {
        this.cronExpression = "0 */12 * * *";
      }
      this.formModal.show();
    }
  }

  @Output() onClose = new EventEmitter<string>();

  public cronOptions: CronOptions = {
    formInputClass: "form-control cron-editor-input",
    formSelectClass: "form-control cron-editor-select",
    formRadioClass: "cron-editor-radio",
    formCheckboxClass: "cron-editor-checkbox",

    defaultTime: "10:00:00",
    use24HourTime: true,

    hideSeconds: true,
    removeSeconds: true,
    removeYears: true,
    removeExtendedCron: false
  };

  constructor(private location: Location,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("cronDetailsModal")
    );
  }


  doConfirm(){
    if (true)
    {
      this.onClose.emit(this.cronExpression);
      this.closeModal();
    }
    else {
      this.toastService.showErrorToast("error", 'Cron');
    }
  }

  closeModal(){
    this.formModal.hide();
  }

  goBack()
  {
    this.location.back();
  }

}
