<div class="mx-2">
  <h1><i class="bi {{site?.icon}} text-warning mx-3"></i>Site {{siteid}} - controller list</h1>
  <h5>This page shows every controllers for the selected site.</h5>
  <br>
</div>

<div class="mx-2">
  <div class="d-lg-none">
    <div>
      <button class="btn btn-primary" (click)="addNewController()"><i class="bi bi-plus-circle"></i>New controller</button>
      <button class="btn btn-primary" (click)="gotoLogs()"><i class="bi bi-card-list"></i>Logs</button>
      <button class="btn btn-primary mx-2" (click)="updateSite()"><i class="bi bi-pencil"></i>Edit site</button>
      <button class="btn btn-danger" (click)="deleteSite()"><i class="bi bi-trash"></i>Delete site</button>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div class="d-flex justify-content-between">
      <button class="btn btn-primary" (click)="addNewController()"><i class="bi bi-plus-circle"></i>New controller</button>
      <div>
        <button class="btn btn-primary" (click)="gotoLogs()"><i class="bi bi-card-list"></i>Logs</button>
        <button class="btn btn-primary mx-2" (click)="updateSite()"><i class="bi bi-pencil"></i>Edit site</button>
        <button class="btn btn-danger" (click)="deleteSite()"><i class="bi bi-trash"></i>Delete site</button>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3 card-container">
    <h3>Controllers</h3>
    <div class="col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch" *ngFor="let controller of listacontroller">
      <app-controller-card class="card text-white bg-dark mb-3"[controller]="controller"></app-controller-card>
    </div>
  </div>
  <app-controller-list [siteId]="siteid"></app-controller-list>
</div>

<app-dialog-controller [openModal]="modifyParameters" [openModalCreate]="createParameters" (onClose)="onDetailsClosed()"></app-dialog-controller>
<app-dialog-site [openModal]="updateSiteParameters" (onClose)="onDetailsClosed()"></app-dialog-site>
