
  <div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="callerMoveToModal"  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Caller {{oCurrentCaller?.callerid}} - move to...</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="modalForm" >
            <label for="recipient-name" class="col-form-label">Activity Id</label>
            <select class="form-control form-select" aria-label="Activity" id="recipient-name" formControlName="activityid">
              <option *ngFor="let activity of activityList"
                      [value]="activity?.activityid"
                      [selected]="activity?.activityid === modalForm.value.activityid">
                  {{activity?.activityid}} {{activity?.description}}
              </option>
            </select>

            <label for="status-name" class="col-form-label">Status</label>
            <input *ngIf="bCreate" type="text" class="form-control" id="status-name" formControlName="status" readonly>
            <input *ngIf="!bCreate" type="text" class="form-control" id="status-name" formControlName="status" readonly>

            <label for="description-text" class="col-form-label">Description</label>
            <textarea class="form-control" id="description-text" formControlName="description"></textarea>

            <label for="note-text" class="col-form-label">Note</label>
            <textarea class="form-control" id="note-text" formControlName="note"></textarea>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
          <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
        </div>
      </div>
    </div>
  </div>

