
  <div class="modal hide fade in"  data-bs-keyboard="false" data-bs-backdrop="static" id="activityDetailsModal"  tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Activity's variable {{oCurrentActivityVar?.varid}} - details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="modalForm" >
            <label for="variablename-name" class="col-form-label">Variable name</label>
            <input type="text" class="form-control" id="variablename-name" formControlName="variablename" readonly>

            <label for="variabletype-name" class="col-form-label">Variable type</label>
            <input type="text" class="form-control" id="variabletype-name" formControlName="variabletype" readonly>

            <label for="variablevalue-name" class="col-form-label">Default value</label>
            <input type="text" class="form-control" id="variablevalue-name" formControlName="variablevalue" readonly>

            <label for="value-text" class="col-form-label">Value</label>
            <textarea class="form-control" id="value-text" formControlName="value"></textarea>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" style="flex:left" data-dismiss="modal" (click)="deleteVariable()"> Reset to default</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"> Close</button>
          <button type="button" class="btn btn-primary" (click)="doSave()">Save</button>
        </div>
      </div>
    </div>
  </div>

