import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getDateLocaleString } from 'src/app/lib/Utils';
import { ActivityFilter, TActivity, TActivityStatus, getActivityStatus } from 'src/app/model/tactivity.model';
import { ActivitiesService } from 'src/app/service/activities.service';
import { ToastService } from 'src/app/service/toast.service';
import { UtilService } from 'src/app/service/util.service';


@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {

  listaactivity: TActivity[];
  activityid: string;
  errormsg;
  siteid: string;
  controllerid: string;
  projectid: string|null;
  bSingleProject = false;

  createParameters:any;
  modifyParameters:any;

  searchForm: FormGroup;
  searchFilter:ActivityFilter;

  statuses = [
    { id: TActivityStatus.UNACTIVE, value: 'UNACTIVE' },
    { id: TActivityStatus.UNKNOWN, value: 'UNKNOWN' },
    { id: TActivityStatus.ACTIVE, value: 'ACTIVE' },
    { id: TActivityStatus.RUNNING, value: 'RUNNING' },
    { id: TActivityStatus.PROCESSED, value: 'PROCESSED' },
    { id: TActivityStatus.FAILED, value: 'FAILED' },
    { id: TActivityStatus.STOPPED, value: 'STOPPED' }
  ];

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder, 
              private router: Router, private utilService: UtilService,
              private activitiesService: ActivitiesService, public toastService: ToastService) { }

  ngOnInit(): void {
    if (this.router.url.indexOf("/controller-projects/") > 0) {
      this.bSingleProject = true;
    }
    this.route.params.subscribe(params => {
      this.siteid=params['siteid'];
      this.controllerid=params['controllerid'];
      this.projectid=params['projectid'];
    });

    this.route.queryParams
      .subscribe(params => {
        this.activityid = params.activityid;
        this.doInitSearchForm();
    
        this.getActivitiesObservable(this.siteid, this.controllerid, this.searchFilter);
      }
    );

  }

  
  doInitSearchFilter() {
    this.searchFilter = new ActivityFilter();
    if(this.bSingleProject) {
      this.searchFilter.projectid = this.projectid;
    }
    if(this.activityid) {
      this.searchFilter.activityid = this.activityid;
    }
  }

  doInitSearchForm() {
    this.doInitSearchFilter();
    this.searchForm = this.formBuilder.group({
      status: null,
      id: this.activityid ?? null
    });
  }

  doSearch(){
    this.doInitSearchFilter();
    if (this.searchForm.controls.status.valid && this.searchForm.value.status) {
      this.searchFilter.status = this.searchForm.value.status;
    }
    if (this.searchForm.controls.id.valid && this.searchForm.value.id) {
      this.searchFilter.activityid = this.searchForm.value.id;
    }
    else{
      this.searchFilter.activityid = null;
    }
    
    this.getActivitiesObservable(this.siteid, this.controllerid, this.searchFilter);
  }

  getActivitiesObservable(siteid:string, controllerid:string, filter:ActivityFilter|null){
    this.errormsg ='';

    this.activitiesService.getActivitiesObservable(siteid, controllerid, filter)
      .subscribe(
        {
          next: risp => this.listaactivity = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activities');
          }
        });
  }

  doReload()
  {
    this.getActivitiesObservable(this.siteid, this.controllerid, this.searchFilter);
  }

  isTimedEnabled(activity: TActivity) {
    return activity?._timedEnabled ?? false;
  }

  getExecutionsCount(oActivity: TActivity)
  {
    if (oActivity._executionsCount != undefined)
    {
      if (oActivity._executionsCount >= 0)
      {
        return String(oActivity._executionsCount);
      }
      else
      {
        return "?";
      }
    }
    return "---";
  }

  getActivityStatus(activity: TActivity):string
  {
    return getActivityStatus(activity);
  }

  convertDateToString(date:Date){
    if(date){
      return getDateLocaleString(date);
    }

    return  '';
  }

  doNewActivity()
  {
    this.createParameters = {siteid: this.siteid, controllerid: this.controllerid, projectid: this.projectid};
  }

  onDetailsClosed()
  {
    this.modifyParameters = null;
    this.createParameters = null;
    this.doReload();
  }

  doShowDetails(activityid:string)
  {
    this.modifyParameters = {siteid:this.siteid, controllerid:this.controllerid, activityid:activityid};
  }

  doShowVariables(siteid: string, controllerid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid +'/controller/' + controllerid +'/activities/' + activityid +'/variables']);
  }

  doShowExecutions(siteid: string, controllerid: string, projectid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid +'/activities/' + activityid + '/executions']);
  }

  doShowCallers(siteid: string, controllerid: string, projectid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid +'/activities/' + activityid + '/callers']);
  }

  doShowLogs(siteid: string, controllerid: string, projectid: string, activityid: string)
  {
    this.router.navigate(['/site/' + siteid + '/controller/' + controllerid + '/project/' + projectid +'/activities/' + activityid + '/logs']);
  }

  isActive(activity: TActivity){
    if(activity.status === TActivityStatus.ACTIVE){
      return true;
    }
    return false;
  }

  doActive(activity: TActivity){
    if(!activity._timedEnabled && !activity.schedule){
      this.toastService.showWarningToast("To start the activity of a non-timed project, you must first specify the schedule property.", 'Activities');
      return;
    }
    activity.status = TActivityStatus.ACTIVE;
    this.doUpdateStatusActivity(activity);
  }
  doUnactive(activity: TActivity){
    activity.status = TActivityStatus.UNACTIVE;
    this.doUpdateStatusActivity(activity);
  }


  doUpdateStatusActivity(activity: TActivity){
    this.errormsg ='';
    this.activitiesService.patchActivityStatusObservable(this.siteid, activity.activityid, activity)
      .subscribe(
        {
          next: risp => {
            this.toastService.showSuccessToast("Activity saved correctly.", 'Activity');
            this.doReload();
          },
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Activity');
          }
        });
  }

  doDeleteActivity(activityid: string){
    this.utilService.openConfirmDialog(
      "Are you sure you want to delete the selected activity [" + activityid + "]?", true).subscribe({
        next: (res) => {
          if (res) {
            this.deleteActivityObservable(this.siteid, this.controllerid, activityid);
          }
        }
      });
  }

  deleteActivityObservable(siteId:string, controllerId:string, activityid:string){
    this.errormsg ='';
    this.activitiesService.deleteActivityObservable(siteId, controllerId, activityid)
      .subscribe(
        {
          next: risp =>
          {
            if (risp === true)
            {
              this.toastService.showSuccessToast("Activity ["+controllerId+"] deleted correctly.", 'Activity');
              if(this.activityid) {
                this.router.navigate(['/site/' + this.siteid + '/controller/' + this.controllerid ]);
              }
              else{
                this.doReload();
              }
            }
            else
            {
              this.toastService.showErrorToast("Cannot be deleted", 'Activities');
            }

          },
          error:error => {
            this.toastService.showErrorToast(error, 'Activities');
          }
        });
  }
}
