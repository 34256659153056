import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/service/toast.service';
import { TController } from 'src/app/model/tcontroller.model';
import { ControllerService } from 'src/app/service/controller.service';


@Component({
  selector: 'app-project-controllers',
  templateUrl: './project-controllers.component.html',
  styleUrls: ['./project-controllers.component.css']
})
export class ProjectControllersComponent implements OnInit {

  listacontroller: TController[];
  errormsg;
  siteid: string;
  controllerid: string;
  projectid: string;

  artifact:string;

  constructor(private route: ActivatedRoute, private router: Router, 
    private projectservice: ControllerService, public toastService: ToastService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.siteid = params['siteid'];
      this.controllerid = params['controllerid'];
      this.projectid = params['projectid'];
    });
    this.getArtifactsObservable(this.siteid, this.controllerid, this.projectid);
  }

  getArtifactsObservable(siteid: string, controllerid: string, projectid: string){
    this.errormsg ='';
    this.projectservice.getControllersOfProjectObservable(projectid)
      .subscribe(
        {
          next: risp => this.listacontroller = risp,
          error:error => {
            this.errormsg = error;
            this.toastService.showErrorToast(error, 'Error');
          }
        });
  }

  goToController(controller: TController){
    this.router.navigate(['/site/' + controller.siteid +'/controller/' + controller.controllerid]);
  }
}
