<div class="mx-2">
  <h1><i class="bi bi-archive text-warning mx-3"></i>Artifacts list</h1>
  <app-parents [projectid]="projectid"></app-parents>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>
  
  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Action</th>
        <th scope="col">Project Id</th>
        <th scope="col">Type</th>
        <th scope="col">File name</th>
        <th scope="col">Size</th>
        <th scope="col">Url</th>
        <!-- <th scope="col">Timestamp</th> -->
        <!-- <th scope="col">Actions</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let artifact of listaartifact">
        <th scope="row">{{ artifact.artifactid }}</th>
        <td>{{ artifact.projectid }}</td>
        <td>{{ artifact.contenttype }}</td>
        <td>{{ artifact.filename }}</td>
        <td>{{ artifact.size }}</td>
        <td>{{ artifact.url }}</td>
        <!-- <td>{{ convertDateToString(artifact.rectimestamp) }}</td> -->
        <!-- <td><button class="btn btn-primary" (click)="btnShow(artifact)">Show</button></td> -->
      </tr>
    </tbody>
  </table>
</div>
</div>

<app-dialog-artifact #dlgArtifact [openModal]="artifact" (OnClosed)="OnClosed($event)" [siteId]="siteid" [controllerId]="controllerid" [projectId]="projectid"></app-dialog-artifact>