<div class="mx-2">
  <h1><i class="bi bi-lightning-charge text-warning mx-3"></i> Execution list</h1>
  <h5>This page shows all execution for the specified project.</h5>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <app-parents [projectid]="projectid" [activityid]="activityid"></app-parents>
  <br>
  <form [formGroup]="searchForm" class="mt-3 mb-5">
    <div class="row">
      <div class="col-md-4">
        <lib-select text="Status" [data]="statuses" [fieldId]="'id'" [fieldText]="'value'"
          formControlName="status" [submitted]="true" [addFirstItem]="true" >
        </lib-select>
      </div>
      <div class="form-group col-md-4">
        <label for="id">Id</label>
        <input type="text" class="form-control" id="id" formControlName="id">
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button  type="button" class="btn btn-primary" (click)="doSearch()"><i class="bi bi-arrow-repeat"></i>Search</button>
      </div>
    </div>
  </form>
  
  <div *ngIf="errormsg">{{errormsg}}</div>
</div>

<lib-table [data]="result" [configuration]="tableConfiguration" (action)="action($event)"
(pageChanged)="pageChanged($event)" (sort)="sort($event)" (reload)="doReload()"
[hideFunction]="hideFunction"></lib-table>

<app-dialog-execution [openModal]="modifyParameters" [openModalCreate]="createParameters" (onClose)="onDetailsClosed()"></app-dialog-execution>
