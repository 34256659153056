import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getControllerStatus, TControllerStats, doCalculateIndex, TController } from 'src/app/model/tcontroller.model';
import { ControllerService } from 'src/app/service/controller.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-controller-card',
  templateUrl: './controller-card.component.html',
  styleUrls: ['./controller-card.component.css']
})
export class ControllerCardComponent implements OnInit, OnChanges  {

  @Input() controller:TController;
  controllerStats: TControllerStats;
  isLoaded = false;
  index= 0;

  constructor(public controllerService: ControllerService, public toastService: ToastService,  private router:Router) { }

  ngOnInit(): void {
    // if(this.site){
    //   this.getSitesStatsObservable(this.site.siteid);
    // }
  }

  ngOnChanges(){
    if(this.controller){
      this.getControllerStatsObservable(this.controller.siteid, this.controller.controllerid);
    }
  }

  getControllerStatsObservable(siteid: string, controllerid: string){
    this.controllerService.getControllerStatsObservable(siteid, controllerid)
      .subscribe(
        {
          next: risp => {
            this.controllerStats = risp;
            this.isLoaded= true;
            this.doCalculateIndex();
          },
          error:error => {}
        });
  }

  getControllerStatus(oController: TController){
    return getControllerStatus(oController.status);
  }

  doCalculateIndex(){
    this.index = doCalculateIndex(this.controllerStats, this.controller.status);
  }


  gotoController(controller: TController){
    this.router.navigate(['/site/' + this.controller.siteid + '/controller/' + controller.controllerid ]);
  }

  goToLastExecution(event, controller: TController){
    event.stopPropagation();
    this.controllerService.getControllerLastExecutionObservable(controller.siteid, controller.controllerid)
      .subscribe(
        {
          next: risp => {
            if(risp){
              this.router.navigate(['/site/' + this.controller.siteid + '/controller/' + controller.controllerid + '/project/' + risp.projectid + '/activities/' + risp.activityid + '/executions/'], { queryParams: {executionid: risp.executionid}});
            }
          },
          error:error => {
            this.toastService.showErrorToast(error, 'Controller');}
        });
  }

}
