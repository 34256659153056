import { SearchFilter } from "./searchfilter.model";

export enum TActivityStatus{
  UNACTIVE = -1,
  UNKNOWN = 0,
  ACTIVE = 1,
  RUNNING = 2,
  PROCESSED = 3,
  FAILED = 4,
  STOPPED = 5
}

export class TActivity{
  constructor(
      public activityid: string,
      public controllerid: string, // Identificativo del sito
      public projectid: string,
      public description: string,
      public status: number,
      public rectimestamp: Date,
      public updatestatustimestamp: Date,
      public updatetimestamp: Date,
      public exec_retention: number,
      public batch_retention: number,

      /// <summary> Se true viene impostata ACTIVE l'attività dopo il completamento con successo altrimenti PROCESSED
      /// </summary>
      public restartAfterSuccess: boolean,

      /// <summary> Se true viene impostata ACTIVE l'attività dopo il completamento in errore altrimenti PROCESSED
      /// </summary>
      public restartAfterError : boolean,

      // /// <summary> Minuti minimi di attesa per il riavvio
      // /// </summary>
      // public restartWaitMin: number,

      /// <summary> Schedule con la sistassi Cron 
      /// </summary>
      public schedule: string,

      public nextRun: Date,
      
      public note: string,

      public _projectSummary: string,
      public _timedEnabled : boolean,
      public _executionsCount: number
  ){}
}

export class TActivityStats {
  activityid: string
  countExecutions: number
  countExecutionsRunning: number
  countBatches: number
  countBatchSuccess: number
  countBatchError: number
}

export function doCalculateIndex(stats: TActivityStats, status: number) : number {

  if (stats?.countExecutionsRunning <= 0) {
    return (status === TActivityStatus.ACTIVE) ? 5 : 4;
  }
  if (stats?.countBatchError === 0) {
    if (stats?.countBatchSuccess > stats?.countBatches) {
      return 0;
    }
    if (stats?.countBatchSuccess === stats?.countBatches) {
      return 1;
    }
  }
  else if (stats?.countBatchError < stats?.countBatchSuccess) {
    return 2;
  }
  else {
    return 3;
  }

  return 0;
}

export class ActivityFilter extends SearchFilter {
  activityid:string|undefined|null;
  projectid:string|undefined|null;
  status:number;
}

export function getActivityStatus(oActivity: TActivity) {
  switch (oActivity.status) {
    case TActivityStatus.UNACTIVE:
      return "UNACTIVE";
    case TActivityStatus.UNKNOWN:
      return "UNKNOWN";
    case TActivityStatus.ACTIVE:
      return "ACTIVE";
    case TActivityStatus.RUNNING:
      return "RUNNING";
    case TActivityStatus.PROCESSED:
      return "PROCESSED";
    case TActivityStatus.FAILED:
      return "FAILED";
    case TActivityStatus.STOPPED:
      return "STOPPED";
    default:
      return "<" + (oActivity.status??'') + ">";
  }
}
