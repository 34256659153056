import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ExecutionFilter, TExecution, TExecutionStats } from '../model/texecution.model';
import { getFilterParams, handleErrorObs } from '../lib/Utils';
import { BatchFilter, TExecBatch } from '../model/texecbatch.model';
import { TExecBatchTaskVariable } from '../model/texecbatchtaskvariable.model';
import { TBatchTask } from '../model/tbatchtask.model';
import { PagedData } from '../lib/table/pagedData';
import { SearchFilter } from 'src/app/model/searchfilter.model';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ExecutionService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

  getExecutionsObservable(siteid: string, controllerid: string, projectid: string, activityid: string, filter: ExecutionFilter|null): Observable<PagedData<TExecution[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid + "/executions", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getExecutionDetailsObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string): Observable<TExecution> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid +"/execution-details/"+ executionid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getExecutionStatsObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string): Observable<TExecutionStats> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid +"/execution-stats/"+ executionid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  sendStopRequestExecutionObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string): Observable<boolean> {
    return this.http.patch(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/executions/"+ executionid+ "/stop-request", null)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteExecutionObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/execution-details/"+ executionid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getExecBatchesObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, filter: BatchFilter|null): Observable<PagedData<TExecBatch[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid + "/executions/" + executionid + "/batches", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteExecBatchObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/executions/"+ executionid+ "/batch-details/" + batchid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getBatchTasksObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string, filter: SearchFilter|null): Observable<PagedData<TBatchTask[]>> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid + "/executions/" + executionid + "/batches/" + batchid + "/tasks", { params: getFilterParams(filter)})
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteBatchTaskObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string, taskid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/executions/"+ executionid+ "/batches/" + batchid+ "/task-details/" + taskid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getBatchTaskVariablesObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string, taskid: string): Observable<TExecBatchTaskVariable[]> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/" + projectid + "/activities/" + activityid + "/executions/" + executionid + "/batches/" + batchid + "/tasks/" + taskid + "/variables")
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  getTaskDetailsObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid:string, taskid:string): Observable<TBatchTask> {
    return this.http.get(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/executions/" + executionid +"/batches/"+ batchid+"/task-details/"+taskid, { params: null })
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }

  deleteBatchTaskVariableObservable(siteid: string, controllerid: string, projectid: string, activityid: string, executionid: string, batchid: string, taskid: string, varid: string): Observable<boolean> {
    return this.http.delete(this.utilService.getBasePath() + "/api/v1/sites/" + siteid + "/controllers/" + controllerid + "/projects/"+projectid+"/activities/" + activityid +"/executions/"+ executionid+ "/batches/" + batchid+ "/tasks/" + taskid+"/variables/"+varid)
      .pipe(
        map((risposta: any) => risposta),
        catchError(handleErrorObs)
      );
  }
}



