<div class="mx-2">
  <h1>Controller Project list</h1>
  <h5>This page shows all projects for the specified controller.</h5>
  <div class ="mb-3">
    <a href="/site/{{siteid}}/controller/{{controllerid}}" style="font-weight: normal;">{{controllerid}}</a>
  </div>
  <br>
  <div *ngIf="errormsg">{{errormsg}}</div>

  <button class="btn btn-primary" (click)="linkNewProject()"><i class="bi bi-link-45deg"></i>Link a new project</button>

  <div class="table-responsive">
    <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Status</th>
      <th scope="col">Description</th>
      <!-- <th scope="col">Activities</th> -->
      <th scope="col">Actions</th>
      <th scope="col">Details</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let oProject of listaproject">
      <th scope="row">{{ oProject.projectid }}</th>
      <td>{{ oProject.status}}</td>
      <td>{{ oProject.summary }}</td>
      <!-- <td><button class="btn btn-primary" (click)="doShowActivities(siteid, controllerid, oProject.projectid)"><i class="bi bi-play-fill"></i>Show</button></td> -->
      <td><button class="btn btn-primary" (click)="doUnlink(oProject)"><i class="bi bi-code-slash"></i>Unlink</button></td>
      <td><button class="btn btn-primary" (click)="doShowDetails(oProject.projectid)"><i class="bi bi-pencil"></i>Edit</button></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<app-dialog-controller-project (onClose)="onProjectDetailsClosed()"></app-dialog-controller-project>
<app-dialog-project [openModal]="selectedProjectId" (onClose)="onProjectDetailsClosed()"></app-dialog-project>
