import { getElapsed, getElapsedSec } from "../lib/Utils";
import { SearchFilter } from "./searchfilter.model";

export enum TExecBatchStatus {
  UNKNOWN,
  NEW,
  EMPTY,
  RUNNING,
  ERROR,
  SUCCESS,
  INTERRUPTED
}

export class TExecBatch {
  constructor(
    public batchid: string,
    public number: number,
    public execid: string,
    public batchstatus: number, //UNKNOWN, NEW, EMPTY, RUNNING, ERROR, SUCCESS
    public numberoferrors: number,
    public numberofsuccess: number,
    public numberofrunning: number,
    public startdatetime: Date,
    public stopdatetime: Date,
    public rectimestamp: Date,
    public updatetimestamp: Date,

    public _elapsed: number,
    public _elapsed_sec: number,
  ) { }
}


export class BatchFilter extends SearchFilter {
  batchstatus: number;
  number: number;
}

export function getExecBatchStatus(oExec: TExecBatch) {
  switch (oExec.batchstatus) {
    case TExecBatchStatus.UNKNOWN:
      return "UNKNOWN";

    case TExecBatchStatus.NEW:
      return "NEW";

    case TExecBatchStatus.EMPTY:
      return "EMPTY";

    case TExecBatchStatus.RUNNING:
      return "RUNNING";

    case TExecBatchStatus.ERROR:
      return "ERROR";

    case TExecBatchStatus.SUCCESS:
      return "SUCCESS";

    case TExecBatchStatus.INTERRUPTED:
      return "INTERRUPTED";

    default:
      return "<" + oExec.batchstatus?.toString() + ">";
  }
}

export function doParsePropertiesExecBatch(execBatches: any[]): TExecBatch[] {
  execBatches.forEach(x => {
    x.batchStatus = getExecBatchStatus(x);
    var elapsedTmp = x._elapsed_sec;
    x._elapsed_sec = getElapsedSec(elapsedTmp);
    x._elapsed = getElapsed(elapsedTmp);
  });
  return execBatches;
}